import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productDocumentTypeFieldsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDocumentTypeFields: [],
    productDocumentTypeFieldsCachedProductRootId: 0,
  },
  getters: {},
  mutations: {
    setProductDocumentTypeFields(state, productDocumentTypeFieldsData) {
      state.productDocumentTypeFields = productDocumentTypeFieldsData?.sort((pdtf1, pdtf2) => (pdtf1.DataFieldNumber > pdtf2.DataFieldNumber ? 1 : -1)) || [];
    },
    setProductDocumentTypeFieldsCachedProductRootId(state, productDocumentTypeFieldsCachedProductRootId) {
      state.productDocumentTypeFieldsCachedProductRootId = Number(productDocumentTypeFieldsCachedProductRootId || 0);
    },
    _addProductDocumentTypeField(state, productDocumentTypeField) {
      const productDocumentTypeFieldsData = _.cloneDeep(state.productDocumentTypeFields) || [];
      productDocumentTypeFieldsData.push(productDocumentTypeField);
      state.productDocumentTypeFields = productDocumentTypeFieldsData.sort((pdtf1, pdtf2) => (pdtf1.DataFieldNumber > pdtf2.DataFieldNumber ? 1 : -1)) || [];
    },
    _updateProductDocumentTypeField(state, updateProductDocumentTypeField) {
      const rootId = Number(updateProductDocumentTypeField?.RootId || 0);
      if (rootId > 0) {
        let productDocumentTypeFieldsData = _.cloneDeep(state.productDocumentTypeFields) || [];
        state.productDocumentTypeFields = [...productDocumentTypeFieldsData.filter(productDocumentTypeField => Number(productDocumentTypeField?.RootId || 0) !== rootId), _.cloneDeep(updateProductDocumentTypeField)].sort((pdtf1, pdtf2) => (pdtf1.DataFieldNumber > pdtf2.DataFieldNumber ? 1 : -1)) || [];
      }
    },
    _removeProductDocumentTypeField(state, deleteProductDocumentTypeField) {
      const rootId = Number(deleteProductDocumentTypeField?.RootId || 0);
      if (rootId > 0) {
        let productDocumentTypeFieldsData = _.cloneDeep(state.productDocumentTypeFields) || [];
        state.productDocumentTypeFields = [...productDocumentTypeFieldsData.filter(productDocumentTypeField => Number(productDocumentTypeField?.RootId || 0) !== rootId)].sort((pdtf1, pdtf2) => (pdtf1.DataFieldNumber > pdtf2.DataFieldNumber ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getProductDocumentTypeFields(context, {
      ProductRootId,
      IncludeMetadata,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.productDocumentTypeFieldsCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${productDocumentTypeFieldsUrl
          }?productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setProductDocumentTypeFields", data);
        context.commit("setProductDocumentTypeFieldsCachedProductRootId", ProductRootId);
        return data;
      }, "productDocumentTypeFields - getProductDocumentTypeFields");
    },
    async addProductDocumentTypeField(context, productDocumentTypeField) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDocumentTypeFieldsUrl, Payload: productDocumentTypeField });
        context.commit("_addProductDocumentTypeField", data);
      }, "productDocumentTypeFields - addProductDocumentTypeField");
    },
    async updateProductDocumentTypeField(context, productDocumentTypeField) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeFieldsUrl}/${Number(productDocumentTypeField?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypeField });
        context.commit("_updateProductDocumentTypeField", data);
      }, "productDocumentTypeFields - updateProductDocumentTypeField");
    },
    async removeProductDocumentTypeField(context, productDocumentTypeField) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeFieldsUrl}/${Number(productDocumentTypeField?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductDocumentTypeField", productDocumentTypeField);
      }, "productDocumentTypeFields - removeProductDocumentTypeField");
    },
  },
};
