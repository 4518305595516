import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, documentTypeFieldTypesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentTypeFieldTypes: [],
  },
  getters: {
    getDocumentTypeFieldTypeByName: (state) => (name) => {
      return state.documentTypeFieldTypes.find((documentTypeFieldType) => String(documentTypeFieldType?.Name || "") === String(name || "")) || null;
    },
    getNextDocumentTypeFieldTypeRootId: (state) => {
      let result = 1;
      state.documentTypeFieldTypes.forEach((documentTypeFieldType) => {
        if (Number(documentTypeFieldType.RootId) >= result) {
          result = Number(documentTypeFieldType.RootId) + 1;
        }
      });
      return result;
    },
    areDocumentTypeFieldTypesLoaded: (state) => () => {
      return state.documentTypeFieldTypes?.length > 0;
    },
    isDocumentTypeFieldTypeNameTaken: (state) => (currentDocumentTypeFieldType) => {
      const { DocumentTypeFieldTypes } = state;
      if (DocumentTypeFieldTypes?.length < 1) {
        return false;
      }
      if (!currentDocumentTypeFieldType) {
        return true;
      }
      const { Name: currentName, RootId } = currentDocumentTypeFieldType;
      if (!(currentName?.length > 0)) {
        return true;
      }
      if (Number(RootId || 0) < 1) {
        return true;
      }
      return !!state.documentTypeFieldTypes.find((documentTypeFieldType) => (String(documentTypeFieldType?.Name || "") === String(currentName || "")) && (Number(RootId || 0) !== Number(documentTypeFieldType?.RootId || 0)));
    },
  },
  mutations: {
    setDocumentTypeFieldTypes(state, documentTypeFieldTypesData) {
      state.documentTypeFieldTypes = documentTypeFieldTypesData?.sort((dtft1, dtft2) => (dtft1.Name > dtft2.Name ? 1 : -1)) || [];
    },
    _addDocumentTypeFieldType(state, documentTypeFieldType) {
      const documentTypeFieldTypesData = _.cloneDeep(state.documentTypeFieldTypes) || [];
      documentTypeFieldTypesData.push(documentTypeFieldType);
      state.documentTypeFieldTypes = documentTypeFieldTypesData.sort((dtft1, dtft2) => (dtft1.Name > dtft2.Name ? 1 : -1)) || [];
    },
    _updateDocumentTypeFieldType(state, updateDocumentTypeFieldType) {
      const rootId = Number(updateDocumentTypeFieldType?.RootId || 0);
      if (rootId > 0) {
        let documentTypeFieldTypesData = _.cloneDeep(state.documentTypeFieldTypes) || [];
        state.documentTypeFieldTypes = [...documentTypeFieldTypesData.filter(documentTypeFieldType => Number(documentTypeFieldType?.RootId || 0) !== rootId), _.cloneDeep(updateDocumentTypeFieldType)].sort((dtft1, dtft2) => (dtft1.Name > dtft2.Name ? 1 : -1)) || [];
      }
    },
    _removeDocumentTypeFieldType(state, deleteDocumentTypeFieldType) {
      const rootId = Number(deleteDocumentTypeFieldType?.RootId || 0);
      if (rootId > 0) {
        let documentTypeFieldTypesData = _.cloneDeep(state.documentTypeFieldTypes) || [];
        state.documentTypeFieldTypes = [...documentTypeFieldTypesData.filter(documentTypeFieldType => Number(documentTypeFieldType?.RootId || 0) !== rootId)].sort((dtft1, dtft2) => (dtft1.Name > dtft2.Name ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getDocumentTypeFieldTypes(context, {
      IncludeMetadata,
      IncludeDocumentTypes,
      IncludeDocumentTypeCategories,
      IncludeDocumentTypeTitles,
    }) {
      return timeOperation(async () => {
        IncludeMetadata = IncludeMetadata || false;
        IncludeDocumentTypes = IncludeDocumentTypes || false;
        IncludeDocumentTypeCategories = IncludeDocumentTypeCategories || false;
        IncludeDocumentTypeTitles = IncludeDocumentTypeTitles || false;
        const formattedUrl = `${documentTypeFieldTypesUrl
          }?includemetadata=${IncludeMetadata
          }&includedocumenttypes=${IncludeDocumentTypes
          }&includedocumenttypecategories=${IncludeDocumentTypeCategories
          }&includedocumenttypetitles=${IncludeDocumentTypeTitles}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setDocumentTypeFieldTypes", data);
        return data;
      }, "documentTypeFieldTypes - getDocumentTypeFieldTypes");
    },
    async addDocumentTypeFieldType(context, documentTypeFieldType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypeFieldTypesUrl, Payload: documentTypeFieldType });
        context.commit("_addDocumentTypeFieldType", data);
      }, "documentTypeFieldTypes - addDocumentTypeFieldType");
    },
    async updateDocumentTypeFieldType(context, documentTypeFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeFieldTypesUrl}/${Number(documentTypeFieldType?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentTypeFieldType });
        context.commit("_updateDocumentTypeFieldType", data);
      }, "documentTypeFieldTypes - updateDocumentTypeFieldType");
    },
    async removeDocumentTypeFieldType(context, documentTypeFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeFieldTypesUrl}/${Number(documentTypeFieldType?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentTypeFieldType", documentTypeFieldType);
      }, "documentTypeFieldTypes - removeDocumentTypeFieldType");
    },
  },
};
