import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productDocumentCategoriesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDocumentCategories: [],
    productDocumentCategoriesCachedProductRootId: 0,
  },
  getters: {
    getProductDocumentCategoryByName: (state) => (name) => {
      return state.productDocumentCategories?.find((productDocumentCategory) => String(productDocumentCategory?.Name || "") === String(name || "")) || null;
    },
    getNextProductDocumentCategoryRootId: (state) => {
      let result = 1;
      state.productDocumentCategories.forEach((productDocumentCategory) => {
        if (Number(productDocumentCategory.RootId) >= result) {
          result = Number(productDocumentCategory.RootId) + 1;
        }
      });
      return result;
    },
    areProductDocumentCategoriesLoaded: (state) => (productRootId) => {
      productRootId = Number(productRootId || 0);
      return productRootId > 0 && state.productDocumentCategories?.find((productDocumentCategory) => Number(productDocumentCategory?.ProductRootId || 0) === productRootId) != null;
    },
    isProductDocumentCategoryNameTaken:
      (state) =>
      (currentProductDocumentCategory, isNew = false) => {
        const { productDocumentCategories } = state;
        if (productDocumentCategories?.length < 1) {
          return false;
        }
        if (!currentProductDocumentCategory) {
          return true;
        }
        const { Name: currentName, RootId } = currentProductDocumentCategory;
        if (!(currentName?.length > 0)) {
          return false;
        }
        if (Number(RootId || 0) < 1 && !isNew) {
          return true;
        }
        return !!productDocumentCategories.find(
          (productDocumentCategory) =>
            String(productDocumentCategory?.Name || "") === String(currentName || "") && Number(RootId || 0) !== Number(productDocumentCategory?.RootId || 0)
        );
      },
  },
  mutations: {
    setProductDocumentCategories(state, productDocumentCategoriesData) {
      state.productDocumentCategories = productDocumentCategoriesData?.sort((pdc1, pdc2) => (pdc1.Name > pdc2.Name ? 1 : -1)) ?? [];
    },
    setProductDocumentCategoriesCachedProductRootId(state, productDocumentCategoriesCachedProductRootId) {
      state.productDocumentCategoriesCachedProductRootId = Number(productDocumentCategoriesCachedProductRootId || 0);
    },
    _addProductDocumentCategory(state, productDocumentCategory) {
      const productDocumentCategoriesData = _.cloneDeep(state.productDocumentCategories) ?? [];
      productDocumentCategoriesData.push(productDocumentCategory);
      state.productDocumentCategories = productDocumentCategoriesData.sort((pdc1, pdc2) => (pdc1.Name > pdc2.Name ? 1 : -1)) ?? [];
    },
    _updateProductDocumentCategory(state, updateProductDocumentCategory) {
      const rootId = Number(updateProductDocumentCategory?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentCategoriesData = _.cloneDeep(state.productDocumentCategories) ?? [];
        productDocumentCategoriesData =
          [
            ...productDocumentCategoriesData.filter((productDocumentCategory) => Number(productDocumentCategory?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateProductDocumentCategory),
          ].sort((pdc1, pdc2) => (pdc1.Name > pdc2.Name ? 1 : -1)) ?? [];
        state.productDocumentCategories = productDocumentCategoriesData;
      }
    },
    _removeProductDocumentCategory(state, deleteProductDocumentCategory) {
      const rootId = Number(deleteProductDocumentCategory?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentCategoriesData = _.cloneDeep(state.productDocumentCategories) ?? [];
        productDocumentCategoriesData =
          [...productDocumentCategoriesData.filter((productDocumentCategory) => Number(productDocumentCategory?.RootId ?? 0) !== rootId)].sort((pdc1, pdc2) =>
            pdc1.Name > pdc2.Name ? 1 : -1
          ) ?? [];
        state.productDocumentCategories = productDocumentCategoriesData;
      }
    },
  },
  actions: {
    async getProductDocumentCategories(context, {
      ProductRootId,
      IncludeMetadata,
      IncludeProductDocumentTypes,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeProductDocumentTypes = IncludeProductDocumentTypes || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.productDocumentCategoriesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${productDocumentCategoriesUrl
        }?productrootid=${ProductRootId
        }&includemetadata=${IncludeMetadata
        }&includeproductdocumenttypes=${IncludeProductDocumentTypes
        }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
        }&includeproductlevelmetadata=${IncludeProductLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setProductDocumentCategories", data);
        context.commit("setProductDocumentCategoriesCachedProductRootId", ProductRootId);
        return data;
      }, "productDocumentCategories - getProductDocumentCategories");
    },
    async addProductDocumentCategories(context, productDocumentCategories) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentCategoriesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: productDocumentCategories });
        context.commit("setProductDocumentCategories", data);
      }, "products - addProductDocumentCategory");
    },
    async addProductDocumentCategory(context, productDocumentCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDocumentCategoriesUrl, Payload: productDocumentCategory });
        context.commit("_addCurrentSelectedProductDocumentCategory", data);
        context.commit("_addProductDocumentCategory", data);
      }, "products - addProductDocumentCategory");
    },
    async updateProductDocumentCategory(context, productDocumentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentCategoriesUrl}/${productDocumentCategory.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentCategory });
        context.commit("_updateCurrentSelectedProductDocumentCategory", data);
        context.commit("_updateProductDocumentCategory", data);
      }, "products - updateProductDocumentCategory");
    },
    async removeProductDocumentCategory(context, productDocumentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentCategoriesUrl}/${productDocumentCategory.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeCurrentSelectedProductDocumentCategory", productDocumentCategory);
        context.commit("_removeProductDocumentCategory", productDocumentCategory);
      }, "products - removeProductDocumentCategory");
    },
    async updateProductDocumentCategoryPositions(context, productDocumentCategories) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentCategoriesUrl}/updatepositions`;
        if (productDocumentCategories?.length > 0) {
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: productDocumentCategories });
          context.commit("setProductDocumentCategories", data);
        }
      }, "productDocumentCategories - updateProductDocumentCategoryPositions");
    },
    async updateProductDocumentCategoryEnabledStatus(context, productDocumentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentCategoriesUrl}/${Number(productDocumentCategory?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentCategory });
        context.commit("_updateCurrentSelectedProductDocumentCategory", data);
        context.commit("_updateProductDocumentCategory", data);
      }, "products - updateProductDocumentCategory");
    },
  },
};
