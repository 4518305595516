<template>
  <CustomAutoComplete
    :options="options"
    @update:options="handleUpdateOptions"
    v-model="localModelValue"
    :formatFunction="formatFunction"
    :allow-custom-input="true"
    :click-to-open="true"
    :show-all-options-on-focus="showAllOptionsOnFocus"
    :searchIconWhite="true"
    :searchIcon="showSearchIcon"
    :truncate="true"
    searchIconTooltip="Basic"
    :disabled="disabled"
    :hasBorder="false"
    :placeholder="placeholder"
    :input-classes="inputClasses"
    :outer-input-classes="outerInputClasses"
    container-classes="w-64 pt-1"
    @search="handleSearch"
    @optionSelected="handleOptionSelected"
  />
</template>

<script>
import { ref, computed, watch, onMounted, nextTick } from "vue";

import CustomAutoComplete from "@/components/templateBuilder/renderedFormLayout/renderedItems/CustomAutoComplete.vue";

export default {
  name: "QuickSearch",
  components: { CustomAutoComplete },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    formatFunction: {
      type: Function,
      default: (searchResult) => searchResult.OrderNumber,
    },
    allowCustomInput: {
      type: Boolean,
      default: true,
    },
    showAllOptionsOnFocus: {
      type: Boolean,
      default: true,
    },
    showSearchIcon: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
    outerInputClasses: {
      type: String,
      default: "",
    },
    inputClasses: {
      type: String,
      default: "h-9",
    },
  },
  emits: ["update:options", "update:modelValue", "search", "update:quickSearchOrderNumber", "update:quickSearchOrderRootId", "update:customSearchText", "update:loadQuickSearch"],
  setup(props, { emit }) {
    const localModelValue = ref("");
    watch(localModelValue, (is) => {
      if (is !== props.modelValue) {
        emit("update:modelValue", is);
      }
    });
    const modelValue = computed(() => props.modelValue);
    watch(modelValue, (is) => {
      localModelValue.value = is;
    });

    function handleSearch(text) {
      emit("update:customSearchText", text);
      emit("search", text);
    }
    async function handleOptionSelected(option) {
      console.log("quick search option selected", option);
      const RootId = option?.RootId ?? 0;
      if (RootId > 0) {
        emit("update:customSearchText", "");
        emit("update:quickSearchOrderRootId", RootId);
        emit("update:quickSearchOrderNumber", option?.OrderNumber ?? "");
      } else {
        emit("update:quickSearchOrderNumber", "");
        emit("update:quickSearchOrderRootId", 0);
        emit("update:customSearchText", option);
      }
      await nextTick();
      emit("update:loadQuickSearch", { orderRootId: option?.RootId ?? 0, orderNumber: option?.OrderNumber ?? "", text: option?.RootId ? "" : option });
    }
    function handleUpdateOptions(newOptions) {
      emit("update:options", newOptions);
    }

    onMounted(async () => {
      await nextTick();
    });

    return {
      localModelValue,

      handleSearch,
      handleOptionSelected,
      handleUpdateOptions,
    };
  },
};
</script>
