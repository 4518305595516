import _ from "lodash";

export default {
  state: {
    activeModalIds: [],
  },
  mutations: {
    addActiveModal(state, activeModalId) {
      state.activeModalIds?.push?.(activeModalId);
    },
    removeActiveModal(state, activeModalId) {
      _.remove(state.activeModalIds, (id) => id === activeModalId);
    },
  },
};
