import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationDocumentTypeCategoriesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDocumentTypeCategories: [],
    organizationDocumentTypeCategoriesCachedOrganizationRootId: 0,
    organizationDocumentTypeCategoriesCachedProductRootId: 0,
  },
  getters: {},
  mutations: {
    setOrganizationDocumentTypeCategories(state, organizationDocumentTypeCategoriesData) {
      state.organizationDocumentTypeCategories = organizationDocumentTypeCategoriesData?.sort((odtc1, odtc2) => (odtc1.DataCategoryNumber > odtc2.DataCategoryNumber ? 1 : -1)) || [];
    },
    setOrganizationDocumentTypeCategoriesCachedOrganizationRootId(state, organizationDocumentTypeCategoriesCachedOrganizationRootId) {
      state.organizationDocumentTypeCategoriesCachedOrganizationRootId = Number(organizationDocumentTypeCategoriesCachedOrganizationRootId || 0);
    },
    setOrganizationDocumentTypeCategoriesCachedProductRootId(state, organizationDocumentTypeCategoriesCachedProductRootId) {
      state.organizationDocumentTypeCategoriesCachedProductRootId = Number(organizationDocumentTypeCategoriesCachedProductRootId || 0);
    },
    _addOrganizationDocumentTypeCategory(state, organizationDocumentTypeCategory) {
      const organizationDocumentTypeCategoriesData = _.cloneDeep(state.organizationDocumentTypeCategories) || [];
      organizationDocumentTypeCategoriesData.push(organizationDocumentTypeCategory);
      state.organizationDocumentTypeCategories = organizationDocumentTypeCategoriesData.sort((odtc1, odtc2) => (odtc1.DataCategoryNumber > odtc2.DataCategoryNumber ? 1 : -1)) || [];
    },
    _updateOrganizationDocumentTypeCategory(state, updateOrganizationDocumentTypeCategory) {
      const rootId = Number(updateOrganizationDocumentTypeCategory?.RootId || 0);
      if (rootId > 0) {
        let organizationDocumentTypeCategoriesData = _.cloneDeep(state.organizationDocumentTypeCategories) || [];
        state.organizationDocumentTypeCategories = [...organizationDocumentTypeCategoriesData.filter(organizationDocumentTypeCategory => Number(organizationDocumentTypeCategory?.RootId || 0) !== rootId), _.cloneDeep(updateOrganizationDocumentTypeCategory)].sort((odtc1, odtc2) => (odtc1.DataCategoryNumber > odtc2.DataCategoryNumber ? 1 : -1)) || [];
      }
    },
    _removeOrganizationDocumentTypeCategory(state, deleteOrganizationDocumentTypeCategory) {
      const rootId = Number(deleteOrganizationDocumentTypeCategory?.RootId || 0);
      if (rootId > 0) {
        let organizationDocumentTypeCategoriesData = _.cloneDeep(state.organizationDocumentTypeCategories) || [];
        state.organizationDocumentTypeCategories = [...organizationDocumentTypeCategoriesData.filter(organizationDocumentTypeCategory => Number(organizationDocumentTypeCategory?.RootId || 0) !== rootId)].sort((odtc1, odtc2) => (odtc1.DataCategoryNumber > odtc2.DataCategoryNumber ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getOrganizationDocumentTypeCategories(context, {
      OrganizationDocumentTypeRootId,
      OrganizationRootId,
      ProductRootId,
      IncludeMetadata,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      IncludeOrganizationLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        OrganizationDocumentTypeRootId = Number(OrganizationDocumentTypeRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        IncludeOrganizationLevelMetadata = IncludeOrganizationLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.organizationDocumentTypeCategoriesCachedOrganizationRootId === OrganizationRootId) &&
          (context.state.organizationDocumentTypeCategoriesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${organizationDocumentTypeCategoriesUrl
          }?organizationdocumenttyperootid=${OrganizationDocumentTypeRootId
          }&organizationrootid=${OrganizationRootId
          }&productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata
          }&includeorganizationlevelmetadata=${IncludeOrganizationLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationDocumentTypeCategories", data);
        context.commit("setOrganizationDocumentTypeCategoriesCachedOrganizationRootId", OrganizationRootId);
        context.commit("setOrganizationDocumentTypeCategoriesCachedProductRootId", ProductRootId);
        return data;
      }, "organizationDocumentTypeCategories - getOrganizationDocumentTypeCategories");
    },
    async addOrganizationDocumentTypeCategory(context, organizationDocumentTypeCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTypeCategoriesUrl, Payload: organizationDocumentTypeCategory });
        context.commit("_addOrganizationDocumentTypeCategory", data);
      }, "organizationDocumentTypeCategories - addOrganizationDocumentTypeCategory");
    },
    async updateOrganizationDocumentTypeCategory(context, organizationDocumentTypeCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeCategoriesUrl}/${Number(organizationDocumentTypeCategory?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypeCategory });
        context.commit("_updateOrganizationDocumentTypeCategory", data);
      }, "organizationDocumentTypeCategories - updateOrganizationDocumentTypeCategory");
    },
    async removeOrganizationDocumentTypeCategory(context, organizationDocumentTypeCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeCategoriesUrl}/${Number(organizationDocumentTypeCategory?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDocumentTypeCategory", organizationDocumentTypeCategory);
      }, "organizationDocumentTypeCategories - removeOrganizationDocumentTypeCategory");
    },
  },
};
