import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, documentTypeTitlesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentTypeTitles: [],
  },
  getters: {
    getNextDocumentTypeTitleRootId: (state) => {
      let result = 1;
      state.documentTypeTitles.forEach((documentTypeTitle) => {
        if (Number(documentTypeTitle.RootId) >= result) {
          result = Number(documentTypeTitle.RootId) + 1;
        }
      });
      return result;
    },
    areDocumentTypeTitlesLoaded: (state) => () => {
      return state.documentTypeTitles?.length > 0;
    },
    isDocumentTypeTitleNameTaken: (state) => (currentDocumentTypeTitle) => {
      const { DocumentTypeTitles } = state;
      if (DocumentTypeTitles?.length < 1) {
        return false;
      }
      if (!currentDocumentTypeTitle) {
        return true;
      }
      const { Name: currentName, RootId } = currentDocumentTypeTitle;
      if (!(currentName?.length > 0)) {
        return true;
      }
      if (Number(RootId || 0) < 1) {
        return true;
      }
      return !!state.documentTypeTitles.find((documentTypeTitle) => (String(documentTypeTitle?.Name || "") === String(currentName || "")) && (Number(RootId || 0) !== Number(documentTypeTitle?.RootId || 0)));
    },
  },
  mutations: {
    setDocumentTypeTitles(state, documentTypeTitlesData) {
      state.documentTypeTitles = documentTypeTitlesData?.sort((dttl1, dttl2) => (dttl1.Name > dttl2.Name ? 1 : -1)) ?? [];
    },
    _addDocumentTypeTitle(state, documentTypeTitle) {
      const documentTypeTitlesData = _.cloneDeep(state.documentTypeTitles) ?? [];
      documentTypeTitlesData.push(documentTypeTitle);
      state.documentTypeTitles = documentTypeTitlesData.sort((dttl1, dttl2) => (dttl1.Name > dttl2.Name ? 1 : -1)) ?? [];
    },
    _updateDocumentTypeTitle(state, updateDocumentTypeTitle) {
      const rootId = Number(updateDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypeTitlesData = _.cloneDeep(state.documentTypeTitles) ?? [];
        state.documentTypeTitles = [...documentTypeTitlesData.filter(documentTypeTitle => Number(documentTypeTitle?.RootId ?? 0) !== rootId), _.cloneDeep(updateDocumentTypeTitle)].sort((dttl1, dttl2) => (dttl1.Name > dttl2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeDocumentTypeTitle(state, deleteDocumentTypeTitle) {
      const rootId = Number(deleteDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let documentTypeTitlesData = _.cloneDeep(state.documentTypeTitles) ?? [];
        state.documentTypeTitles = [...documentTypeTitlesData.filter(documentTypeTitle => Number(documentTypeTitle?.RootId ?? 0) !== rootId)].sort((dttl1, dttl2) => (dttl1.Name > dttl2.Name ? 1 : -1)) ?? [];
      }
    },
  },
  actions: {
    async getDocumentTypeTitles(context, {
      DocumentTypeRootId,
      IncludeMetadata,
    }) {
      await timeOperation(async () => {
        DocumentTypeRootId = Number(DocumentTypeRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        const formattedUrl = `${documentTypeTitlesUrl
        }?documenttyperootid=${DocumentTypeRootId
        }&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setDocumentTypeTitles", data);
      }, "documentTypeTitles - getDocumentTypeTitles");
    },
    async addDocumentTypeTitle(context, documentTypeTitle) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypeTitlesUrl, Payload: documentTypeTitle });
        context.commit("_addDocumentTypeTitle", data);
      }, "documentTypeTitles - addDocumentTypeTitle");
    },
    async updateDocumentTypeTitle(context, documentTypeTitle) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeTitlesUrl}/${Number(documentTypeTitle?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentTypeTitle });
        context.commit("_updateDocumentTypeTitle", data);
      }, "documentTypeTitles - updateDocumentTypeTitle");
    },
    async removeDocumentTypeTitle(context, documentTypeTitle) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeTitlesUrl}/${Number(documentTypeTitle?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentTypeTitle", documentTypeTitle);
      }, "documentTypeTitles - removeDocumentTypeTitle");
    },
  },
};
