import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationDocumentTypeFieldsUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDocumentTypeFields: [],
    organizationDocumentTypeFieldsCachedOrganizationRootId: 0,
    organizationDocumentTypeFieldsCachedProductRootId: 0,
  },
  getters: {},
  mutations: {
    setOrganizationDocumentTypeFields(state, organizationDocumentTypeFieldsData) {
      state.organizationDocumentTypeFields = organizationDocumentTypeFieldsData?.sort((odtf1, odtf2) => (odtf1.DataFieldNumber > odtf2.DataFieldNumber ? 1 : -1)) || [];
    },
    setOrganizationDocumentTypeFieldsCachedOrganizationRootId(state, organizationDocumentTypeFieldsCachedOrganizationRootId) {
      state.organizationDocumentTypeFieldsCachedOrganizationRootId = Number(organizationDocumentTypeFieldsCachedOrganizationRootId || 0);
    },
    setOrganizationDocumentTypeFieldsCachedProductRootId(state, organizationDocumentTypeFieldsCachedProductRootId) {
      state.organizationDocumentTypeFieldsCachedProductRootId = Number(organizationDocumentTypeFieldsCachedProductRootId || 0);
    },
    _addOrganizationDocumentTypeField(state, organizationDocumentTypeField) {
      const organizationDocumentTypeFieldsData = _.cloneDeep(state.organizationDocumentTypeFields) || [];
      organizationDocumentTypeFieldsData.push(organizationDocumentTypeField);
      state.organizationDocumentTypeFields = organizationDocumentTypeFieldsData.sort((odtf1, odtf2) => (odtf1.DataFieldNumber > odtf2.DataFieldNumber ? 1 : -1)) || [];
    },
    _updateOrganizationDocumentTypeField(state, updateOrganizationDocumentTypeField) {
      const rootId = Number(updateOrganizationDocumentTypeField?.RootId || 0);
      if (rootId > 0) {
        let organizationDocumentTypeFieldsData = _.cloneDeep(state.organizationDocumentTypeFields) || [];
        state.organizationDocumentTypeFields = [...organizationDocumentTypeFieldsData.filter(organizationDocumentTypeField => Number(organizationDocumentTypeField?.RootId || 0) !== rootId), _.cloneDeep(updateOrganizationDocumentTypeField)].sort((odtf1, odtf2) => (odtf1.DataFieldNumber > odtf2.DataFieldNumber ? 1 : -1)) || [];
      }
    },
    _removeOrganizationDocumentTypeField(state, deleteOrganizationDocumentTypeField) {
      const rootId = Number(deleteOrganizationDocumentTypeField?.RootId || 0);
      if (rootId > 0) {
        let organizationDocumentTypeFieldsData = _.cloneDeep(state.organizationDocumentTypeFields) || [];
        state.organizationDocumentTypeFields = [...organizationDocumentTypeFieldsData.filter(organizationDocumentTypeField => Number(organizationDocumentTypeField?.RootId || 0) !== rootId)].sort((odtf1, odtf2) => (odtf1.DataFieldNumber > odtf2.DataFieldNumber ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getOrganizationDocumentTypeFields(context, {
      OrganizationDocumentTypeRootId,
      OrganizationRootId,
      ProductRootId,
      IncludeMetadata,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      IncludeOrganizationLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        OrganizationDocumentTypeRootId = Number(OrganizationDocumentTypeRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        IncludeOrganizationLevelMetadata = IncludeOrganizationLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.organizationDocumentTypeFieldsCachedOrganizationRootId === OrganizationRootId) &&
          (context.state.organizationDocumentTypeFieldsCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${organizationDocumentTypeFieldsUrl
          }?organizationdocumenttyperootid=${OrganizationDocumentTypeRootId
          }&organizationrootid=${OrganizationRootId
          }&productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata
          }&includeorganizationlevelmetadata=${IncludeOrganizationLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationDocumentTypeFields", data);
        context.commit("setOrganizationDocumentTypeFieldsCachedOrganizationRootId", OrganizationRootId);
        context.commit("setOrganizationDocumentTypeFieldsCachedProductRootId", ProductRootId);
        return data;
      }, "organizationDocumentTypeFields - getOrganizationDocumentTypeFields");
    },
    async addOrganizationDocumentTypeField(context, organizationDocumentTypeField) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTypeFieldsUrl, Payload: organizationDocumentTypeField });
        context.commit("_addOrganizationDocumentTypeField", data);
      }, "organizationDocumentTypeFields - addOrganizationDocumentTypeField");
    },
    async updateOrganizationDocumentTypeField(context, organizationDocumentTypeField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeFieldsUrl}/${Number(organizationDocumentTypeField?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypeField });
        context.commit("_updateOrganizationDocumentTypeField", data);
      }, "organizationDocumentTypeFields - updateOrganizationDocumentTypeField");
    },
    async removeOrganizationDocumentTypeField(context, organizationDocumentTypeField) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeFieldsUrl}/${Number(organizationDocumentTypeField?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDocumentTypeField", organizationDocumentTypeField);
      }, "organizationDocumentTypeFields - removeOrganizationDocumentTypeField");
    },
  },
};
