import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, documentTypeFieldsUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentTypeFields: [],
  },
  getters: {},
  mutations: {
    setDocumentTypeFields(state, documentTypeFieldsData) {
      state.documentTypeFields = documentTypeFieldsData?.sort((dtf1, dtf2) => (dtf1.DataFieldNumber > dtf2.DataFieldNumber ? 1 : -1)) || [];
    },
    _addDocumentTypeField(state, documentTypeField) {
      const documentTypeFieldsData = _.cloneDeep(state.documentTypeFields) || [];
      documentTypeFieldsData.push(documentTypeField);
      state.documentTypeFields = documentTypeFieldsData.sort((dtf1, dtf2) => (dtf1.DataFieldNumber > dtf2.DataFieldNumber ? 1 : -1)) || [];
    },
    _updateDocumentTypeField(state, updateDocumentTypeField) {
      const rootId = Number(updateDocumentTypeField?.RootId || 0);
      if (rootId > 0) {
        let documentTypeFieldsData = _.cloneDeep(state.documentTypeFields) || [];
        state.documentTypeFields =
          [...documentTypeFieldsData.filter((documentTypeField) => Number(documentTypeField?.RootId || 0) !== rootId), _.cloneDeep(updateDocumentTypeField)].sort((dtf1, dtf2) =>
            dtf1.DataFieldNumber > dtf2.DataFieldNumber ? 1 : -1
          ) || [];
      }
    },
    _removeDocumentTypeField(state, deleteDocumentTypeField) {
      const rootId = Number(deleteDocumentTypeField?.RootId || 0);
      if (rootId > 0) {
        let documentTypeFieldsData = _.cloneDeep(state.documentTypeFields) || [];
        state.documentTypeFields =
          [...documentTypeFieldsData.filter((documentTypeField) => Number(documentTypeField?.RootId || 0) !== rootId)].sort((dtf1, dtf2) =>
            dtf1.DataFieldNumber > dtf2.DataFieldNumber ? 1 : -1
          ) || [];
      }
    },
  },
  actions: {
    async getDocumentTypeFields(context, { IncludeMetadata }) {
      await timeOperation(async () => {
        IncludeMetadata = IncludeMetadata || false;
        const formattedUrl = `${documentTypeFieldsUrl}?includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setDocumentTypeFields", data);
      }, "documentTypeFields - getDocumentTypeFields");
    },
    async addDocumentTypeField(context, documentTypeField) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentTypeFieldsUrl, Payload: documentTypeField });
        context.commit("_addDocumentTypeField", data);
      }, "documentTypeFields - addDocumentTypeField");
    },
    async updateDocumentTypeField(context, documentTypeField) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeFieldsUrl}/${Number(documentTypeField?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentTypeField });
        context.commit("_updateDocumentTypeField", data);
      }, "documentTypeFields - updateDocumentTypeField");
    },
    async removeDocumentTypeField(context, documentTypeField) {
      await timeOperation(async () => {
        const formattedUrl = `${documentTypeFieldsUrl}/${Number(documentTypeField?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentTypeField", documentTypeField);
      }, "documentTypeFields - removeDocumentTypeField");
    },
  },
};
