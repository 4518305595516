// noinspection JSCheckFunctionSignatures

import { createRouter, createWebHistory } from "vue-router"; // vue 3
import NavHome from "../components/NavHome";
import { store } from "@/store";

// Admin Routes
const Authentication = () => import(/* webpackChunkName: "Authentication" */ "../components/admin/AuthenticationPage");
const AdminPage = () => import(/* webpackChunkName: "AdminPage" */ "../components/admin/AdminPage");
const OrganizationAdmin = () => import(/* webpackChunkName: "OrganizationAdmin" */ "../components/admin/OrganizationAdmin");
const OrganizationEditor = () => import(/* webpackChunkName: "OrganizationEditor" */ "../components/admin/OrganizationEditor");
const OrganizationAdminEditor = () => import(/* webpackChunkName: "OrganizationEditor" */ "../components/admin/OrganizationAdminEditor");
const OrganizationSearch = () => import(/* webpackChunkName: "OrganizationSearch" */ "../components/admin/OrganizationSearch");
const ProductAdmin = () => import(/* webpackChunkName: "ProductAdmin" */ "../components/admin/ProductAdmin");
const SystemGeneral = () => import(/* webpackChunkName: "SystemGeneral" */ "../components/admin/SystemGeneral");
const ProductEditor = () => import(/* webpackChunkName: "ProductEditor" */ "../components/admin/ProductEditor");
const DocumentTypes = () => import(/* webpackChunkName: "DocumentTypes" */ "../components/admin/generalComponents/DocumentTypes");
const DocumentTypeFieldTypes = () => import(/* webpackChunkName: "DocumentTypeFieldTypes" */ "../components/admin/generalComponents/DocumentTypeFieldTypes");
const SystemDocuments = () => import(/* webpackChunkName: "SystemDocuments" */ "../components/admin/systemComponents/./SystemDocuments");
const SystemDocumentCategories = () => import(/* webpackChunkName: "SystemDocumentCategories" */ "../components/admin/systemComponents/./SystemDocumentCategories");
const PotentialDocumentTypes = () => import(/* webpackChunkName: "PotentialDocumentTypes" */ "../components/admin/generalComponents/PotentialDocumentTypesSplitter");
const AuditLogs = () => import(/*webpackChunkName: "AuditLogs" */ "../components/admin/AuditLogs");
const ProductGeneral = () => import(/*webpackChunkName: "ProductGeneral" */ "../components/admin/productComponents/ProductGeneral");
const ProductDocuments = () => import(/*webpackChunkName: "ProductDocuments" */ "../components/admin/productComponents/ProductDocuments");
const ProductSettings = () => import(/*webpackChunkName: "ProductSettings" */ "../components/admin/productComponents/ProductSettings");
const ProductAccessSettings = () => import(/*webpackChunkName: "ProductAccessSettings" */ "../components/admin/productComponents/ProductAccessSettings");
const ProductConfigurationSettings = () => import(/*webpackChunkName: "ProductConfigurationSettings" */ "../components/admin/productComponents/ProductConfigurationSettings");
const ProductDataSources = () => import(/*webpackChunkName: "ProductDataSources" */ "../components/admin/productComponents/ProductDataSources");
const ProductViews = () => import(/*webpackChunkName: "ProductViews" */ "../components/admin/productComponents/ProductViews");
const ProductFormLayouts = () => import(/*webpackChunkName: "ProductFormLayouts" */ "../components/admin/productComponents/ProductFormLayouts");
const ProductCodeBooks = () => import(/*webpackChunkName: "ProductCodeBooks" */ "../components/admin/productComponents/ProductCodeBooks");
const ProductDocumentCategories = () => import(/*webpackChunkName: "ProductDocumentCategories" */ "../components/admin/productComponents/ProductDocumentCategories");
const ProductDocumentTypes = () => import(/*webpackChunkName: "ProductDocumentTypes" */ "../components/admin/productComponents/ProductDocumentTypes");
const ProductDocumentTypeFieldTypes = () => import(/*webpackChunkName: "ProductDocumentTypes" */ "../components/admin/productComponents/ProductDocumentTypeFieldTypes");
const ProductDocumentTemplateTypes = () => import(/*webpackChunkName: "ProductDocumentTemplateTypes" */ "../components/admin/productComponents/ProductDocumentTemplateTypes");
const ProductCustomUserFields = () => import(/*webpackChunkName: "ProductCustomUserFields" */ "../components/admin/productComponents/ProductCustomUserFields");
const ProductCustomFields = () => import(/*webpackChunkName: "ProductCustomFields" */ "../components/admin/productComponents/ProductCustomFields");
const ProductTags = () => import(/*webpackChunkName: "ProductTags" */ "../components/admin/productComponents/ProductTags");
const ProductOrderDocumentTypes = () => import(/*webpackChunkName: "ProductOrderDocumentTypes" */ "../components/admin/productComponents/ProductOrderDocumentTypes");
const OrganizationDocuments = () => import(/*webpackChunkName: "OrganizationDocuments" */ "../components/admin/organizationComponents/OrganizationDocuments");
const OrganizationSettings = () => import(/*webpackChunkName: "OrganizationSettings" */ "../components/admin/organizationComponents/OrganizationSettings");
const OrganizationGeneral = () => import(/*webpackChunkName: "OrganizationGeneral" */ "../components/admin/organizationComponents/OrganizationGeneral");
const OrganizationProducts = () => import(/*webpackChunkName: "OrganizationProducts" */ "../components/admin/organizationComponents/OrganizationProducts");
const OrganizationCustomers = () => import(/*webpackChunkName: "OrganizationCustomers" */ "../components/admin/organizationComponents/OrganizationCustomers");
const OrganizationVendors = () => import(/*webpackChunkName: "OrganizationVendors" */ "../components/admin/organizationComponents/OrganizationVendors");
const OrganizationGroups = () => import(/*webpackChunkName: "OrganizationGroups" */ "../components/admin/organizationComponents/OrganizationGroups");
const OrganizationUsers = () => import(/*webpackChunkName: "OrganizationUsers" */ "../components/admin/organizationComponents/OrganizationUsers");
const OrganizationViews = () => import(/*webpackChunkName: "OrganizationViews" */ "../components/admin/organizationComponents/OrganizationViews");
const OrganizationFormLayouts = () => import(/*webpackChunkName: "OrganizationFormLayouts" */ "../components/admin/organizationComponents/OrganizationFormLayouts");
const ProductWorkflow = () => import(/*webpackChunkName: "ProductWorkflow" */ "../components/admin/productComponents/ProductWorkflow");
const ProductWorkflowTasks = () => import(/*webpackChunkName: "ProductWorkflowTasks" */ "../components/admin/productComponents/ProductWorkflowTasks");
const ProductWorkflowRules = () => import(/*webpackChunkName: "ProductWorkflowRules" */ "../components/admin/productComponents/ProductWorkflowRules");
const ProductWorkflowNotifications = () => import(/*webpackChunkName: "ProductWorkflowNotifications" */ "../components/admin/productComponents/ProductWorkflowNotifications");
const OrganizationWorkflow = () => import(/*webpackChunkName: "OrganizationWorkflow" */ "../components/admin/organizationComponents/OrganizationWorkflow");
const OrganizationWorkflowTasks = () => import(/*webpackChunkName: "OrganizationWorkflowTasks" */ "../components/admin/organizationComponents/OrganizationWorkflowTasks");
const OrganizationWorkflowRules = () => import(/*webpackChunkName: "OrganizationWorkflowRules" */ "../components/admin/organizationComponents/OrganizationWorkflowRules");
const OrganizationWorkflowNotifications = () =>
  import(/*webpackChunkName: "OrganizationWorkflowNotifications" */ "../components/admin/organizationComponents/OrganizationWorkflowNotifications");
const OrganizationAccessSettings = () => import(/*webpackChunkName: "OrganizationAccessSettings" */ "../components/admin/organizationComponents/OrganizationAccessSettings");
const OrganizationConfigurationSettings = () =>
  import(/*webpackChunkName: "OrganizationConfigurationSettings" */ "../components/admin/organizationComponents/OrganizationConfigurationSettings");
const OrganizationDocumentTemplateTypes = () =>
  import(/*webpackChunkName: "OrganizationDocumentTemplateTypes" */ "../components/admin/organizationComponents/OrganizationDocumentTemplateTypes");
const OrganizationOrderDocumentTypes = () =>
  import(/*webpackChunkName: "OrganizationOrderDocumentTypes" */ "../components/admin/organizationComponents/OrganizationOrderDocumentTypes");
const OrganizationDocumentCategories = () =>
  import(/*webpackChunkName: "OrganizationDocumentCategories" */ "../components/admin/organizationComponents/OrganizationDocumentCategories");
const OrganizationDocumentTypes = () => import(/*webpackChunkName: "OrganizationDocumentTypes" */ "../components/admin/organizationComponents/OrganizationDocumentTypes");
const OrganizationDocumentTypeFieldTypes = () =>
  import(/* webpackChunkName: "OrganizationDocumentTypeFieldTypes" */ "../components/admin/organizationComponents/OrganizationDocumentTypeFieldTypes");
const OrganizationNotificationTemplates = () =>
  import(/*webpackChunkName: "OrganizationNotificationTemplates" */ "../components/admin/organizationComponents/OrganizationNotificationTemplates");
const OrganizationsBranch = () => import(/*webpackChunkName: "OrganizationsBranch" */ "../components/admin/organizationComponents/OrganizationBranch");
const OrganizationCustomUserFields = () => import(/*webpackChunkName: "OrganizationCustomUserFields" */ "../components/admin/organizationComponents/OrganizationCustomUserFields");
const OrganizationCustomFields = () => import(/*webpackChunkName: "OrganizationCustomFields" */ "../components/admin/organizationComponents/OrganizationCustomFields");
const OrganizationDataSources = () => import(/*webpackChunkName: "OrganizationDataSources" */ "../components/admin/organizationComponents/OrganizationDataSources");
const OrganizationTags = () => import(/*webpackChunkName: "OrganizationTags" */ "../components/admin/organizationComponents/OrganizationTags");
const OrganizationBranding = () => import(/*webpackChunkName: "OrganizationBranding" */ "../components/admin/organizationComponents/OrganizationBranding");
const ReportsAdmin = () => import(/*webpackChunkName: "ReportsAdmin" */ "../components/admin/reportsComponents/ReportsAdmin");
const ReportsDocuments = () => import(/*webpackChunkName: "ReportsDocuments" */ "../components/admin/reportsComponents/ReportsDocuments");
const ReportsOrders = () => import(/*webpackChunkName: "ReportsOrders" */ "../components/admin/reportsComponents/ReportsOrders");
const SystemSettings = () => import(/* webpackChunkName: "SystemSettings" */ "../components/admin/systemComponents/SystemSettings");
const StaticTemplate = () => import(/* webpackChunkName: "StaticTemplate" */ "../components/admin/systemComponents/StaticTemplate");
const DataSourceTypes = () => import(/* webpackChunkName: "DataSourceTypes" */ "../components/admin/systemComponents/DataSourceTypes");
const ViewLocations = () => import(/* webpackChunkName: "ViewLocations" */ "../components/admin/systemComponents/ViewLocations");
const FormLocations = () => import(/* webpackChunkName: "FormLocations" */ "../components/admin/systemComponents/FormLocations");
const PlatformAdministrators = () => import(/* webpackChunkName: "PlatformAdministrators" */ "../components/admin/systemComponents/PlatformAdministrators");
const OrganizationsGeneral = () => import(/* webpackChunkName: "OrganizationsGeneral" */ "../components/admin/organizationComponents/OrganizationsGeneral");
const DeliverDocumentsPageHeaders = () => import(/* webpackChunkName: "DeliverDocumentsPageHeaders" */ "../components/deliverDocuments/DeliverDocumentsPageHeaders");
const BlankRoute = () => import(/* webpackChunkName: "BlankRoute" */ "../components/uIElements/BlankRoute");

// User Settings Routes
const UserSettingsContainer = () => import(/*webpackChunkName: "UserSettingsContainer" */ "../components/userSettings/UserSettingsContainer");

// Orders Routes
const OrdersGrid = () => import(/* webpackChunkName: "OrdersGrid" */ "../components/orders/ordersViews/OrdersGrid");
const CreateNewOrderEditor = () => import(/*webpackChunkName: "CreateNewOrderEditor" */ "../components/orders/placeOrder/CreateNewOrderEditor");
const PlaceNewOrder = () => import(/* webpackChunkName: "PlaceNewOrder" */ "../components/orders/placeOrder/PlaceNewOrder");

// Views Routes
const ViewsEditor = () => import(/*webpackChunkName: "ViewsEditor" */ "../components/viewManagement/ViewsEditor");

// Links Routes
const LinksHome = () => import(/*webpackChunkName: "LinkHome" */ "../components/links/LinksHome");

// Base Routes
const WelcomePage = () => import(/*webpackChunkName: "WelcomePage" */ "../components/WelcomePage");

const router = createRouter({
  // vue 3
  // export default new VueRouter({
  mode: "history",
  history: createWebHistory(/*process.env.BASE_URL*/), // FIXME: doesn't work with vue3 need to add BASE_URL to .env
  routes: [
    // Welcome - Default to DESQ
    {
      name: "welcome",
      path: "/welcome",
      component: WelcomePage,
    },

    // Links Welcome
    {
      name: "links-welcome",
      path: "/links/welcome",
      component: WelcomePage,
    },

    // TrulyEsq Welcome
    {
      name: "truly-esq-welcome",
      path: "/trulyesq/welcome",
      component: WelcomePage,
    },

    // Login Desq
    {
      name: "login",
      path: "/login",
      component: Authentication,
    },
    // Login TrulyEsq
    {
      name: "truly-esq-login",
      path: "/trulyesq/login",
      component: Authentication,
    },
    // Login Links
    {
      name: "links-login",
      path: "/links/login",
      component: Authentication,
    },

    // Default for /
    {
      path: "/",
      component: NavHome,
      beforeEnter(to, from, next) {
        if (!store.state.auth.isUserLoaded) {
          next("/welcome");
        } else {
          next();
        }
      },
    },

    // TrulyEsq
    {
      name: "truly-esq",
      path: "/truly-esq",
      component: NavHome,
      beforeEnter(to, from, next) {
        if (!store.state.auth.isUserLoaded) {
          next("/welcome");
        } else {
          next();
        }
      },
      children: [
        {
          name: "truly-esq-orders-grid",
          path: "orders-grid",
          component: OrdersGrid,
        },
        {
          name: "default",
          path: "",
          component: OrdersGrid,
        },
      ],
    },

    // Desq
    {
      name: "d-esq",
      path: "/d-esq",
      component: NavHome,
      beforeEnter(to, from, next) {
        if (!store.state.auth.isUserLoaded) {
          next("/welcome");
        } else {
          next();
        }
      },
      children: [
        {
          name: "d-esq-orders-grid",
          path: "orders-grid",
          component: OrdersGrid,
        },
        {
          name: "default",
          path: "",
          component: OrdersGrid,
        },
      ],
    },

    // Links
    {
      name: "links",
      path: "/links",
      component: LinksHome,
      props: true,
    },

    // User Settings
    {
      name: "user-settings",
      path: "/user-settings",
      component: UserSettingsContainer,
    },

    // Reports
    {
      name: "views-editor",
      path: "/views-editor/:id(\\d+)?/:type",
      component: ViewsEditor,
      props: true,
      beforeEnter(to, from, next) {
        if (store.state.auth.isUserLoaded) {
          next();
        } else {
          next("/login");
        }
      },
    },

    // Deliver Documents
    {
      name: "deliver-documents",
      path: "/deliver-documents",
      component: DeliverDocumentsPageHeaders,
    },

    // Place Order
    {
      name: "place-order",
      path: "/place-order",
      component: PlaceNewOrder,
      children: [
        // Place Order - Desq
        {
          name: "desq-place-order",
          path: "/desq-place-order",
          component: CreateNewOrderEditor,
        },
        // Place Order - TrulyEsq
        {
          name: "truly-esq-place-order",
          path: "/truly-esq-place-order",
          component: CreateNewOrderEditor,
        },
      ],
    },

    // Admin
    {
      name: "admin",
      path: "/admin",
      component: AdminPage,
      beforeEnter(to, from, next) {
        if (store.state.auth.isUserLoaded) {
          if (store.state.auth.isPlatformAdmin) {
            next();
          } else if (store.state.auth.isAdmin) {
            next(`/organization-admin`);
          } else {
            next("/");
          }
        } else {
          next("/login");
        }
      },
      children: [
        // Admin - Products
        {
          name: "products",
          path: "products",
          component: ProductAdmin,
        },
        {
          name: "products-edit",
          path: "products/:op(create|edit)/:id(\\d+)?",
          component: ProductEditor,
          children: [
            {
              name: "product-general",
              path: "general",
              component: ProductGeneral,
            },
            {
              name: "product-views",
              path: "views",
              component: ProductViews,
            },
            {
              name: "product-form-layouts",
              path: "form-layouts",
              component: ProductFormLayouts,
              children: [
                {
                  name: "product-form-layout-edit",
                  path: "product-form-layout-edit",
                  component: BlankRoute,
                },
              ],
            },
            {
              name: "product-workflow",
              path: "workflow",
              component: ProductWorkflow,
              children: [
                {
                  name: "product-workflow-tasks",
                  path: "tasks",
                  component: ProductWorkflowTasks,
                },
                {
                  name: "product-workflow-rules",
                  path: "rules",
                  component: ProductWorkflowRules,
                },
                {
                  name: "product-workflow-notifications",
                  path: "notifications",
                  component: ProductWorkflowNotifications,
                },
              ],
            },
            {
              name: "product-documents",
              path: "documents",
              component: ProductDocuments,
              children: [
                {
                  name: "product-document-template-types",
                  path: "document-templates",
                  component: ProductDocumentTemplateTypes,
                },
                {
                  name: "product-order-document-types",
                  path: "document-order-types",
                  component: ProductOrderDocumentTypes,
                },
                {
                  name: "product-document-categories",
                  path: "document-categories",
                  component: ProductDocumentCategories,
                },
                {
                  name: "product-document-types",
                  path: "document-types",
                  component: ProductDocumentTypes,
                },
                {
                  name: "product-document-fields",
                  path: "document-fields",
                  component: ProductDocumentTypeFieldTypes,
                },
              ],
            },
            {
              name: "product-settings",
              path: "settings",
              component: ProductSettings,
              children: [
                {
                  name: "product-access-settings",
                  path: "access-settings",
                  component: ProductAccessSettings,
                },
                {
                  name: "product-configuration-settings",
                  path: "configuration-settings",
                  component: ProductConfigurationSettings,
                },
                {
                  name: "product-data-sources",
                  path: "data-sources",
                  component: ProductDataSources,
                },
                {
                  name: "product-custom-user-fields",
                  path: "custom-user-fields",
                  component: ProductCustomUserFields,
                },
                {
                  name: "product-custom-fields",
                  path: "custom-fields",
                  component: ProductCustomFields,
                },
                {
                  name: "product-tags",
                  path: "tags",
                  component: ProductTags,
                },
                {
                  name: "product-code-books",
                  path: "code-books",
                  component: ProductCodeBooks,
                },
              ],
            },
          ],
        },

        // Admin - Organizations
        {
          name: "organizations",
          path: "organizations",
          component: OrganizationsGeneral,
          children: [
            {
              name: "organization-search",
              path: "search",
              component: OrganizationSearch,
            },

            // Organizations
            {
              name: "organizations-organizations",
              path: "organizations",
              component: OrganizationAdmin,
            },
            {
              name: "organizations-edit",
              path: "organizations/:op(create|edit)/:id(\\d+)?",
              component: OrganizationEditor,
              children: [
                {
                  name: "organization-general",
                  path: "general",
                  component: OrganizationGeneral,
                },
                {
                  name: "organization-products",
                  path: "products",
                  component: OrganizationProducts,
                },
                {
                  name: "organization-customers",
                  path: "customers",
                  component: OrganizationCustomers,
                },
                {
                  name: "organization-vendors",
                  path: "vendors",
                  component: OrganizationVendors,
                },
                {
                  name: "organization-groups",
                  path: "groups",
                  component: OrganizationGroups,
                },
                {
                  name: "organization-users",
                  path: "users",
                  component: OrganizationUsers,
                },
                {
                  name: "organization-views",
                  path: "views",
                  component: OrganizationViews,
                },
                {
                  name: "organization-form-layouts",
                  path: "form-layouts",
                  component: OrganizationFormLayouts,
                  children: [
                    {
                      name: "organization-form-layout-edit",
                      path: "organization-form-layout-edit",
                      component: BlankRoute,
                    },
                  ],
                },
                {
                  name: "organization-workflow",
                  path: "workflow",
                  component: OrganizationWorkflow,
                  children: [
                    {
                      name: "organization-workflow-tasks",
                      path: "tasks",
                      component: OrganizationWorkflowTasks,
                    },
                    {
                      name: "organization-workflow-rules",
                      path: "rules",
                      component: OrganizationWorkflowRules,
                    },
                    {
                      name: "organization-workflow-notifications",
                      path: "notifications",
                      component: OrganizationWorkflowNotifications,
                    },
                  ],
                },
                {
                  name: "organization-documents",
                  path: "documents",
                  component: OrganizationDocuments,
                  children: [
                    {
                      name: "organization-document-template-types",
                      path: "document-templates",
                      component: OrganizationDocumentTemplateTypes,
                    },
                    {
                      name: "organization-order-document-types",
                      path: "document-order-types",
                      component: OrganizationOrderDocumentTypes,
                    },
                    {
                      name: "organization-document-categories",
                      path: "document-categories",
                      component: OrganizationDocumentCategories,
                    },
                    {
                      name: "organization-document-types",
                      path: "document-types",
                      component: OrganizationDocumentTypes,
                    },
                    {
                      name: "organization-document-types-field-types",
                      path: "document-fields",
                      component: OrganizationDocumentTypeFieldTypes,
                    },
                  ],
                },
                {
                  name: "organization-branding",
                  path: "branding",
                  component: OrganizationBranding,
                },
                {
                  name: "organization-settings",
                  path: "settings",
                  component: OrganizationSettings,
                  children: [
                    {
                      name: "organization-access-settings",
                      path: "access-settings",
                      component: OrganizationAccessSettings,
                    },
                    {
                      name: "organization-configuration-settings",
                      path: "configuration-settings",
                      component: OrganizationConfigurationSettings,
                    },
                    {
                      name: "organization-data-sources",
                      path: "data-sources",
                      component: OrganizationDataSources,
                    },
                    {
                      name: "organization-custom-user-fields",
                      path: "custom-user-fields",
                      component: OrganizationCustomUserFields,
                    },
                    {
                      name: "organization-custom-fields",
                      path: "custom-fields",
                      component: OrganizationCustomFields,
                    },
                    {
                      name: "organization-tags",
                      path: "tags",
                      component: OrganizationTags,
                    },
                    {
                      name: "organization-message-templates",
                      path: "message-templates",
                      component: OrganizationNotificationTemplates,
                    },
                    {
                      name: "organization-branches",
                      path: "branches",
                      component: OrganizationsBranch,
                    },
                  ],
                },
              ],
            },
          ],
        },

        // Admin - System
        {
          name: "system",
          path: "system",
          component: SystemGeneral,
          children: [
            {
              name: "system-settings",
              path: "settings",
              component: SystemSettings,
            },
            {
              name: "system-static-templates",
              path: "static-templates",
              component: StaticTemplate,
            },
            {
              name: "data-source-types",
              path: "data-source-types",
              component: DataSourceTypes,
            },
            {
              name: "view-locations",
              path: "view-locations",
              component: ViewLocations,
            },
            {
              name: "form-locations",
              path: "form-locations",
              component: FormLocations,
            },
            {
              name: "system-administrators",
              path: "administrators",
              component: PlatformAdministrators,
            },
            {
              name: "system-documents",
              path: "system-documents",
              component: SystemDocuments,
              children: [
                {
                  name: "document-categories",
                  path: "document-categories",
                  component: SystemDocumentCategories,
                },
                {
                  name: "document-types",
                  path: "document-types",
                  component: DocumentTypes,
                },
                {
                  name: "document-type-field-types",
                  path: "document-type-field-types",
                  component: DocumentTypeFieldTypes,
                },
                {
                  name: "potential-document-types",
                  path: "potential-document-types",
                  component: PotentialDocumentTypes,
                },
              ],
            },
          ],
        },

        // Admin - Reports
        {
          name: "reports",
          path: "reports",
          component: ReportsAdmin,
          children: [
            {
              name: "reports-documents",
              path: "documents",
              component: ReportsDocuments,
            },
            {
              name: "reports-orders",
              path: "orders",
              component: ReportsOrders,
            },
          ],
        },

        // Admin - Logs
        {
          name: "logs",
          path: "logs",
          component: AuditLogs,
        },

        // Admin - Default
        {
          name: "default",
          path: "",
          redirect: "/admin/documents",
        },
      ],
    },

    // Admin - Organization Admin
    {
      name: "organization-admin",
      path: "/organization-admin",
      component: OrganizationAdminEditor,
      children: [
        {
          name: "organization-admin-general",
          path: "general",
          component: OrganizationGeneral,
        },
        {
          name: "organization-admin-products",
          path: "products",
          component: OrganizationProducts,
        },
        {
          name: "organization-admin-customers",
          path: "customers",
          component: OrganizationCustomers,
        },
        {
          name: "organization-admin-vendors",
          path: "vendors",
          component: OrganizationVendors,
        },
        {
          name: "organization-admin-groups",
          path: "groups",
          component: OrganizationGroups,
        },
        {
          name: "organization-admin-users",
          path: "users",
          component: OrganizationUsers,
        },
        {
          name: "organization-admin-views",
          path: "views",
          component: OrganizationViews,
        },
        {
          name: "organization-admin-form-layouts",
          path: "form-layouts",
          component: OrganizationFormLayouts,
          children: [
            {
              name: "organization-admin-form-layout-edit",
              path: "organization-form-layout-edit",
              component: BlankRoute,
            },
          ],
        },
        {
          name: "organization-admin-workflow",
          path: "workflow",
          component: OrganizationWorkflow,
          children: [
            {
              name: "organization-admin-workflow-tasks",
              path: "tasks",
              component: OrganizationWorkflowTasks,
            },
            {
              name: "organization-admin-workflow-rules",
              path: "rules",
              component: OrganizationWorkflowRules,
            },
            {
              name: "organization-admin-workflow-notifications",
              path: "notifications",
              component: OrganizationWorkflowNotifications,
            },
          ],
        },
        {
          name: "organization-admin-documents",
          path: "documents",
          component: OrganizationDocuments,
          children: [
            {
              name: "organization-admin-document-template-types",
              path: "document-templates",
              component: OrganizationDocumentTemplateTypes,
            },
            {
              name: "organization-admin-order-document-types",
              path: "document-order-types",
              component: OrganizationOrderDocumentTypes,
            },
            {
              name: "organization-admin-document-categories",
              path: "document-categories",
              component: OrganizationDocumentCategories,
            },
            {
              name: "organization-admin-document-types",
              path: "document-types",
              component: OrganizationDocumentTypes,
            },
          ],
        },
        {
          name: "organization-admin-branding",
          path: "branding",
          component: OrganizationBranding,
        },
        {
          name: "organization-admin-settings",
          path: "settings",
          component: OrganizationSettings,
          children: [
            {
              name: "organization-admin-access-settings",
              path: "access-settings",
              component: OrganizationAccessSettings,
            },
            {
              name: "organization-admin-configuration-settings",
              path: "configuration-settings",
              component: OrganizationConfigurationSettings,
            },
            {
              name: "organization-admin-data-sources",
              path: "data-sources",
              component: OrganizationDataSources,
            },
            {
              name: "organization-admin-custom-user-fields",
              path: "custom-user-fields",
              component: OrganizationCustomUserFields,
            },
            {
              name: "organization-admin-custom-fields",
              path: "custom-fields",
              component: OrganizationCustomFields,
            },
            {
              name: "organization-admin-tags",
              path: "tags",
              component: OrganizationTags,
            },
            {
              name: "organization-admin-message-templates",
              path: "message-templates",
              component: OrganizationNotificationTemplates,
            },
            {
              name: "organization-admin-branches",
              path: "branches",
              component: OrganizationsBranch,
            },
          ],
        },
      ],
    },

    // Default
    {
      path: "/:catchAll(.*)",
      redirect: "/",
    },
  ],
});

export default router;
