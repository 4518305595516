export const QUICK_SEARCH_TEXT = "quickSearchText";
export const QUICK_SEARCH_ORDER_NUMBER = "quickSearchOrderNumber";
export const QUICK_SEARCH_ORDER_ROOT_ID = "quickSearchOrderRootId";
export const LOAD_QUICK_SEARCH = "loadQuickSearch";
export const QUICK_SEARCH_VIEW_TYPE = "quickSearchViewType";
export const QUICK_SEARCH_DATASOURCE_ROOT_ID = "quickSearchDatasourceRootId";
export const ORGANIZATION_ROOT_ID = "organizationRootId";
export const USER_ROOT_ID = "userRootId";
export const ORDER_ROOT_ID = "orderRootId";
export const LOADING_ORDER = "loadingOrder";
export const ORDER = "order";
export const FORM_LOCATION_ROOT_ID = "formLocationRootId";
export const VIEWS_LOCATION_ROOT_ID = "viewsLocationRootId";
export const SERVICE_VENDOR_ROOT_ID = "serviceVendorRootId";
export const MESSAGE_ATTACHMENTS = "messageAttachments";
export const SERVICE_ORDER_ROOT_ID = "serviceOrderRootId";
export const MODAL_SAVE_ERRORS = "modalSaveErrors";
export const DISABLED_UPLOAD_IDS = "disabledUploadIds";
export const SAVED_UPLOADS = "savedUploads";
export const FILTERED_SAVED_UPLOADS = "filteredSavedUploads";

export const SELECTED_DATASOURCE_ITEMS = "selectedDataSourceItems";
export const SELECTED_DATASOURCE_ITEM_SOURCE_FORM_ROOT_ID = "selectedDataSourceItemSourceFormRootId";
