<template>
  <div class="flex pb-0.5 pr-2">
    <span>
      <span v-if="isAuthenticated">
        <span v-if="inPlatformAdminMode || inAdminMode">
          <a>
            <UserDropDown title="Settings" :items="isAdmin ? servicesAdminPortal : servicesUser" />
          </a>
        </span>
        <span v-else>
          <a>
            <UserDropDown title="Settings" :items="isAdmin ? servicesAdminHome : servicesUser" />
          </a>
        </span>
      </span>
      <span v-else>
        <router-link to="/login"> Login </router-link>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-8 my-0.5">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
      </span>
    </span>
    <div class="ml-2 mt-0.5 pt-0.5">
      <FeedbackButton innerCssClass="w-6 h-6 pt-1" outerCssClass="cursor-pointer" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

import UserDropDown from "./UserDropdown";
import FeedbackButton from "@/components/uIElements/FeedbackButton.vue";

export default {
  name: "UserLoginStatus",
  components: {
    UserDropDown,
    FeedbackButton,
  },
  setup() {
    // Vuex
    const store = useStore();
    // State
    const currentHomeRoute = computed(() => store.state.auth.currentHomeRoute);
    const isAdmin = computed(() => store.state.auth.isAdmin);
    const inPlatformAdminMode = computed(() => store.state.auth.inPlatformAdminMode);
    const inAdminMode = computed(() => store.state.auth.inAdminMode);
    // Getters
    const isAuthenticated = computed(() => store.getters.isAuthenticated);

    // Computed
    const servicesAdminHome = computed(() => [
      {
        text: "Home",
        target: ".e-content",
        link: currentHomeRoute.value.includes("/links") ? "/links" : "orders-grid",
      },
      {
        text: "Admin",
        target: ".e-content",
        link: "/admin/products",
      },
      {
        text: "Settings",
        target: ".e-content",
        link: "",
      },
      {
        text: "Logout",
        target: ".e-content",
        link: "logout",
      },
    ]);
    const servicesAdminPortal = computed(() => [
      {
        text: "Home",
        target: ".e-content",
        link: currentHomeRoute.value.includes("/links") ? "/links" : "orders-grid",
      },
      {
        text: "Settings",
        target: ".e-content",
        link: "",
      },
      {
        text: "Logout",
        target: ".e-content",
        link: "logout",
      },
    ]);
    const servicesUser = computed(() => [
      {
        text: "Home",
        target: ".e-content",
        link: currentHomeRoute.value.includes("/links") ? "/links" : "orders-grid",
      },
      {
        text: "Settings",
        target: ".e-content",
        link: "",
      },
      {
        text: "Logout",
        target: ".e-content",
        link: "logout",
      },
    ]);

    return {
      isAdmin,
      inPlatformAdminMode,
      inAdminMode,
      isAuthenticated,
      servicesAdminHome,
      servicesAdminPortal,
      servicesUser,
    };
  },
};
</script>
