import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productDocumentTypeCategoriesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDocumentTypeCategories: [],
    productDocumentTypeCategoriesCachedProductRootId: 0,
  },
  getters: {},
  mutations: {
    setProductDocumentTypeCategories(state, productDocumentTypeCategoriesData) {
      state.productDocumentTypeCategories = productDocumentTypeCategoriesData?.sort((pdtc1, pdtc2) => (pdtc1.DataCategoryNumber > pdtc2.DataCategoryNumber ? 1 : -1)) || [];
    },
    setProductDocumentTypeCategoriesCachedProductRootId(state, productDocumentTypeCategoriesCachedProductRootId) {
      state.productDocumentTypeCategoriesCachedProductRootId = Number(productDocumentTypeCategoriesCachedProductRootId || 0);
    },
    _addProductDocumentTypeCategory(state, productDocumentTypeCategory) {
      const productDocumentTypeCategoriesData = _.cloneDeep(state.productDocumentTypeCategories) || [];
      productDocumentTypeCategoriesData.push(productDocumentTypeCategory);
      state.productDocumentTypeCategories = productDocumentTypeCategoriesData.sort((pdtc1, pdtc2) => (pdtc1.DataCategoryNumber > pdtc2.DataCategoryNumber ? 1 : -1)) || [];
    },
    _updateProductDocumentTypeCategory(state, updateProductDocumentTypeCategory) {
      const rootId = Number(updateProductDocumentTypeCategory?.RootId || 0);
      if (rootId > 0) {
        let productDocumentTypeCategoriesData = _.cloneDeep(state.productDocumentTypeCategories) || [];
        state.productDocumentTypeCategories = [...productDocumentTypeCategoriesData.filter(productDocumentTypeCategory => Number(productDocumentTypeCategory?.RootId || 0) !== rootId), _.cloneDeep(updateProductDocumentTypeCategory)].sort((pdtc1, pdtc2) => (pdtc1.DataCategoryNumber > pdtc2.DataCategoryNumber ? 1 : -1)) || [];
      }
    },
    _removeProductDocumentTypeCategory(state, deleteProductDocumentTypeCategory) {
      const rootId = Number(deleteProductDocumentTypeCategory?.RootId || 0);
      if (rootId > 0) {
        let productDocumentTypeCategoriesData = _.cloneDeep(state.productDocumentTypeCategories) || [];
        state.productDocumentTypeCategories = [...productDocumentTypeCategoriesData.filter(productDocumentTypeCategory => Number(productDocumentTypeCategory?.RootId || 0) !== rootId)].sort((pdtc1, pdtc2) => (pdtc1.DataCategoryNumber > pdtc2.DataCategoryNumber ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getProductDocumentTypeCategories(context, {
      ProductRootId,
      IncludeMetadata,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.productDocumentTypeCategoriesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${productDocumentTypeCategoriesUrl
          }?productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setProductDocumentTypeCategories", data);
        context.commit("setProductDocumentTypeCategoriesCachedProductRootId", ProductRootId);
        return data;
      }, "productDocumentTypeCategories - getProductDocumentTypeCategories");
    },
    async addProductDocumentTypeCategory(context, productDocumentTypeCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDocumentTypeCategoriesUrl, Payload: productDocumentTypeCategory });
        context.commit("_addProductDocumentTypeCategory", data);
      }, "productDocumentTypeCategories - addProductDocumentTypeCategory");
    },
    async updateProductDocumentTypeCategory(context, productDocumentTypeCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeCategoriesUrl}/${Number(productDocumentTypeCategory?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypeCategory });
        context.commit("_updateProductDocumentTypeCategory", data);
      }, "productDocumentTypeCategories - updateProductDocumentTypeCategory");
    },
    async removeProductDocumentTypeCategory(context, productDocumentTypeCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeCategoriesUrl}/${Number(productDocumentTypeCategory?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductDocumentTypeCategory", productDocumentTypeCategory);
      }, "productDocumentTypeCategories - removeProductDocumentTypeCategory");
    },
  },
};
