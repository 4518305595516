import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  DeleteVerb,
  organizationDocumentCategoriesUrl,
  organizationDocumentCategoryPartiesUrl,
  organizationDocumentCategoryPartyRolesUrl,
} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDocumentCategories: [],
    organizationDocumentCategoriesCachedOrganizationRootId: 0,
    organizationDocumentCategoriesCachedProductRootId: 0,
  },
  getters: {
    getOrganizationDocumentCategoryByName: (state) => (name) => {
      return state.organizationDocumentCategories?.find((organizationDocumentCategory) => String(organizationDocumentCategory?.Name || "") === String(name || "")) || null;
    },
    getOrganizationDocumentCategoriesForProduct: (state) => (productRootId) => {
      let result = [];
      state.organizationDocumentCategories.forEach((organizationDocumentCategory) => {
        if (Number(organizationDocumentCategory.ProductRootId) === Number(productRootId)) {
          result.push(_.cloneDeep(organizationDocumentCategory));
        }
      });
      return result.sort((odc1, odc2) => (odc1.DocumentCategoryName > odc2.DocumentCategoryName ? 1 : -1)) || [];
    },
    getNextOrganizationDocumentCategoryRootId: (state) => {
      let result = 1;
      state.organizationDocumentCategories.forEach((organizationDocumentCategory) => {
        if (Number(organizationDocumentCategory.RootId) >= result) {
          result = Number(organizationDocumentCategory.RootId) + 1;
        }
      });
      return result;
    },
    areOrganizationDocumentCategoriesLoaded:
      (state) =>
        ({ ProductRootId, OrganizationRootId }) => {
          ProductRootId = Number(ProductRootId || 0);
          OrganizationRootId = Number(OrganizationRootId || 0);
          return (
            ProductRootId > 0 &&
            OrganizationRootId > 0 &&
            state.organizationDocumentCategories?.find(
              (organizationDocumentCategory) =>
                Number(organizationDocumentCategory?.ProductRootId || 0) === ProductRootId && Number(organizationDocumentCategory?.OrganizationRootId || 0) === OrganizationRootId
            ) != null
          );
        },
    isOrganizationDocumentCategoryNameTaken: (state) => (currentOrganizationDocumentCategory) => {
      const { organizationDocumentCategories } = state;
      if (organizationDocumentCategories?.length < 1) {
        return false;
      }
      if (!currentOrganizationDocumentCategory) {
        return true;
      }
      const { Name: currentName, RootId } = currentOrganizationDocumentCategory;
      if (!(currentName?.length > 0)) {
        return true;
      }
      if (Number(RootId || 0) < 1) {
        return true;
      }
      return !!organizationDocumentCategories.find((organizationDocumentCategory) => String(organizationDocumentCategory?.Name || "") === String(currentName || "") && Number(RootId || 0) !== Number(organizationDocumentCategory?.RootId || 0));
    },
  },
  mutations: {
    setOrganizationDocumentCategories(state, organizationDocumentCategoriesData) {
      state.organizationDocumentCategories = organizationDocumentCategoriesData?.sort((odc1, odc2) => (odc1.Name > odc2.Name ? 1 : -1)) || [];
    },
    setOrganizationDocumentCategoriesCachedOrganizationRootId(state, organizationDocumentCategoriesCachedOrganizationRootId) {
      state.organizationDocumentCategoriesCachedOrganizationRootId = Number(organizationDocumentCategoriesCachedOrganizationRootId || 0);
    },
    setOrganizationDocumentCategoriesCachedProductRootId(state, organizationDocumentCategoriesCachedProductRootId) {
      state.organizationDocumentCategoriesCachedProductRootId = Number(organizationDocumentCategoriesCachedProductRootId || 0);
    },
    _addOrganizationDocumentCategory(state, organizationDocumentCategory) {
      const organizationDocumentCategoriesData = _.cloneDeep(state.organizationDocumentCategories) || [];
      organizationDocumentCategoriesData.push(organizationDocumentCategory);
      state.organizationDocumentCategories = organizationDocumentCategoriesData.sort((odc1, odc2) => (odc1.Name > odc2.Name ? 1 : -1)) || [];
    },
    _updateOrganizationDocumentCategory(state, updateOrganizationDocumentCategory) {
      const rootId = Number(updateOrganizationDocumentCategory?.RootId || 0);
      if (rootId > 0) {
        let organizationDocumentCategoriesData = _.cloneDeep(state.organizationDocumentCategories) || [];
        organizationDocumentCategoriesData =
          [
            ...organizationDocumentCategoriesData.filter((organizationDocumentCategory) => Number(organizationDocumentCategory?.RootId || 0) !== rootId),
            _.cloneDeep(updateOrganizationDocumentCategory),
          ].sort((odc1, odc2) => (odc1.Name > odc2.Name ? 1 : -1)) || [];
        state.organizationDocumentCategories = organizationDocumentCategoriesData;
      }
    },
    _updateOrganizationDocumentCategoryEnabled(state, updateOrganizationDocumentCategory) {
      const rootId = Number(updateOrganizationDocumentCategory?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentCategory = state.organizationDocumentCategoriesData?.find((organizationDocumentCategory) => Number(organizationDocumentCategory?.RootId ?? 0) === rootId) ?? null;
        if (organizationDocumentCategory?.RootId > 0) {
          organizationDocumentCategory.Enabled = updateOrganizationDocumentCategory.Enabled;
        }
      }
    },
    _removeOrganizationDocumentCategory(state, deleteOrganizationDocumentCategory) {
      const rootId = Number(deleteOrganizationDocumentCategory?.RootId || 0);
      if (rootId > 0) {
        let organizationDocumentCategoriesData = _.cloneDeep(state.organizationDocumentCategories) || [];
        organizationDocumentCategoriesData =
          [...organizationDocumentCategoriesData.filter((organizationDocumentCategory) => Number(organizationDocumentCategory?.RootId || 0) !== rootId)].sort((odc1, odc2) =>
            odc1.Name > odc2.Name ? 1 : -1
          ) || [];
        state.organizationDocumentCategories = organizationDocumentCategoriesData;
      }
    },
  },
  actions: {
    async getOrganizationDocumentCategories(
      context,
      {
        OrganizationRootId,
        ProductRootId,
        IncludeMetadata,
        IncludeOrganizationDocumentTypes,
        IncludeSystemLevelMetadata,
        IncludeProductLevelMetadata,
        IncludeOrganizationLevelMetadata,
        UseCache,
      }
    ) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId || 0);
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeOrganizationDocumentTypes = IncludeOrganizationDocumentTypes || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        IncludeOrganizationLevelMetadata = IncludeOrganizationLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.organizationDocumentCategoriesCachedOrganizationRootId === OrganizationRootId) &&
          (context.state.organizationDocumentTypeFieldTypesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${organizationDocumentCategoriesUrl
          }?organizationrootid=${OrganizationRootId
          }&productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includeorganizationdocumenttypes=${IncludeOrganizationDocumentTypes
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata
          }&includeorganizationlevelmetadata=${IncludeOrganizationLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationDocumentCategories", data);
        context.commit("setOrganizationDocumentCategoriesCachedOrganizationRootId", OrganizationRootId);
        context.commit("setOrganizationDocumentCategoriesCachedProductRootId", ProductRootId);
        return data;
      }, "organizationDocumentCategories - getOrganizationDocumentCategories");
    },
    async addOrganizationDocumentCategories(context, organizationDocumentCategories) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentCategoriesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentCategories });
        context.commit("setOrganizationDocumentCategories", data);
      }, "organizations - addOrganizationDocumentCategory");
    },
    async addOrganizationDocumentCategory(context, organizationDocumentCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentCategoriesUrl, Payload: organizationDocumentCategory });
        context.commit("_addOrganizationDocumentCategory", data);
      }, "organizations - addOrganizationDocumentCategory");
    },
    async updateOrganizationDocumentCategory(context, organizationDocumentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentCategoriesUrl}/${organizationDocumentCategory.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentCategory });
        context.commit("_updateOrganizationDocumentCategory", data);
      }, "organizations - updateOrganizationDocumentCategory");
    },
    async removeOrganizationDocumentCategory(context, organizationDocumentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentCategoriesUrl}/${organizationDocumentCategory.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeCurrentSelectedOrganizationDocumentCategory", organizationDocumentCategory);
        context.commit("_removeOrganizationDocumentCategory", organizationDocumentCategory);
      }, "organizations - removeOrganizationDocumentCategory");
    },
    async updateOrganizationDocumentCategoryPositions(context, organizationDocumentCategories) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentCategoriesUrl}/updatepositions`;
        if (organizationDocumentCategories?.length > 0) {
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentCategories });
          context.commit("setOrganizationDocumentCategories", data);
        }
      }, "organizationDocumentCategories - updateOrganizationDocumentCategoryPositions");
    },
    async updateOrganizationDocumentCategoryEnabledStatus(context, organizationDocumentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentCategoriesUrl}/${Number(organizationDocumentCategory?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentCategory });
        context.commit("_updateOrganizationDocumentCategoryEnabled", data);
      }, "organizations - updateOrganizationDocumentCategoryEnabledStatus");
    },
    async getOrganizationDocumentCategoryPartiesAndPartyRoles(context, organizationDocumentCategory) {
      await timeOperation(async () => {
        if (organizationDocumentCategory?.RootId > 0) {
          await context.dispatch("getOrganizationDocumentCategoryParties", organizationDocumentCategory);
          await context.dispatch("getOrganizationDocumentCategoryPartyRoles", organizationDocumentCategory);
        }
      }, "organizationDocumentCategories - getOrganizationDocumentCategoryPartiesAndPartyRoles");
    },
    async getOrganizationDocumentCategoryParties(context, organizationDocumentCategory) {
      await timeOperation(async () => {
        if (organizationDocumentCategory?.RootId > 0) {
          const formattedUrl = `${organizationDocumentCategoryPartiesUrl}?organizationdocumentcategoryrootid=${Number(organizationDocumentCategory.RootId || 0)}&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          organizationDocumentCategory.OrganizationDocumentCategoryParties = data || [];
        }
      }, "organizationDocumentCategories - getOrganizationDocumentCategoryParties");
    },
    async addOrganizationDocumentCategoryParty(context, organizationDocumentCategoryParty) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentCategoryPartiesUrl, Payload: organizationDocumentCategoryParty });
      }, "organizationDocumentCategories - addOrganizationDocumentCategoryParty");
    },
    async removeOrganizationDocumentCategoryParty(context, organizationDocumentCategoryParty) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentCategoryPartiesUrl}/${Number(organizationDocumentCategoryParty?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizationDocumentCategories - removeOrganizationDocumentCategoryParty");
    },
    async getOrganizationDocumentCategoryPartyRoles(context, organizationDocumentCategory) {
      await timeOperation(async () => {
        if (organizationDocumentCategory?.RootId > 0) {
          const formattedUrl = `${organizationDocumentCategoryPartyRolesUrl}?organizationdocumentcategoryrootid=${Number(organizationDocumentCategory?.RootId || 0)}&includemetadata=true`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          organizationDocumentCategory.OrganizationDocumentCategoryPartyRoles = data || [];
        }
      }, "organizationDocumentCategories - getOrganizationDocumentCategoryPartyRoles");
    },
    async addOrganizationDocumentCategoryPartyRole(context, organizationDocumentCategoryPartyRole) {
      await timeOperation(async () => {
        await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentCategoryPartyRolesUrl, Payload: organizationDocumentCategoryPartyRole });
      }, "organizationDocumentCategories - addOrganizationDocumentCategoryPartyRole");
    },
    async removeOrganizationDocumentCategoryPartyRole(context, organizationDocumentCategoryPartyRole) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentCategoryPartyRolesUrl}/${Number(organizationDocumentCategoryPartyRole?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "organizationDocumentCategories - removeOrganizationDocumentCategoryPartyRole");
    },
  },
};
