import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, documentCategoriesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    documentCategories: [],
    selectedAdminDocumentCategory: null,
  },
  getters: {
    getDocumentCategoryByName: (state) => (name) => {
      return state.documentCategories.find((documentCategory) => String(documentCategory?.Name || "") === String(name || "")) || null;
    },
    getNextDocumentCategoryRootId: (state) => {
      let result = 1;
      state.documentCategories.forEach((documentCategory) => {
        if (Number(documentCategory.RootId) >= result) {
          result = Number(documentCategory.RootId) + 1;
        }
      });
      return result;
    },
    areDocumentCategoriesLoaded: (state) => () => {
      return state.documentCategories?.length > 0;
    },
    isDocumentCategoryNameTaken:
      (state) =>
      (currentDocumentCategory, isNew = false) => {
        const { DocumentCategories } = state;
        if (DocumentCategories?.length < 1) {
          return false;
        }
        if (!currentDocumentCategory) {
          return true;
        }
        const { Name: currentName, RootId } = currentDocumentCategory;
        if (!(currentName?.length > 0)) {
          return false;
        }
        if (Number(RootId || 0) < 1 && !isNew) {
          return true;
        }
        return !!state.documentCategories.find(
          (documentCategory) => String(documentCategory?.Name || "") === String(currentName || "") && Number(RootId || 0) !== Number(documentCategory?.RootId || 0)
        );
      },
  },
  mutations: {
    setSelectedAdminDocumentCategory(state, newDocumentCategory) {
      state.selectedAdminDocumentCategory = _.cloneDeep(newDocumentCategory);
    },
    setDocumentCategories(state, documentCategoriesData) {
      state.documentCategories = documentCategoriesData?.sort((dc1, dc2) => (dc1.Name > dc2.Name ? 1 : -1)) || [];
    },
    _addDocumentCategory(state, documentCategory) {
      const documentCategoriesData = _.cloneDeep(state.documentCategories) || [];
      documentCategoriesData.push(documentCategory);
      state.documentCategories = documentCategoriesData.sort((dc1, dc2) => (dc1.Name > dc2.Name ? 1 : -1)) || [];
    },
    _updateDocumentCategory(state, updateDocumentCategory) {
      const rootId = Number(updateDocumentCategory?.RootId || 0);
      if (rootId > 0) {
        let documentCategoriesData = _.cloneDeep(state.documentCategories) || [];
        state.documentCategories =
          [...documentCategoriesData.filter((documentCategory) => Number(documentCategory?.RootId || 0) !== rootId), _.cloneDeep(updateDocumentCategory)].sort((dc1, dc2) =>
            dc1.Name > dc2.Name ? 1 : -1
          ) || [];
      }
    },
    _removeDocumentCategory(state, deleteDocumentCategory) {
      const rootId = Number(deleteDocumentCategory?.RootId || 0);
      if (rootId > 0) {
        let documentCategoriesData = _.cloneDeep(state.documentCategories) || [];
        state.documentCategories =
          [...documentCategoriesData.filter((documentCategory) => Number(documentCategory?.RootId || 0) !== rootId)].sort((dc1, dc2) => (dc1.Name > dc2.Name ? 1 : -1)) || [];
      }
    },
  },
  actions: {
    async getDocumentCategories(context, { UseCache, IncludeMetadata, IncludeDocumentTypes }) {
      await timeOperation(async () => {
        UseCache = UseCache || false;
        IncludeMetadata = IncludeMetadata || false;
        IncludeDocumentTypes = IncludeDocumentTypes || false;
        if (!UseCache || !(context.state.documentCategories?.length > 0)) {
          const formattedUrl = `${documentCategoriesUrl}?includemetadata=${IncludeMetadata}&includedocumenttypes=${IncludeDocumentTypes}`;
          const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
          context.commit("setDocumentCategories", data);
        }
      }, "documentCategories - getDocumentCategories");
    },
    async addDocumentCategory(context, documentCategory) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: documentCategoriesUrl, Payload: documentCategory });
        context.commit("_addDocumentCategory", data);
      }, "documentCategories - addDocumentCategory");
    },
    async addDocumentCategories(context, documentCategories) {
      await timeOperation(async () => {
        const formattedUrl = `${documentCategoriesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: documentCategories });
        context.commit("setDocumentCategories", data);
      }, "documentCategories - addDocumentCategory");
    },
    async updateDocumentCategory(context, documentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${documentCategoriesUrl}/${Number(documentCategory?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: documentCategory });
        context.commit("_updateDocumentCategory", data);
      }, "documentCategories - updateDocumentCategory");
    },
    async removeDocumentCategory(context, documentCategory) {
      await timeOperation(async () => {
        const formattedUrl = `${documentCategoriesUrl}/${Number(documentCategory?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeDocumentCategory", documentCategory);
      }, "documentCategories - removeDocumentCategory");
    },
  },
};
