import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  DeleteVerb,
  productsUrl,
  productDocumentTemplateTypesUrl,
  productOrderDocumentTypesUrl,
  productCodeBooksUrl,
  productAccessSettingsUrl,
  productConfigSettingsUrl,
  validateNamesUrl,
  productCodeBookCodesUrl,
} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";
import {
  TrulyEsqProductRootId,
  DesqProductRootId,
  LinksProductRootId,
} from "@/lib/settings";

export default {
  state: {
    products: [],
    currentSelectedProduct: {},
    productDocumentTemplateTypes: [],
    productOrderDocumentTypes: [],
    productCodeBooks: [],
    productAccessSettings: [],
    productConfigSettings: [],
    productNameSearchData: [],
    selectedProductCodeBook: {},
    productDocumentTemplateTypeWithData: {},
    productDocumentTemplateTypeFields: {},
    currentTrulyEsqPageRoute: "",
    showRequiredValuesError: false,
    reloadSavedViewData: false,
    isEditingProduct: false,
  },
  getters: {
    getProductSourceName: () => (rootId) => {
      switch (rootId) {
        case TrulyEsqProductRootId:
          return "TrulyEsq";
        case DesqProductRootId:
          return "Desq";
        case LinksProductRootId:
          return "Links";
      }
      return "";
    },
    getProductByRootId: (state) => (rootId) => {
      return state.products.find((product) => Number(product?.RootId || 0) === Number(rootId || 0)) || null;
    },
    getProductByDisplayName: (state) => (displayName) => {
      return state.products.find((product) => String(product?.DisplayName || "") === String(displayName || "")) || null;
    },
    getProductDocumentTemplateTypeByName: (state) => (name) => {
      return state.productDocumentTemplateTypes.find((productDocumentTemplateType) => String(productDocumentTemplateType?.Name || "") === String(name || "")) || null;
    },
    getProductDocumentTemplateTypeByRootId: (state) => (rootId) => {
      return state.productDocumentTemplateTypes.find((productDocumentTemplateType) => Number(productDocumentTemplateType?.RootId || 0) === Number(rootId || 0)) || null;
    },
    getNextProductDocumentTemplateTypeRootId: (state) => (productRootId) => {
      let result = 1;
      productRootId = Number(productRootId || 0);
      if (productRootId > 0) {
        state.productDocumentTemplateTypes.forEach((productDocumentTemplateType) => {
          if (Number(productDocumentTemplateType?.ProductRootId || 0) === productRootId) {
            if (Number(productDocumentTemplateType?.RootId || 0) >= result) {
              result = Number(productDocumentTemplateType.RootId) + 1;
            }
          }
        });
      }
      return result;
    },
    getProductOrderDocumentTypeByName: (state) => (name) => {
      return state.productOrderDocumentTypes.find((productOrderDocumentType) => String(productOrderDocumentType?.Name || "") === String(name || "")) || null;
    },
    getNextProductOrderDocumentTypeRootId: (state) => (productRootId) => {
      let result = 1;
      productRootId = Number(productRootId || 0);
      if (productRootId > 0) {
        state.productOrderDocumentTypes.forEach((productOrderDocumentType) => {
          if (Number(productOrderDocumentType?.ProductRootId || 0) === productRootId) {
            if (Number(productOrderDocumentType?.RootId || 0) >= result) {
              result = Number(productOrderDocumentType.RootId) + 1;
            }
          }
        });
      }
      return result;
    },
    getProductCodeBookByName: (state) => (name) => {
      return state.productCodeBooks.find((productCodeBook) => String(productCodeBook?.Name || "") === String(name || "")) || null;
    },
    getNextProductCodeBookRootId: (state) => (productRootId) => {
      let result = 1;
      productRootId = Number(productRootId || 0);
      if (productRootId > 0) {
        state.productCodeBooks.forEach((productCodeBook) => {
          if (Number(productCodeBook?.ProductRootId || 0) === productRootId) {
            if (Number(productCodeBook?.RootId || 0) >= result) {
              result = Number(productCodeBook.RootId) + 1;
            }
          }
        });
      }
      return result;
    },
    getProductCodeBookCodeByRootId: (state) => (rootId) => {
      return state.selectedProductCodeBook?.ProductCodeBookCodes?.find((productCodeBookCode) => Number(productCodeBookCode?.RootId || 0) === Number(rootId || 0)) || null;
    },
    getNextProductCodeBookCodeRootId: (state) => (productCodeBookRootId) => {
      let result = 1;
      productCodeBookRootId = Number(productCodeBookRootId || 0);
      if (
        productCodeBookRootId > 0 &&
        state.selectedProductCodeBook?.ProductCodeBookCodes?.length > 0 &&
        productCodeBookRootId === Number(state.selectedProductCodeBook.RootId || 0)
      ) {
        state.selectedProductCodeBook.ProductCodeBookCodes.forEach((productCodeBookCode) => {
          if (Number(productCodeBookCode?.RootId || 0) >= result) {
            result = Number(productCodeBookCode.RootId) + 1;
          }
        });
      }
      return result;
    },
    getProductAccessSettingByName: (state) => (name) => {
      return state.productAccessSettings.find((productAccessSetting) => String(productAccessSetting?.Name || "") === String(name || "")) || null;
    },
    getNextProductAccessSettingRootId: (state) => (productRootId) => {
      let result = 1;
      productRootId = Number(productRootId || 0);
      if (productRootId > 0) {
        state.productAccessSettings.forEach((productAccessSetting) => {
          if (Number(productAccessSetting?.ProductRootId || 0) === productRootId) {
            if (Number(productAccessSetting?.RootId || 0) >= result) {
              result = Number(productAccessSetting.RootId) + 1;
            }
          }
        });
      }
      return result;
    },
    getProductConfigSettingByName: (state) => (name) => {
      return state.productConfigSettings.find((productConfigSetting) => String(productConfigSetting?.Name || "") === String(name || "")) || null;
    },
    getNextProductConfigSettingRootId: (state) => (productRootId) => {
      let result = 1;
      productRootId = Number(productRootId || 0);
      if (productRootId > 0) {
        state.productConfigSettings.forEach((productConfigSetting) => {
          if (Number(productConfigSetting?.ProductRootId || 0) === productRootId) {
            if (Number(productConfigSetting?.RootId || 0) >= result) {
              result = Number(productConfigSetting.RootId) + 1;
            }
          }
        });
      }
      return result;
    },
    getProductEditorGeneralRoute: (state) => {
      return `/admin/products/edit/${state.currentSelectedProduct?.RootId || 0 || 0}/general`;
    },
    isProductNameTaken: (state) => (rootId) => {
      return (state.productNameSearchData?.find((productNameSearch) => Number(productNameSearch?.RootId || 0) !== Number(rootId || 0)) || null) != null;
    },
  },
  mutations: {
    setProductData(state, productData) {
      state.products = productData?.sort((prod1, prod2) => (prod1.DisplayName > prod2.DisplayName ? 1 : -1)) || [];
    },
    clearProductEditorData(state) {
      state.productDocumentTemplateTypes = [];
      state.productOrderDocumentTypes = [];
      state.productCodeBooks = [];
      state.productDocumentTemplateTypeWithData = {};
      state.productDocumentTemplateTypeFields = {};
      state.productAccessSettings = [];
      state.productConfigSettings = [];
    },
    setSelectedProductCodeBook(state, productCodeBook) {
      state.selectedProductCodeBook = productCodeBook || {};
    },
    _addProduct(state, product) {
      const productsData = _.cloneDeep(state.products) || [];
      productsData.push(product);
      state.products = productsData.sort((prod1, prod2) => (prod1.DisplayName > prod2.DisplayName ? 1 : -1)) || [];
    },
    _updateProduct(state, updateProduct) {
      const rootId = Number(updateProduct?.RootId || 0);
      if (rootId > 0) {
        let productsData = _.cloneDeep(state.products) || [];
        productsData =
          [...productsData.filter((product) => Number(product?.RootId || 0) !== rootId), _.cloneDeep(updateProduct)].sort((prod1, prod2) =>
            prod1.DisplayName > prod2.DisplayName ? 1 : -1
          ) || [];
        state.products = productsData;
      }
    },
    _removeProduct(state, deleteProduct) {
      const rootId = Number(deleteProduct?.RootId || 0);
      if (rootId > 0) {
        let productsData = _.cloneDeep(state.products) || [];
        productsData =
          [...productsData.filter((product) => Number(product?.RootId || 0) !== rootId)].sort((prod1, prod2) => (prod1.DisplayName > prod2.DisplayName ? 1 : -1)) || [];
        state.products = productsData;
      }
    },
    updateCurrentProductGeneralValues(state, values) {
      state.currentSelectedProduct.Name = values.Name || "";
      state.currentSelectedProduct.DisplayName = values.DisplayName || "";
      state.currentSelectedProduct.Description = values.Description || "";
      state.currentSelectedProduct.CompanyName = values.CompanyName || "";
      state.currentSelectedProduct.WebsiteUrl = values.WebsiteUrl || "";
    },
    setProductDocumentTemplateData(state, productDocumentTemplateData) {
      state.productDocumentTemplateTypeWithData = productDocumentTemplateData || {};
      state.productDocumentTemplateTypeFields = {};
      if (state.productDocumentTemplateTypeWithData?.ProductDocumentTemplateTypeFields?.length > 0) {
        let dataFields = {};
        state.productDocumentTemplateTypeWithData.ProductDocumentTemplateTypeFields.forEach((productDocumentTemplateTypeField) => {
          if (productDocumentTemplateTypeField?.Tag?.length > 0) {
            if (productDocumentTemplateTypeField.DataValue?.length > 0) {
              switch (String(productDocumentTemplateTypeField.DataType).toLowerCase()) {
                case "object":
                  break;
                case "date":
                  {
                    const dateAndTime = new Date(productDocumentTemplateTypeField.DataValue || "");
                    const month = dateAndTime.getMonth();
                    const day = dateAndTime.getDate();
                    const year = dateAndTime.getFullYear();
                    if (month > -1 && month < 12 && day > 0 && day < 32 && year > 1900 && year < 2300) {
                      const dayText = day < 10 ? `0${day}` : `${day}`;
                      const monthText = month < 9 ? `0${month + 1}` : `${month + 1}`;
                      dataFields[productDocumentTemplateTypeField.Tag] = `${year}-${monthText}-${dayText}`;
                    } else {
                      dataFields[productDocumentTemplateTypeField.Tag] = "";
                    }
                  }
                  break;
                case "datetime":
                  if (String(productDocumentTemplateTypeField.DataValue).includes("T") && !String(productDocumentTemplateTypeField.DataValue).includes("Z")) {
                    dataFields[productDocumentTemplateTypeField.Tag] = new Date(`${productDocumentTemplateTypeField.DataValue}.000Z`);
                  } else {
                    dataFields[productDocumentTemplateTypeField.Tag] = new Date(productDocumentTemplateTypeField.DataValue);
                  }
                  break;
                case "decimal":
                case "numeric":
                  {
                    const parts = Number(parseFloat(productDocumentTemplateTypeField.DataValue)).toFixed(2).toString().split(".");
                    dataFields[productDocumentTemplateTypeField.Tag] = Number(parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : ""));
                  }
                  break;
                case "currency":
                  {
                    const parts = Number(parseFloat(productDocumentTemplateTypeField.DataValue)).toFixed(2).toString().split(".");
                    dataFields[productDocumentTemplateTypeField.Tag] = `$${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "")}`;
                  }
                  break;
                case "short":
                case "int":
                case "long":
                  dataFields[productDocumentTemplateTypeField.Tag] = Number(productDocumentTemplateTypeField.DataValue);
                  break;
                case "bool":
                case "boolean":
                  dataFields[productDocumentTemplateTypeField.Tag] = Boolean(productDocumentTemplateTypeField.DataValue);
                  break;
                default:
                  dataFields[productDocumentTemplateTypeField.Tag] = productDocumentTemplateTypeField.DataValue;
                  break;
              }
            } else {
              dataFields[productDocumentTemplateTypeField.Tag] = "";
            }
          }
        });
        state.productDocumentTemplateTypeFields = dataFields;
      }
    },
    setProductDocumentTemplateTypes(state, productDocumentTemplateTypesData) {
      state.productDocumentTemplateTypes = productDocumentTemplateTypesData?.sort((pdtt1, pdtt2) => (pdtt1.Name > pdtt2.Name ? 1 : -1)) || [];
    },
    _addCurrentProductDocumentTemplateType(state, productDocumentTemplateType) {
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductDocumentTemplateTypes != null && productDocumentTemplateType != null) {
        const currentSelectedProductProductDocumentTemplateTypesData = _.cloneDeep(state.currentSelectedProduct.ProductDocumentTemplateTypes) || [];
        currentSelectedProductProductDocumentTemplateTypesData.push(productDocumentTemplateType);
        state.currentSelectedProduct.ProductDocumentTemplateTypes =
          currentSelectedProductProductDocumentTemplateTypesData.sort((pdtt1, pdtt2) => (pdtt1.Name > pdtt2.Name ? 1 : -1)) || [];
      }
    },
    _updateCurrentProductDocumentTemplateType(state, updateProductDocumentTemplateType) {
      const rootId = Number(updateProductDocumentTemplateType?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductDocumentTemplateTypes != null && rootId > 0) {
        let currentSelectedProductProductDocumentTemplateTypesData = _.cloneDeep(state.currentSelectedProduct.ProductDocumentTemplateTypes) || [];
        currentSelectedProductProductDocumentTemplateTypesData =
          [
            ...currentSelectedProductProductDocumentTemplateTypesData.filter((productDocumentTemplateType) => Number(productDocumentTemplateType?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductDocumentTemplateType),
          ].sort((pdtt1, pdtt2) => (pdtt1.Name > pdtt2.Name ? 1 : -1)) || [];
        state.currentSelectedProduct.ProductDocumentTemplateTypes = currentSelectedProductProductDocumentTemplateTypesData;
      }
    },
    _removeCurrentProductDocumentTemplateType(state, deleteProductDocumentTemplateType) {
      const rootId = Number(deleteProductDocumentTemplateType?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductDocumentTemplateTypes != null && rootId > 0) {
        let currentSelectedProductProductDocumentTemplateTypesData = _.cloneDeep(state.currentSelectedProduct.ProductDocumentTemplateTypes) || [];
        currentSelectedProductProductDocumentTemplateTypesData =
          [...currentSelectedProductProductDocumentTemplateTypesData.filter((productDocumentTemplateType) => Number(productDocumentTemplateType?.RootId || 0) !== rootId)].sort(
            (pdtt1, pdtt2) => (pdtt1.Name > pdtt2.Name ? 1 : -1)
          ) || [];
        state.currentSelectedProduct.ProductDocumentTemplateTypes = currentSelectedProductProductDocumentTemplateTypesData;
      }
    },
    _addProductDocumentTemplateType(state, productDocumentTemplateType) {
      const productDocumentTemplateTypesData = _.cloneDeep(state.productDocumentTemplateTypes) || [];
      productDocumentTemplateTypesData.push(productDocumentTemplateType);
      state.productDocumentTemplateTypes = productDocumentTemplateTypesData.sort((pdtt1, pdtt2) => (pdtt1.Name > pdtt2.Name ? 1 : -1)) || [];
    },
    _updateProductDocumentTemplateType(state, updateProductDocumentTemplateType) {
      const rootId = Number(updateProductDocumentTemplateType?.RootId || 0);
      if (rootId > 0) {
        let productDocumentTemplateTypesData = _.cloneDeep(state.productDocumentTemplateTypes) || [];
        productDocumentTemplateTypesData =
          [
            ...productDocumentTemplateTypesData.filter((productDocumentTemplateType) => Number(productDocumentTemplateType?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductDocumentTemplateType),
          ].sort((pdtt1, pdtt2) => (pdtt1.Name > pdtt2.Name ? 1 : -1)) || [];
        state.productDocumentTemplateTypes = productDocumentTemplateTypesData;
      }
    },
    _removeProductDocumentTemplateType(state, deleteProductDocumentTemplateType) {
      const rootId = Number(deleteProductDocumentTemplateType?.RootId || 0);
      if (rootId > 0) {
        let productDocumentTemplateTypesData = _.cloneDeep(state.productDocumentTemplateTypes) || [];
        productDocumentTemplateTypesData =
          [...productDocumentTemplateTypesData.filter((productDocumentTemplateType) => Number(productDocumentTemplateType?.RootId || 0) !== rootId)].sort((pdtt1, pdtt2) =>
            pdtt1.Name > pdtt2.Name ? 1 : -1
          ) || [];
        state.productDocumentTemplateTypes = productDocumentTemplateTypesData;
      }
    },
    setProductOrderDocumentTypes(state, productOrderDocumentTypesData) {
      state.productOrderDocumentTypes = productOrderDocumentTypesData?.sort((podt1, podt2) => (podt1.Name > podt2.Name ? 1 : -1)) || [];
    },
    _addProductOrderDocumentType(state, productOrderDocumentType) {
      const productOrderDocumentTypesData = _.cloneDeep(state.productOrderDocumentTypes) || [];
      productOrderDocumentTypesData.push(productOrderDocumentType);
      state.productOrderDocumentTypes = productOrderDocumentTypesData.sort((podt1, podt2) => (podt1.Name > podt2.Name ? 1 : -1)) || [];
    },
    _updateProductOrderDocumentType(state, updateProductOrderDocumentType) {
      const rootId = Number(updateProductOrderDocumentType?.RootId || 0);
      if (rootId > 0) {
        let productOrderDocumentTypesData = _.cloneDeep(state.productOrderDocumentTypes) || [];
        productOrderDocumentTypesData =
          [
            ...productOrderDocumentTypesData.filter((productOrderDocumentType) => Number(productOrderDocumentType?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductOrderDocumentType),
          ].sort((podt1, podt2) => (podt1.Name > podt2.Name ? 1 : -1)) || [];
        state.productOrderDocumentTypes = productOrderDocumentTypesData;
      }
    },
    _removeProductOrderDocumentType(state, deleteProductOrderDocumentType) {
      const rootId = Number(deleteProductOrderDocumentType?.RootId || 0);
      if (rootId > 0) {
        let productOrderDocumentTypesData = _.cloneDeep(state.productOrderDocumentTypes) || [];
        productOrderDocumentTypesData =
          [...productOrderDocumentTypesData.filter((productOrderDocumentType) => Number(productOrderDocumentType?.RootId || 0) !== rootId)].sort((podt1, podt2) =>
            podt1.Name > podt2.Name ? 1 : -1
          ) || [];
        state.productOrderDocumentTypes = productOrderDocumentTypesData;
      }
    },
    _addCurrentSelectedProductDocumentCategory(state, currentSelectedProductDocumentCategory) {
      const currentSelectedProductProductDocumentCategoriesData = _.cloneDeep(state.currentSelectedProduct?.ProductDocumentCategories) || [];
      currentSelectedProductProductDocumentCategoriesData.push(currentSelectedProductDocumentCategory);
      state.currentSelectedProduct.ProductDocumentCategories =
        currentSelectedProductProductDocumentCategoriesData.sort((sapdc1, sapdc2) => (sapdc1.Position > sapdc2.Position ? 1 : -1)) || [];
    },
    _updateCurrentSelectedProductDocumentCategory(state, updateProductDocumentCategory) {
      const rootId = Number(updateProductDocumentCategory?.RootId || 0);
      if (state.currentSelectedProduct?.ProductDocumentCategories != null && rootId > 0) {
        let currentSelectedProductProductDocumentCategoriesData = _.cloneDeep(state.currentSelectedProduct.ProductDocumentCategories) || [];
        currentSelectedProductProductDocumentCategoriesData =
          [
            ...currentSelectedProductProductDocumentCategoriesData.filter((productDocumentCategory) => Number(productDocumentCategory?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductDocumentCategory),
          ].sort((sapdc1, sapdc2) => (sapdc1.Position > sapdc2.Position ? 1 : -1)) || [];
        state.currentSelectedProduct.ProductDocumentCategories = currentSelectedProductProductDocumentCategoriesData;
      }
    },
    _removeCurrentSelectedProductDocumentCategory(state, deleteProductDocumentCategory) {
      const rootId = Number(deleteProductDocumentCategory?.RootId || 0);
      if (state.currentSelectedProduct?.ProductDocumentCategories != null && rootId > 0) {
        let currentSelectedProductProductDocumentCategoriesData = _.cloneDeep(state.currentSelectedProduct.ProductDocumentCategories) || [];
        currentSelectedProductProductDocumentCategoriesData =
          [...currentSelectedProductProductDocumentCategoriesData.filter((productDocumentCategory) => Number(productDocumentCategory?.RootId || 0) !== rootId)].sort(
            (sapdc1, sapdc2) => (sapdc1.Position > sapdc2.Position ? 1 : -1)
          ) || [];
        state.currentSelectedProduct.ProductDocumentCategories = currentSelectedProductProductDocumentCategoriesData;
      }
    },
    _addCurrentSelectedProductDocumentType(state, currentSelectedProductDocumentType) {
      const currentSelectedProductProductDocumentTypesData = _.cloneDeep(state.currentSelectedProduct?.ProductDocumentTypes) || [];
      currentSelectedProductProductDocumentTypesData.push(currentSelectedProductDocumentType);
      state.currentSelectedProduct.ProductDocumentTypes =
        currentSelectedProductProductDocumentTypesData.sort((sapdt1, sapdt2) => (sapdt1.Position > sapdt2.Position ? 1 : -1)) || [];
    },
    _updateCurrentSelectedProductDocumentType(state, updateProductDocumentType) {
      const rootId = Number(updateProductDocumentType?.RootId || 0);
      if (state.currentSelectedProduct?.ProductDocumentTypes != null && rootId > 0) {
        let currentSelectedProductProductDocumentTypesData = _.cloneDeep(state.currentSelectedProduct.ProductDocumentTypes) || [];
        currentSelectedProductProductDocumentTypesData =
          [
            ...currentSelectedProductProductDocumentTypesData.filter((productDocumentType) => Number(productDocumentType?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductDocumentType),
          ].sort((sapdt1, sapdt2) => (sapdt1.Position > sapdt2.Position ? 1 : -1)) || [];
        state.currentSelectedProduct.ProductDocumentTypes = currentSelectedProductProductDocumentTypesData;
      }
    },
    _removeCurrentSelectedProductDocumentType(state, deleteProductDocumentType) {
      const rootId = Number(deleteProductDocumentType?.RootId || 0);
      if (state.currentSelectedProduct?.ProductDocumentTypes != null && rootId > 0) {
        let currentSelectedProductProductDocumentTypesData = _.cloneDeep(state.currentSelectedProduct.ProductDocumentTypes) || [];
        currentSelectedProductProductDocumentTypesData =
          [...currentSelectedProductProductDocumentTypesData.filter((productDocumentType) => Number(productDocumentType?.RootId || 0) !== rootId)].sort((sapdt1, sapdt2) =>
            sapdt1.Position > sapdt2.Position ? 1 : -1
          ) || [];
        state.currentSelectedProduct.ProductDocumentTypes = currentSelectedProductProductDocumentTypesData;
      }
    },
    setProductCodeBooks(state, productCodeBooksData) {
      state.productCodeBooks = productCodeBooksData?.sort((pcb1, pcb2) => (pcb1.Name > pcb2.Name ? 1 : -1)) || [];
    },
    _addCurrentProductCodeBook(state, productCodeBook) {
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductCodeBooks != null && productCodeBook != null) {
        const currentSelectedProductProductCodeBooksData = _.cloneDeep(state.currentSelectedProduct.ProductCodeBooks) || [];
        currentSelectedProductProductCodeBooksData.push(productCodeBook);
        state.currentSelectedProduct.ProductCodeBooks = currentSelectedProductProductCodeBooksData.sort((pcb1, pcb2) => (pcb1.Name > pcb2.Name ? 1 : -1)) || [];
      }
    },
    _updateCurrentProductCodeBook(state, updateProductCodeBook) {
      const rootId = Number(updateProductCodeBook?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductCodeBooks != null && rootId > 0) {
        let currentSelectedProductProductCodeBooksData = _.cloneDeep(state.currentSelectedProduct.ProductCodeBooks) || [];
        currentSelectedProductProductCodeBooksData =
          [...currentSelectedProductProductCodeBooksData.filter((productCodeBook) => Number(productCodeBook?.RootId || 0) !== rootId), _.cloneDeep(updateProductCodeBook)].sort(
            (pcb1, pcb2) => (pcb1.Name > pcb2.Name ? 1 : -1)
          ) || [];
        state.currentSelectedProduct.ProductCodeBooks = currentSelectedProductProductCodeBooksData;
      }
    },
    _removeCurrentProductCodeBook(state, deleteProductCodeBook) {
      const rootId = Number(deleteProductCodeBook?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductCodeBooks != null && rootId > 0) {
        let currentSelectedProductProductCodeBooksData = _.cloneDeep(state.currentSelectedProduct.ProductCodeBooks) || [];
        currentSelectedProductProductCodeBooksData =
          [...currentSelectedProductProductCodeBooksData.filter((productCodeBook) => Number(productCodeBook?.RootId || 0) !== rootId)].sort((pcb1, pcb2) =>
            pcb1.Name > pcb2.Name ? 1 : -1
          ) || [];
        state.currentSelectedProduct.ProductCodeBooks = currentSelectedProductProductCodeBooksData;
      }
    },
    _addProductCodeBook(state, productCodeBook) {
      if (state.productCodeBooks != null && productCodeBook != null) {
        const productCodeBooksData = _.cloneDeep(state.productCodeBooks) || [];
        productCodeBooksData.push(productCodeBook);
        state.productCodeBooks = productCodeBooksData.sort((pcb1, pcb2) => (pcb1.Name > pcb2.Name ? 1 : -1)) || [];
      }
    },
    _updateProductCodeBook(state, updateProductCodeBook) {
      const rootId = Number(updateProductCodeBook?.RootId || 0);
      if (rootId > 0) {
        let productCodeBooksData = _.cloneDeep(state.productCodeBooks) || [];
        productCodeBooksData =
          [...productCodeBooksData.filter((productCodeBook) => Number(productCodeBook?.RootId || 0) !== rootId), _.cloneDeep(updateProductCodeBook)].sort((pcb1, pcb2) =>
            pcb1.Name > pcb2.Name ? 1 : -1
          ) || [];
        state.productCodeBooks = productCodeBooksData;
      }
    },
    _removeProductCodeBook(state, deleteProductCodeBook) {
      const rootId = Number(deleteProductCodeBook?.RootId || 0);
      if (rootId > 0) {
        let productCodeBooksData = _.cloneDeep(state.productCodeBooks) || [];
        productCodeBooksData =
          [...productCodeBooksData.filter((productCodeBook) => Number(productCodeBook?.RootId || 0) !== rootId)].sort((pcb1, pcb2) => (pcb1.Name > pcb2.Name ? 1 : -1)) || [];
        state.productCodeBooks = productCodeBooksData;
      }
    },
    _addCurrentProductCodeBookCode(state, productCodeBookCode) {
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductCodeBookCodes != null && productCodeBookCode != null) {
        const currentSelectedProductProductCodeBookCodesData = _.cloneDeep(state.currentSelectedProduct.ProductCodeBookCodes) || [];
        currentSelectedProductProductCodeBookCodesData.push(productCodeBookCode);
        state.currentSelectedProduct.ProductCodeBookCodes = currentSelectedProductProductCodeBookCodesData.sort((pcbc1, pcbc2) => (pcbc1.Code > pcbc2.Code ? 1 : -1)) || [];
      }
    },
    _updateCurrentProductCodeBookCode(state, updateProductCodeBookCode) {
      const rootId = Number(updateProductCodeBookCode?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductCodeBookCodes != null && rootId > 0) {
        let currentSelectedProductProductCodeBookCodesData = _.cloneDeep(state.currentSelectedProduct.ProductCodeBookCodes) || [];
        currentSelectedProductProductCodeBookCodesData =
          [
            ...currentSelectedProductProductCodeBookCodesData.filter((productCodeBookCode) => Number(productCodeBookCode?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductCodeBookCode),
          ].sort((pcbc1, pcbc2) => (pcbc1.Code > pcbc2.Code ? 1 : -1)) || [];
        state.currentSelectedProduct.ProductCodeBookCodes = currentSelectedProductProductCodeBookCodesData;
      }
    },
    _removeCurrentProductCodeBookCode(state, deleteProductCodeBookCode) {
      const rootId = Number(deleteProductCodeBookCode?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductCodeBookCodes != null && rootId > 0) {
        let currentSelectedProductProductCodeBookCodesData = _.cloneDeep(state.currentSelectedProduct.ProductCodeBookCodes) || [];
        currentSelectedProductProductCodeBookCodesData =
          [...currentSelectedProductProductCodeBookCodesData.filter((productCodeBookCode) => Number(productCodeBookCode?.RootId || 0) !== rootId)].sort((pcbc1, pcbc2) =>
            pcbc1.Code > pcbc2.Code ? 1 : -1
          ) || [];
        state.currentSelectedProduct.ProductCodeBookCodes = currentSelectedProductProductCodeBookCodesData;
      }
    },
    _addProductCodeBookCode(state, productCodeBookCode) {
      const productCodeBookCodesData = _.cloneDeep(state.productCodeBookCodes) || [];
      productCodeBookCodesData.push(productCodeBookCode);
      state.productCodeBookCodes = productCodeBookCodesData.sort((pcbc1, pcbc2) => (pcbc1.Code > pcbc2.Code ? 1 : -1)) || [];
      if (state.selectedProductCodeBook?.RootId > 0 && Number(state.selectedProductCodeBook.RootId) === Number(productCodeBookCode.ProductCodeBookRootId)) {
        state.selectedProductCodeBook.ProductCodeBookCodes.push(_.cloneDeep(productCodeBookCode));
        state.selectedProductCodeBook.ProductCodeBookCodes = state.selectedProductCodeBook.ProductCodeBookCodes.sort((pcbc1, pcbc2) => (pcbc1.Code > pcbc2.Code ? 1 : -1)) || [];
      }
    },
    _updateProductCodeBookCode(state, updateProductCodeBookCode) {
      const rootId = Number(updateProductCodeBookCode?.RootId || 0);
      if (rootId > 0) {
        let productCodeBookCodesData = _.cloneDeep(state.productCodeBookCodes) || [];
        productCodeBookCodesData =
          [...productCodeBookCodesData.filter((productCodeBookCode) => Number(productCodeBookCode?.RootId || 0) !== rootId), _.cloneDeep(updateProductCodeBookCode)].sort(
            (pcbc1, pcbc2) => (pcbc1.Code > pcbc2.Code ? 1 : -1)
          ) || [];
        state.productCodeBookCodes = productCodeBookCodesData;
        if (state.selectedProductCodeBook?.RootId > 0 && Number(state.selectedProductCodeBook.RootId) === Number(updateProductCodeBookCode.ProductCodeBookRootId)) {
          let selectedProductCodeBookCodesData = _.cloneDeep(state.selectedProductCodeBook.ProductCodeBookCodes) || [];
          selectedProductCodeBookCodesData =
            [...selectedProductCodeBookCodesData.filter((productCodeBookCode) => Number(productCodeBookCode?.RootId || 0) !== rootId), _.cloneDeep(updateProductCodeBookCode)].sort(
              (pcbc1, pcbc2) => (pcbc1.Code > pcbc2.Code ? 1 : -1)
            ) || [];
          state.selectedProductCodeBook.ProductCodeBookCodes = selectedProductCodeBookCodesData;
        }
      }
    },
    _removeProductCodeBookCode(state, deleteProductCodeBookCode) {
      const rootId = Number(deleteProductCodeBookCode?.RootId || 0);
      if (rootId > 0) {
        let productCodeBookCodesData = _.cloneDeep(state.productCodeBookCodes) || [];
        productCodeBookCodesData =
          [...productCodeBookCodesData.filter((productCodeBookCode) => Number(productCodeBookCode?.RootId || 0) !== rootId)].sort((pcbc1, pcbc2) =>
            pcbc1.Code > pcbc2.Code ? 1 : -1
          ) || [];
        state.productCodeBookCodes = productCodeBookCodesData;
        if (state.selectedProductCodeBook?.RootId > 0 && Number(state.selectedProductCodeBook.RootId) === Number(deleteProductCodeBookCode.ProductCodeBookRootId)) {
          let selectedProductCodeBookCodesData = _.cloneDeep(state.selectedProductCodeBook.ProductCodeBookCodes) || [];
          selectedProductCodeBookCodesData =
            [...selectedProductCodeBookCodesData.filter((productCodeBookCode) => Number(productCodeBookCode?.RootId || 0) !== rootId)].sort((pcbc1, pcbc2) =>
              pcbc1.Code > pcbc2.Code ? 1 : -1
            ) || [];
          state.selectedProductCodeBook.ProductCodeBookCodes = selectedProductCodeBookCodesData;
        }
      }
    },
    setProductAccessSettings(state, productAccessSettingsData) {
      if (productAccessSettingsData?.length > 0) {
        productAccessSettingsData.forEach((productAccessSetting) => {
          productAccessSetting.DefaultValueString = productAccessSetting.DefaultValue ? String.fromCharCode(0x2713) : "";
          productAccessSetting.CustomString = productAccessSetting.Custom ? String.fromCharCode(0x2713) : "";
        });
      }
      state.productAccessSettings = productAccessSettingsData?.sort((pas1, pas2) => (pas1.Name > pas2.Name ? 1 : -1)) || [];
    },
    _addCurrentProductAccessSetting(state, productAccessSetting) {
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductAccessSettings != null && productAccessSetting != null) {
        const currentSelectedProductProductAccessSettingsData = _.cloneDeep(state.currentSelectedProduct.ProductAccessSettings) || [];
        currentSelectedProductProductAccessSettingsData.push(productAccessSetting);
        state.currentSelectedProduct.ProductAccessSettings = currentSelectedProductProductAccessSettingsData.sort((pas1, pas2) => (pas1.Name > pas2.Name ? 1 : -1)) || [];
      }
    },
    _updateCurrentProductAccessSetting(state, updateProductAccessSetting) {
      const rootId = Number(updateProductAccessSetting?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductAccessSettings != null && rootId > 0) {
        let currentSelectedProductProductAccessSettingsData = _.cloneDeep(state.currentSelectedProduct.ProductAccessSettings) || [];
        currentSelectedProductProductAccessSettingsData =
          [
            ...currentSelectedProductProductAccessSettingsData.filter((productAccessSetting) => Number(productAccessSetting?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductAccessSetting),
          ].sort((pas1, pas2) => (pas1.Name > pas2.Name ? 1 : -1)) || [];
        state.currentSelectedProduct.ProductAccessSettings = currentSelectedProductProductAccessSettingsData;
      }
    },
    _removeCurrentProductAccessSetting(state, deleteProductAccessSetting) {
      const rootId = Number(deleteProductAccessSetting?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductAccessSettings != null && rootId > 0) {
        let currentSelectedProductProductAccessSettingsData = _.cloneDeep(state.currentSelectedProduct.ProductAccessSettings) || [];
        currentSelectedProductProductAccessSettingsData =
          [...currentSelectedProductProductAccessSettingsData.filter((productAccessSetting) => Number(productAccessSetting?.RootId || 0) !== rootId)].sort((pas1, pas2) =>
            pas1.Name > pas2.Name ? 1 : -1
          ) || [];
        state.currentSelectedProduct.ProductAccessSettings = currentSelectedProductProductAccessSettingsData;
      }
    },
    _addProductAccessSetting(state, productAccessSetting) {
      const productAccessSettingsData = _.cloneDeep(state.productAccessSettings) || [];
      let productAccessSettingCopy = _.cloneDeep(productAccessSetting);
      productAccessSettingCopy.DefaultValueString = productAccessSetting.DefaultValue ? String.fromCharCode(0x2713) : "";
      productAccessSettingCopy.CustomString = productAccessSetting.Custom ? String.fromCharCode(0x2713) : "";
      productAccessSettingsData.push(productAccessSettingCopy);
      state.productAccessSettings = productAccessSettingsData.sort((pas1, pas2) => (pas1.Name > pas2.Name ? 1 : -1)) || [];
    },
    _updateProductAccessSetting(state, updateProductAccessSetting) {
      const rootId = Number(updateProductAccessSetting?.RootId || 0);
      if (rootId > 0) {
        let productAccessSettingCopy = _.cloneDeep(updateProductAccessSetting);
        productAccessSettingCopy.DefaultValueString = productAccessSettingCopy.DefaultValue ? String.fromCharCode(0x2713) : "";
        productAccessSettingCopy.CustomString = productAccessSettingCopy.Custom ? String.fromCharCode(0x2713) : "";
        let productAccessSettingsData = _.cloneDeep(state.productAccessSettings) || [];
        productAccessSettingsData =
          [...productAccessSettingsData.filter((productAccessSetting) => Number(productAccessSetting?.RootId || 0) !== rootId), _.cloneDeep(productAccessSettingCopy)].sort(
            (pas1, pas2) => (pas1.Name > pas2.Name ? 1 : -1)
          ) || [];
        state.productAccessSettings = productAccessSettingsData;
      }
    },
    _removeProductAccessSetting(state, deleteProductAccessSetting) {
      const rootId = Number(deleteProductAccessSetting?.RootId || 0);
      if (rootId > 0) {
        let productAccessSettingsData = _.cloneDeep(state.productAccessSettings) || [];
        productAccessSettingsData =
          [...productAccessSettingsData.filter((productAccessSetting) => Number(productAccessSetting?.RootId || 0) !== rootId)].sort((pas1, pas2) =>
            pas1.Name > pas2.Name ? 1 : -1
          ) || [];
        state.productAccessSettings = productAccessSettingsData;
      }
    },
    setProductConfigSettings(state, productConfigSettingsData) {
      state.productConfigSettings.splice(0, state.productConfigSettings.length);
      productConfigSettingsData?.forEach((productConfigSetting) => {
        productConfigSetting.UserCanEditString = productConfigSetting.UserCanEdit ? String.fromCharCode(0x2713) : "";
        productConfigSetting.VisibleString = productConfigSetting.Visible ? String.fromCharCode(0x2713) : "";
        productConfigSetting.CustomString = productConfigSetting.Custom ? String.fromCharCode(0x2713) : "";
      });
      state.productConfigSettings = productConfigSettingsData?.sort((pcs1, pcs2) => (pcs1.Name > pcs2.Name ? 1 : -1)) || [];
    },
    _addCurrentProductConfigSetting(state, productConfigSetting) {
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct.ProductConfigSettings != null) {
        const currentSelectedProductProductConfigSettingsData = _.cloneDeep(state.currentSelectedProduct.ProductConfigSettings) || [];
        currentSelectedProductProductConfigSettingsData.push(productConfigSetting);
        state.currentSelectedProduct.ProductConfigSettings = currentSelectedProductProductConfigSettingsData.sort((pcs1, pcs2) => (pcs1.Name > pcs2.Name ? 1 : -1)) || [];
      }
    },
    _updateCurrentProductConfigSetting(state, updateProductConfigSetting) {
      const rootId = Number(updateProductConfigSetting?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductConfigSettings != null && rootId > 0) {
        let currentSelectedProductProductConfigSettingsData = _.cloneDeep(state.currentSelectedProduct.ProductConfigSettings) || [];
        currentSelectedProductProductConfigSettingsData =
          [
            ...currentSelectedProductProductConfigSettingsData.filter((productConfigSetting) => Number(productConfigSetting?.RootId || 0) !== rootId),
            _.cloneDeep(updateProductConfigSetting),
          ].sort((pcs1, pcs2) => (pcs1.Name > pcs2.Name ? 1 : -1)) || [];
        state.currentSelectedProduct.ProductConfigSettings = currentSelectedProductProductConfigSettingsData;
      }
    },
    _removeCurrentProductConfigSetting(state, deleteProductConfigSetting) {
      const rootId = Number(deleteProductConfigSetting?.RootId || 0);
      if (state.currentSelectedProduct?.RootId > 0 && state.currentSelectedProduct?.ProductConfigSettings != null && rootId > 0) {
        let currentSelectedProductProductConfigSettingsData = _.cloneDeep(state.currentSelectedProduct.ProductConfigSettings) || [];
        currentSelectedProductProductConfigSettingsData =
          [...currentSelectedProductProductConfigSettingsData.filter((productConfigSetting) => Number(productConfigSetting?.RootId || 0) !== rootId)].sort((pcs1, pcs2) =>
            pcs1.Name > pcs2.Name ? 1 : -1
          ) || [];
        state.currentSelectedProduct.ProductConfigSettings = currentSelectedProductProductConfigSettingsData;
      }
    },
    _addProductConfigSetting(state, productConfigSetting) {
      const productConfigSettingsData = _.cloneDeep(state.productConfigSettings) || [];
      let productConfigSettingCopy = _.cloneDeep(productConfigSetting);
      productConfigSettingCopy.UserCanEditString = productConfigSetting.UserCanEdit ? String.fromCharCode(0x2713) : "";
      productConfigSettingCopy.VisibleString = productConfigSetting.Visible ? String.fromCharCode(0x2713) : "";
      productConfigSettingCopy.CustomString = productConfigSetting.Custom ? String.fromCharCode(0x2713) : "";
      productConfigSettingsData.push(productConfigSettingCopy);
      state.productConfigSettings = productConfigSettingsData.sort((pcs1, pcs2) => (pcs1.Name > pcs2.Name ? 1 : -1)) || [];
    },
    _updateProductConfigSetting(state, updateProductConfigSetting) {
      const rootId = Number(updateProductConfigSetting?.RootId || 0);
      if (rootId > 0) {
        let productConfigSettingCopy = _.cloneDeep(updateProductConfigSetting);
        productConfigSettingCopy.UserCanEditString = productConfigSettingCopy.UserCanEdit ? String.fromCharCode(0x2713) : "";
        productConfigSettingCopy.VisibleString = productConfigSettingCopy.Visible ? String.fromCharCode(0x2713) : "";
        productConfigSettingCopy.CustomString = productConfigSettingCopy.Custom ? String.fromCharCode(0x2713) : "";
        let productConfigSettingsData = _.cloneDeep(state.productConfigSettings) || [];
        productConfigSettingsData =
          [...productConfigSettingsData.filter((productConfigSetting) => Number(productConfigSetting?.RootId || 0) !== rootId), _.cloneDeep(productConfigSettingCopy)].sort(
            (pcs1, pcs2) => (pcs1.Name > pcs2.Name ? 1 : -1)
          ) || [];
        state.productConfigSettings = productConfigSettingsData;
      }
    },
    _removeProductConfigSetting(state, deleteProductConfigSetting) {
      const rootId = Number(deleteProductConfigSetting?.RootId || 0);
      if (rootId > 0) {
        let productConfigSettingsData = _.cloneDeep(state.productConfigSettings) || [];
        productConfigSettingsData =
          [...productConfigSettingsData.filter((productConfigSetting) => Number(productConfigSetting?.RootId || 0) !== rootId)].sort((pcs1, pcs2) =>
            pcs1.Name > pcs2.Name ? 1 : -1
          ) || [];
        state.productConfigSettings = productConfigSettingsData;
      }
    },
    setProductNameSearchData(state, nameSearchData) {
      state.productNameSearchData = nameSearchData || [];
    },
    setReloadSavedViewData(state, reloadSavedViewData) {
      state.reloadSavedViewData = reloadSavedViewData || false;
    },
    setShowRequiredValuesError(state, showRequiredValuesError) {
      state.showRequiredValuesError = showRequiredValuesError || false;
    },
    setCurrentSelectedProduct(state, currentSelectedProduct) {
      state.currentSelectedProduct = currentSelectedProduct || {};
    },
    setIsEditingProduct(state, isEditingProduct) {
      state.isEditingProduct = isEditingProduct || false;
    },
  },
  actions: {
    async getProductsData(context, includeMetadata) {
      await timeOperation(async () => {
        const formattedUrl = `${productsUrl}?includemetadata=${includeMetadata || false}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductData", data);
      }, "products - getProductsData");
    },
    async getProductDocumentTemplateTypes(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTemplateTypesUrl}?productrootid=${Number(params?.ProductRootId || 0)}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductDocumentTemplateTypes", data);
      }, "products - getProductDocumentTemplateTypes");
    },
    async getProductDocumentTemplateTypeData(context, params) {
      await timeOperation(async () => {
        const rootId = Number(params?.RootId || 0);
        const orderNumber = String(params?.OrderNumber || "");
        const formattedUrl = `${productDocumentTemplateTypesUrl}/${rootId}?productrootid=0&ordernumber=${orderNumber}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductDocumentTemplateData", data);
      }, "products - getProductDocumentTemplateTypes");
    },
    async getProductOrderDocumentTypes(context, { ProductRootId, OrganizationRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        IncludeMetadata = IncludeMetadata || true;
        const formattedUrl = `${productOrderDocumentTypesUrl}?productrootid=${ProductRootId}&organizationrootid=${OrganizationRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductOrderDocumentTypes", data);
      }, "products - getProductOrderDocumentTypes");
    },
    async getProductCodeBooks(context, params) {
      await timeOperation(async () => {}, "products - getProductCodeBooks");
      const formattedUrl = `${productCodeBooksUrl}?productrootid=${Number(params?.ProductRootId || 0)}&includemetadata=true`;
      const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      context.commit("setProductCodeBooks", data);
    },
    async getProductAccessSettings(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${productAccessSettingsUrl}?productrootid=${Number(params?.ProductRootId || 0)}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductAccessSettings", data);
      }, "products - getProductAccessSettings");
    },
    async getProductConfigSettings(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${productConfigSettingsUrl}?productrootid=${Number(params?.ProductRootId || 0)}&includemetadata=true`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductConfigSettings", data);
      }, "products - getProductConfigSettings");
    },
    async productNameSearch(context, params) {
      await timeOperation(async () => {
        const formattedUrl = `${validateNamesUrl}?typename=product&name=${String(params?.Name || "")}&displayname=${String(params?.DisplayName || "")}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setProductNameSearchData", data);
      }, "products - productNameSearch");
    },
    async addProduct(context, product) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productsUrl, Payload: product });
        context.commit("_addProduct", data);
        return data;
      }, "products - addProduct");
    },
    async updateProduct(context, product) {
      await timeOperation(async () => {
        const formattedUrl = `${productsUrl}/${Number(product?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: product });
        context.commit("_updateProduct", data);
      }, "products - updateProduct");
    },
    async removeProduct(context, product) {
      await timeOperation(async () => {
        const formattedUrl = `${productsUrl}/${Number(product?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "products - removeProduct");
    },
    async cloneProduct(context, {
      RootId,
      IncludeProductDataSources,
      IncludeProductAccessSettings,
      IncludeProductConfigSettings,
      IncludeProductDocumentCategories,
      IncludeProductDocumentTypes,
      IncludeProductDocumentTemplateTypes,
      IncludeProductOrderDocumentTypes,
      IncludeProductCustomUserFields,
      IncludeProductCustomFields,
      IncludeProductTags,
      IncludeProductSearchGroups,
      IncludeSearches,
      IncludeProductFormGroups,
      IncludeFormLayouts,
      IncludeWorkflowTaskTemplates,
      IncludeWorkflowRuleTemplates,
      IncludeWorkflowNotificationTemplates
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId || 0);
        IncludeProductDataSources = IncludeProductDataSources || true;
        IncludeProductAccessSettings = IncludeProductAccessSettings || true;
        IncludeProductConfigSettings = IncludeProductConfigSettings || true;
        IncludeProductDocumentCategories = IncludeProductDocumentCategories || true;
        IncludeProductDocumentTypes = IncludeProductDocumentTypes || true;
        IncludeProductDocumentTemplateTypes = IncludeProductDocumentTemplateTypes || true;
        IncludeProductOrderDocumentTypes = IncludeProductOrderDocumentTypes || true;
        IncludeProductCustomUserFields = IncludeProductCustomUserFields || true;
        IncludeProductCustomFields = IncludeProductCustomFields || true;
        IncludeProductTags = IncludeProductTags || true;
        IncludeProductSearchGroups = IncludeProductSearchGroups || true;
        IncludeSearches = IncludeSearches || true;
        IncludeProductFormGroups = IncludeProductFormGroups || true;
        IncludeFormLayouts = IncludeFormLayouts || true;
        IncludeWorkflowTaskTemplates = IncludeWorkflowTaskTemplates || true;
        IncludeWorkflowRuleTemplates = IncludeWorkflowRuleTemplates || true;
        IncludeWorkflowNotificationTemplates = IncludeWorkflowNotificationTemplates || true;
        const formattedUrl = `${productsUrl
          }/${RootId
          }/clone?includeproductdatasources=${IncludeProductDataSources
          }&includeproductaccesssettings=${IncludeProductAccessSettings
          }&includeproductconfigsettings=${IncludeProductConfigSettings
          }&includeproductdocumentcategories=${IncludeProductDocumentCategories
          }&includeproductdocumenttypes=${IncludeProductDocumentTypes
          }&includeproductdocumenttemplatetypes=${IncludeProductDocumentTemplateTypes
          }&includeproductorderdocumenttypes=${IncludeProductOrderDocumentTypes
          }&includeproductcustomuserfields=${IncludeProductCustomUserFields
          }&includeproductcustomfields=${IncludeProductCustomFields
          }&includeproducttags=${IncludeProductTags
          }&includeproductsearchgroups=${IncludeProductSearchGroups
          }&includesearches=${IncludeSearches
          }&includeproductformgroups=${IncludeProductFormGroups
          }&includeformlayouts=${IncludeFormLayouts
          }&includeworkflowtasktemplates=${IncludeWorkflowTaskTemplates
          }&includeworkflowruletemplates=${IncludeWorkflowRuleTemplates
          }&includeworkflownotificationtemplates=${IncludeWorkflowNotificationTemplates}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_addProduct", data);
        return data;
      }, "products - cloneProduct");
    },
    async exportProduct(context, {
      RootId,
      IncludeProductDataSources,
      IncludeProductAccessSettings,
      IncludeProductConfigSettings,
      IncludeProductDocumentCategories,
      IncludeProductDocumentTypes,
      IncludeProductDocumentTemplateTypes,
      IncludeProductOrderDocumentTypes,
      IncludeProductCustomUserFields,
      IncludeProductCustomFields,
      IncludeProductTags,
      IncludeProductSearchGroups,
      IncludeSearches,
      IncludeProductFormGroups,
      IncludeFormLayouts,
      IncludeWorkflowTaskTemplates,
      IncludeWorkflowRuleTemplates,
      IncludeWorkflowNotificationTemplates
    }) {
      return timeOperation(async () => {
        RootId = Number(RootId || 0);
        IncludeProductDataSources = IncludeProductDataSources || true;
        IncludeProductAccessSettings = IncludeProductAccessSettings || true;
        IncludeProductConfigSettings = IncludeProductConfigSettings || true;
        IncludeProductDocumentCategories = IncludeProductDocumentCategories || true;
        IncludeProductDocumentTypes = IncludeProductDocumentTypes || true;
        IncludeProductDocumentTemplateTypes = IncludeProductDocumentTemplateTypes || true;
        IncludeProductOrderDocumentTypes = IncludeProductOrderDocumentTypes || true;
        IncludeProductCustomUserFields = IncludeProductCustomUserFields || true;
        IncludeProductCustomFields = IncludeProductCustomFields || true;
        IncludeProductTags = IncludeProductTags || true;
        IncludeProductSearchGroups = IncludeProductSearchGroups || true;
        IncludeSearches = IncludeSearches || true;
        IncludeProductFormGroups = IncludeProductFormGroups || true;
        IncludeFormLayouts = IncludeFormLayouts || true;
        IncludeWorkflowTaskTemplates = IncludeWorkflowTaskTemplates || true;
        IncludeWorkflowRuleTemplates = IncludeWorkflowRuleTemplates || true;
        IncludeWorkflowNotificationTemplates = IncludeWorkflowNotificationTemplates || true;
        const formattedUrl = `${productsUrl
          }/${RootId
          }/export?includeproductdatasources=${IncludeProductDataSources
          }&includeproductdatasources=${IncludeProductDataSources
          }&includeproductaccesssettings=${IncludeProductAccessSettings
          }&includeproductconfigsettings=${IncludeProductConfigSettings
          }&includeproductdocumentcategories=${IncludeProductDocumentCategories
          }&includeproductdocumenttypes=${IncludeProductDocumentTypes
          }&includeproductdocumenttemplatetypes=${IncludeProductDocumentTemplateTypes
          }&includeproductorderdocumenttypes=${IncludeProductOrderDocumentTypes
          }&includeproductcustomuserfields=${IncludeProductCustomUserFields
          }&includeproductcustomfields=${IncludeProductCustomFields
          }&includeproducttags=${IncludeProductTags
          }&includeproductsearchgroups=${IncludeProductSearchGroups
          }&includesearches=${IncludeSearches
          }&includeproductformgroups=${IncludeProductFormGroups
          }&includeformlayouts=${IncludeFormLayouts
          }&includeworkflowtasktemplates=${IncludeWorkflowTaskTemplates
          }&includeworkflowruletemplates=${IncludeWorkflowRuleTemplates
          }&includeworkflownotificationtemplates=${IncludeWorkflowNotificationTemplates}`;
        return await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
      }, "products - exportProduct");
    },
    async importProduct(context, {
      IncludeProductDataSources,
      IncludeProductAccessSettings,
      IncludeProductConfigSettings,
      IncludeProductDocumentCategories,
      IncludeProductDocumentTypes,
      IncludeProductDocumentTemplateTypes,
      IncludeProductOrderDocumentTypes,
      IncludeProductCustomUserFields,
      IncludeProductCustomFields,
      IncludeProductTags,
      IncludeProductSearchGroups,
      IncludeSearches,
      IncludeProductFormGroups,
      IncludeFormLayouts,
      IncludeWorkflowTaskTemplates,
      IncludeWorkflowRuleTemplates,
      IncludeWorkflowNotificationTemplates,
      UpdateExisting,
      FileContents
    }) {
      if (!(FileContents?.length > 0)) {
        console.log("invalid import data found");
        return null;
      }
      return timeOperation(async () => {
        IncludeProductDataSources = IncludeProductDataSources || true;
        IncludeProductAccessSettings = IncludeProductAccessSettings || true;
        IncludeProductConfigSettings = IncludeProductConfigSettings || true;
        IncludeProductDocumentCategories = IncludeProductDocumentCategories || true;
        IncludeProductDocumentTypes = IncludeProductDocumentTypes || true;
        IncludeProductDocumentTemplateTypes = IncludeProductDocumentTemplateTypes || true;
        IncludeProductOrderDocumentTypes = IncludeProductOrderDocumentTypes || true;
        IncludeProductCustomUserFields = IncludeProductCustomUserFields || true;
        IncludeProductCustomFields = IncludeProductCustomFields || true;
        IncludeProductTags = IncludeProductTags || true;
        IncludeProductSearchGroups = IncludeProductSearchGroups || true;
        IncludeSearches = IncludeSearches || true;
        IncludeProductFormGroups = IncludeProductFormGroups || true;
        IncludeFormLayouts = IncludeFormLayouts || true;
        IncludeWorkflowTaskTemplates = IncludeWorkflowTaskTemplates || true;
        IncludeWorkflowRuleTemplates = IncludeWorkflowRuleTemplates || true;
        IncludeWorkflowNotificationTemplates = IncludeWorkflowNotificationTemplates || true;
        UpdateExisting = UpdateExisting || false;
        let encodedData = { Base64Encoded: btoa(FileContents) };
        const formattedUrl = `${productsUrl
          }/import?includeproductdatasources=${IncludeProductDataSources
          }&includeproductaccesssettings=${IncludeProductAccessSettings
          }&includeproductconfigsettings=${IncludeProductConfigSettings
          }&includeproductdocumentcategories=${IncludeProductDocumentCategories
          }&includeproductdocumenttypes=${IncludeProductDocumentTypes
          }&includeproductdocumenttemplatetypes=${IncludeProductDocumentTemplateTypes
          }&includeproductorderdocumenttypes=${IncludeProductOrderDocumentTypes
          }&includeproductcustomuserfields=${IncludeProductCustomUserFields
          }&includeproductcustomfields=${IncludeProductCustomFields
          }&includeproducttags=${IncludeProductTags
          }&includeproductsearchgroups=${IncludeProductSearchGroups
          }&includesearches=${IncludeSearches
          }&includeproductformgroups=${IncludeProductFormGroups
          }&includeformlayouts=${IncludeFormLayouts
          }&includeworkflowtasktemplates=${IncludeWorkflowTaskTemplates
          }&includeworkflowruletemplates=${IncludeWorkflowRuleTemplates
          }&includeworkflownotificationtemplates=${IncludeWorkflowNotificationTemplates
          }&updateexisting=${UpdateExisting}`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: encodedData });
        context.commit("_addProduct", data);
        return data;
      }, "products - importProduct");
    },
    async addProductDocumentTemplateType(context, productDocumentTemplateType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDocumentTemplateTypesUrl, Payload: productDocumentTemplateType });
        context.commit("_addCurrentProductDocumentTemplateType", data);
        context.commit("_addProductDocumentTemplateType", data);
      }, "products - addProductDocumentTemplateType");
    },
    async updateProductDocumentTemplateType(context, productDocumentTemplateType) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTemplateTypesUrl}/${Number(productDocumentTemplateType?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentTemplateType });
        context.commit("_updateCurrentProductDocumentTemplateType", data);
        context.commit("_updateProductDocumentTemplateType", data);
      }, "products - updateProductDocumentTemplateType");
    },
    async removeProductDocumentTemplateType(context, productDocumentTemplateType) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTemplateTypesUrl}/${Number(productDocumentTemplateType?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeCurrentProductDocumentTemplateType", productDocumentTemplateType);
        context.commit("_removeProductDocumentTemplateType", productDocumentTemplateType);
      }, "products - removeProductDocumentTemplateType");
    },
    async addProductOrderDocumentType(context, productOrderDocumentType) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, {Verb: PostVerb, FormattedUrl: productOrderDocumentTypesUrl, Payload: productOrderDocumentType});
        context.commit("_addProductOrderDocumentType", data);
        return data;
      }, "products - addProductOrderDocumentType");
    },
    async updateProductOrderDocumentType(context, productOrderDocumentType) {
      return timeOperation(async () => {
        const formattedUrl = `${productOrderDocumentTypesUrl}/${Number(productOrderDocumentType?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, {Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productOrderDocumentType});
        context.commit("_updateProductOrderDocumentType", data);
        return data;
      }, "products - updateProductOrderDocumentType");
    },
    async removeProductOrderDocumentType(context, productOrderDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${productOrderDocumentTypesUrl}/${Number(productOrderDocumentType?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductOrderDocumentType", productOrderDocumentType);
      }, "products - removeProductOrderDocumentType");
    },
    async updateProductOrderDocumentTypeEnabledStatus(context, productOrderDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${productOrderDocumentTypesUrl}/${Number(productOrderDocumentType?.RootId || 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productOrderDocumentType });
        context.commit("_updateProductOrderDocumentType", data);
      }, "products - updateProductOrderDocumentType");
    },
    async addProductCodeBook(context, productCodeBook) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productCodeBooksUrl, Payload: productCodeBook });
        context.commit("_addCurrentProductCodeBook", data);
        context.commit("_addProductCodeBook", data);
      }, "products - addProductCodeBook");
    },
    async updateProductCodeBook(context, productCodeBook) {
      await timeOperation(async () => {
        const formattedUrl = `${productCodeBooksUrl}/${Number(productCodeBook?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productCodeBook });
        context.commit("_updateCurrentProductCodeBook", data);
        context.commit("_updateProductCodeBook", data);
      }, "products - updateProductCodeBook");
    },
    async removeProductCodeBook(context, productCodeBook) {
      await timeOperation(async () => {
        const formattedUrl = `${productCodeBooksUrl}/${Number(productCodeBook?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeCurrentProductCodeBook", productCodeBook);
        context.commit("_removeProductCodeBook", productCodeBook);
      }, "products - removeProductCodeBook");
    },
    async addProductCodeBookCode(context, productCodeBookCode) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productCodeBookCodesUrl, Payload: productCodeBookCode });
        context.commit("_addProductCodeBookCode", data);
        context.commit("_addCurrentProductCodeBookCode", data);
      }, "products - addProductCodeBookCode");
    },
    async updateProductCodeBookCode(context, productCodeBookCode) {
      await timeOperation(async () => {
        const formattedUrl = `${productCodeBookCodesUrl}/${Number(productCodeBookCode?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productCodeBookCode });
        context.commit("_updateProductCodeBookCode", data);
        context.commit("_updateCurrentProductCodeBookCode", data);
      }, "products - updateProductCodeBookCode");
    },
    async removeProductCodeBookCode(context, productCodeBookCode) {
      await timeOperation(async () => {
        const formattedUrl = `${productCodeBookCodesUrl}/${Number(productCodeBookCode?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductCodeBookCode", productCodeBookCode);
        context.commit("_removeCurrentProductCodeBookCode", productCodeBookCode);
      }, "products - removeProductCodeBookCode");
    },
    async addProductAccessSetting(context, productAccessSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productAccessSettingsUrl, Payload: productAccessSetting });
        context.commit("_addCurrentProductAccessSetting", data);
        context.commit("_addProductAccessSetting", data);
      }, "products - addProductAccessSetting");
    },
    async updateProductAccessSetting(context, productAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${productAccessSettingsUrl}/${Number(productAccessSetting?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productAccessSetting });
        context.commit("_updateCurrentProductAccessSetting", data);
        context.commit("_updateProductAccessSetting", data);
      }, "products - updateProductAccessSetting");
    },
    async removeProductAccessSetting(context, productAccessSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${productAccessSettingsUrl}/${Number(productAccessSetting?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeCurrentProductAccessSetting", productAccessSetting);
        context.commit("_removeProductAccessSetting", productAccessSetting);
      }, "products - removeProductAccessSetting");
    },
    async addProductConfigSetting(context, productConfigSetting) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productConfigSettingsUrl, Payload: productConfigSetting });
        context.commit("_addCurrentProductConfigSetting", data);
        context.commit("_addProductConfigSetting", data);
      }, "products - addProductConfigSetting");
    },
    async updateProductConfigSetting(context, productConfigSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${productConfigSettingsUrl}/${Number(productConfigSetting?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productConfigSetting });
        context.commit("_updateCurrentProductConfigSetting", data);
        context.commit("_updateProductConfigSetting", data);
      }, "products - updateProductConfigSetting");
    },
    async removeProductConfigSetting(context, productConfigSetting) {
      await timeOperation(async () => {
        const formattedUrl = `${productConfigSettingsUrl}/${Number(productConfigSetting?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeCurrentProductConfigSetting", productConfigSetting);
        context.commit("_removeProductConfigSetting", productConfigSetting);
      }, "products - removeProductConfigSetting");
    },
  },
};
