const baseUrl = process.env.VUE_APP_TRULY_API_ENDPOINT ?? "http://localhost:3458/trulyapi/";
const basePdfServiceUrl = process.env.VUE_APP_PDF_API_ENDPOINT ?? "http://localhost:3459/api/pdfviewer";

export const pdfServiceUrl = basePdfServiceUrl;

export const loginUrl = `${baseUrl}login`;
export const groupsUrl = `${baseUrl}groups`;
export const ordersUrl = `${baseUrl}orders`;
export const orderDocumentsUrl = `${baseUrl}orderdocuments`;
export const orderSearchColumnsUrl = `${baseUrl}ordersearchcolumns`;
export const orderSearchOperatorsUrl = `${baseUrl}ordersearchoperators`;
export const placeOrdersUrl = `${baseUrl}placeorders`;
export const submittedWriteUpsUrl = `${baseUrl}submittedwriteups`;
export const uploadedSubmittedWriteUpsUrl = `${baseUrl}uploadedsubmittedwriteups`;
export const requestedChangeOrdersUrl = `${baseUrl}requestedchangeorders`;
export const attachmentsUrl = `${baseUrl}attachments`;
export const linksAttachmentsUrl = `${baseUrl}linksattachments`;
export const linksQueuedAttachmentsUrl = `${baseUrl}linksqueuedattachments`;
export const userLinksAttachmentsUrl = `${baseUrl}userlinksattachments`;
export const userLinksAttachmentTypesUrl = `${baseUrl}userlinksattachmenttypes`;
export const ordersTransactionTypeUrl = `${baseUrl}ordertransactiontypes`;
export const orderWriteUpsUrl = `${baseUrl}orderwriteups`;
export const orderWriteUpAttachmentsUrl = `${baseUrl}orderwriteupattachments`;
export const orderScheduleAsUrl = `${baseUrl}orderscheduleas`;
export const orderScheduleB1sUrl = `${baseUrl}orderscheduleb1s`;
export const orderScheduleB2sUrl = `${baseUrl}orderscheduleb2s`;
export const orderInternalWriteUpsUrl = `${baseUrl}orderinternalwriteups`;
export const orderDS1sUrl = `${baseUrl}orderds1s`;
export const staticTemplatesUrl = `${baseUrl}statictemplates`;
export const organizationsUrl = `${baseUrl}organizations`;
export const organizationProductsUrl = `${baseUrl}organizationproducts`;
export const organizationTagsUrl = `${baseUrl}organizationtags`;
export const organizationDocumentCategoriesUrl = `${baseUrl}organizationdocumentcategories`;
export const organizationDocumentTypesUrl = `${baseUrl}organizationdocumenttypes`;
export const organizationDocumentTemplateTypesUrl = `${baseUrl}organizationdocumenttemplatetypes`;
export const organizationOrderDocumentTypesUrl = `${baseUrl}organizationorderdocumenttypes`;
export const organizationSettingsUrl = `${baseUrl}organizationsettings`;
export const organizationAccessSettingsUrl = `${baseUrl}organizationaccesssettings`;
export const organizationBranchesUrl = `${baseUrl}organizationbranches`;
export const organizationCustomersUrl = `${baseUrl}organizationcustomers`;
export const organizationVendorsUrl = `${baseUrl}organizationvendors`;
export const organizationCustomerDataSourceFieldAccessSettingsUrl = `${baseUrl}organizationcustomerdatasourcefieldaccesssettings`;
export const organizationVendorDataSourceFieldAccessSettingsUrl = `${baseUrl}organizationvendordatasourcefieldaccesssettings`;
export const organizationBrandsUrl = `${baseUrl}organizationbrands`;
export const productsUrl = `${baseUrl}products`;
export const productAccessSettingsUrl = `${baseUrl}productaccesssettings`;
export const productConfigSettingsUrl = `${baseUrl}productconfigsettings`;
export const productDocumentCategoriesUrl = `${baseUrl}productdocumentcategories`;
export const productDocumentTypesUrl = `${baseUrl}productdocumenttypes`;
export const productDocumentTemplateTypesUrl = `${baseUrl}productdocumenttemplatetypes`;
export const productOrderDocumentTypesUrl = `${baseUrl}productorderdocumenttypes`;
export const productTagsUrl = `${baseUrl}producttags`;
export const productCustomFieldsUrl = `${baseUrl}productcustomfields`;
export const productCustomFieldEntriesUrl = `${baseUrl}productcustomfieldentries`;
export const productCustomUserFieldsUrl = `${baseUrl}productcustomuserfields`;
export const documentTypesUrl = `${baseUrl}documenttypes`;
export const documentTypeTitlesUrl = `${baseUrl}documenttypetitles`;
export const productDocumentTypeTitlesUrl = `${baseUrl}productdocumenttypetitles`;
export const organizationDocumentTypeTitlesUrl = `${baseUrl}organizationdocumenttypetitles`;
export const documentTypeCategoriesUrl = `${baseUrl}documenttypecategories`;
export const productDocumentTypeCategoriesUrl = `${baseUrl}productdocumenttypecategories`;
export const organizationDocumentTypeCategoriesUrl = `${baseUrl}organizationdocumenttypecategories`;
export const documentTypeFieldsUrl = `${baseUrl}documenttypefields`;
export const productDocumentTypeFieldsUrl = `${baseUrl}productdocumenttypefields`;
export const organizationDocumentTypeFieldsUrl = `${baseUrl}organizationdocumenttypefields`;
export const documentTypeFieldTypesUrl = `${baseUrl}documenttypefieldtypes`;
export const productDocumentTypeFieldTypesUrl = `${baseUrl}productdocumenttypefieldtypes`;
export const organizationDocumentTypeFieldTypesUrl = `${baseUrl}organizationdocumenttypefieldtypes`;
export const documentCategoriesUrl = `${baseUrl}documentcategories`;
export const productDataSourcesUrl = `${baseUrl}productdatasources`;
export const productDataSourceFieldsUrl = `${baseUrl}productdatasourcefields`;
export const usersUrl = `${baseUrl}users`;
export const userAccessSettingsUrl = `${baseUrl}useraccesssettings`;
export const userConfigSettingsUrl = `${baseUrl}userconfigsettings`;
export const userBranchesUrl = `${baseUrl}userbranches`;
export const userTagsUrl = `${baseUrl}usertags`;
export const userProductsUrl = `${baseUrl}userproducts`;
export const userProductCodeBooksUrl = `${baseUrl}userproductcodebooks`;
export const appliedTagsUrl = `${baseUrl}appliedtags`;
export const groupMembersUrl = `${baseUrl}groupmembers`;
export const groupAccessSettingsUrl = `${baseUrl}groupaccesssettings`;
export const auditLogsUrl = `${baseUrl}auditlogs`;
export const documentLogUrl = `${baseUrl}documentlog`;
export const sharedDataLogUrl = `${baseUrl}shareddatalog`;
export const workflowLogUrl = `${baseUrl}workflowlog`;
export const userSearchSettingsLogUrl = `${baseUrl}usersearchsettingslog`;
export const userAccessSettingsLogUrl = `${baseUrl}useraccesssettingslog`;
export const userConfigSettingsLogUrl = `${baseUrl}userconfigsettingslog`;
export const feedbackEntriesUrl = `${baseUrl}feedbackentries`;
export const salesLeadsUrl = `${baseUrl}salesleads`;
export const searchesUrl = `${baseUrl}searches`;
export const searchGroupsUrl = `${baseUrl}searchgroups`;
export const formEntriesUrl = `${baseUrl}formentries`;
export const formLayoutsUrl = `${baseUrl}formlayouts`;
export const sharedSearchesUrl = `${baseUrl}sharedsearches`;
export const sharedFormLayoutsUrl = `${baseUrl}sharedformlayouts`;
export const systemSettingsUrl = `${baseUrl}systemsettings`;
export const dataSourceTypesUrl = `${baseUrl}datasourcetypes`;
export const searchLocationsUrl = `${baseUrl}searchlocations`;
export const formLocationsUrl = `${baseUrl}formlocations`;
export const productFormLocationsUrl = `${baseUrl}productformlocations`;
export const productSearchLocationsUrl = `${baseUrl}productsearchlocations`;
export const validateNamesUrl = `${baseUrl}validatenames`;
export const statesUrl = `${baseUrl}states`;
export const directUploadsUrl = `${baseUrl}directuploads`;
export const potentialDocumentTypesUrl = `${baseUrl}potentialdocumenttypes`;
export const productCodeBooksUrl = `${baseUrl}productcodebooks`;
export const productCodeBookCodesUrl = `${baseUrl}productcodebookcodes`;
export const userAvatarsUrl = `${baseUrl}useravatars`;
export const imageUrl = `${baseUrl}image`;
export const reportsUrl = `${baseUrl}reports`;
export const copiedLinksUrl = `${baseUrl}copiedlinks`;
export const viewedOrdersUrl = `${baseUrl}viewedorders`;
export const bookmarkedOrdersUrl = `${baseUrl}bookmarkedorders`;
export const partiesUrl = `${baseUrl}parties`;
export const partyRolesUrl = `${baseUrl}partyroles`;
export const organizationPartiesUrl = `${baseUrl}organizationparties`;
export const organizationPartyRolesUrl = `${baseUrl}organizationpartyroles`;
export const organizationDocumentCategoryPartiesUrl = `${baseUrl}organizationdocumentcategoryparties`;
export const organizationDocumentCategoryPartyRolesUrl = `${baseUrl}organizationdocumentcategorypartyroles`;
export const organizationDocumentTypePartiesUrl = `${baseUrl}organizationdocumenttypeparties`;
export const organizationDocumentTypePartyRolesUrl = `${baseUrl}organizationdocumenttypepartyroles`;
export const documentPlaceOrdersUrl = `${baseUrl}documentplaceorders`;
export const documentPlaceOrderEntriesUrl = `${baseUrl}documentplaceorderentries`;
export const instantMessagesUrl = `${baseUrl}instantmessages`;
export const notesUrl = `${baseUrl}notes`;
export const mimeTypesUrl = `${baseUrl}mimetypes`;
export const notificationsUrl = `${baseUrl}notifications`;
export const organizationNotificationTemplatesUrl = `${baseUrl}organizationnotificationtemplates`;
export const organizationSearchesUrl = `${baseUrl}organizationsearches`;
export const userSearchesUrl = `${baseUrl}usersearches`;
export const userFormLayoutsUrl = `${baseUrl}userformlayouts`;
export const organizationCustomFieldsUrl = `${baseUrl}organizationcustomfields`;
export const organizationCustomFieldEntriesUrl = `${baseUrl}organizationcustomfieldentries`;
export const organizationCustomUserFieldsUrl = `${baseUrl}organizationcustomuserfields`;
export const organizationDataSourcesUrl = `${baseUrl}organizationdatasources`;
export const organizationDataSourceFieldsUrl = `${baseUrl}organizationdatasourcefields`;
export const workflowTaskTemplatesUrl = `${baseUrl}workflowtasktemplates`;
export const workflowRuleTemplatesUrl = `${baseUrl}workflowruletemplates`;
export const workflowNotificationTemplatesUrl = `${baseUrl}workflownotificationtemplates`;
export const productWorkflowTaskTemplatesUrl = `${baseUrl}productworkflowtasktemplates`;
export const productWorkflowRuleTemplatesUrl = `${baseUrl}productworkflowruletemplates`;
export const productWorkflowNotificationTemplatesUrl = `${baseUrl}productworkflownotificationtemplates`;
export const organizationWorkflowTaskTemplatesUrl = `${baseUrl}organizationworkflowtasktemplates`;
export const organizationWorkflowRuleTemplatesUrl = `${baseUrl}organizationworkflowruletemplates`;
export const organizationWorkflowNotificationTemplatesUrl = `${baseUrl}organizationworkflownotificationtemplates`;
export const userWorkflowTaskTemplatesUrl = `${baseUrl}userworkflowtasktemplates`;
export const userWorkflowRuleTemplatesUrl = `${baseUrl}userworkflowruletemplates`;
export const userWorkflowNotificationTemplatesUrl = `${baseUrl}userworkflownotificationtemplates`;
export const workflowTasksUrl = `${baseUrl}workflowtasks`;
export const workflowRulesUrl = `${baseUrl}workflowrules`;
export const workflowNotificationsUrl = `${baseUrl}workflownotifications`;
export const formGroupsUrl = `${baseUrl}formgroups`;
export const userDataSourceFieldAccessSettingsUrl = `${baseUrl}userdatasourcefieldaccesssettings`;
export const groupDataSourceFieldAccessSettingsUrl = `${baseUrl}groupdatasourcefieldaccesssettings`;
export const groupSearchesUrl = `${baseUrl}groupsearches`;
export const groupFormLayoutsUrl = `${baseUrl}groupformlayouts`;
export const groupOrganizationVendorsUrl = `${baseUrl}grouporganizationvendors`;
export const productVendorCategoriesUrl = `${baseUrl}productvendorcategories`;
export const organizationVendorCategoriesUrl = `${baseUrl}organizationvendorcategories`;
export const organizationVendorCategoryMembersUrl = `${baseUrl}organizationvendorcategorymembers`;
export const notificationTypesUrl = `${baseUrl}notificationtypes`;
export const notificationEventsUrl = `${baseUrl}notificationevents`;
export const userNotificationSettingsUrl = `${baseUrl}usernotificationsettings`;
export const widgetTypesUrl = `${baseUrl}widgettypes`;
export const widgetContextFieldTypesUrl = `${baseUrl}widgetcontextfieldtypes`;
export const organizationContactCompaniesUrl = `${baseUrl}organizationcontactcompanies`;
export const organizationContactPersonsUrl = `${baseUrl}organizationcontactpersons`;
export const orderContactCompaniesUrl = `${baseUrl}ordercontactcompanies`;
export const orderContactPersonsUrl = `${baseUrl}ordercontactpersons`;
export const platformAdministratorsUrl = `${baseUrl}platformadministrators`;

//export const BaseTrulyTitleEmailTemplate = 'BaseTrulyTitleEmail';
//export const BaseTrulyEsqEmailTemplate = 'BaseTrulyEsqEmail';
//export const CognitoAccountCreatedEmailTemplate = 'CognitoAccountCreatedEmail';
//export const CustomerOrderReceivedEmailTemplate = 'CustomerOrderReceivedEmail';
//export const CustomerOrderReceivedEmailWithAddressTemplate = 'CustomerOrderReceivedEmailWithAddress';
//export const CustomerOrderUpdatedEmailTemplate = 'CustomerOrderUpdatedEmail';
//export const TrulyTitleRequestChangesToTheTitleEvidencePackageEmailTemplate = 'TrulyTitleRequestChangesToTheTitleEvidencePackageEmail';
//export const TrulyTitleNewOrderReceivedEmailTemplate = 'TrulyTitleNewOrderReceivedEmail';
//export const TrulyTitleWriteUpSubmittedEmailTemplate = 'TrulyTitleWriteUpSubmittedEmail';
//export const TrulyTitleOrderSignedEmailTemplate = 'TrulyTitleOrderSignedEmail';
export const TrulyEsqWriteUpTemplateWorksheetTemplate = "TrulyEsqWriteUpTemplateWorksheet";
export const TrulyEsqChicagoDS1FormTemplate = "TrulyEsqChicagoDS1Form";
//export const TrulyEsqHeaderImageTemplate = 'TrulyEsqHeaderImage';
//export const TrulyTitleHeaderImageTemplate = 'TrulyTitleHeaderImage';

export const ApiRequest = "apiRequest";
export const GetVerb = "get";
export const PostVerb = "post";
export const PutVerb = "put";
export const DeleteVerb = "delete";
