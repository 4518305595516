import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationDocumentTypeTitlesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationDocumentTypeTitles: [],
    organizationDocumentTypeTitlesCachedOrganizationRootId: 0,
    organizationDocumentTypeTitlesCachedProductRootId: 0,
  },
  getters: {
    getNextOrganizationDocumentTypeTitleRootId: (state) => {
      let result = 1;
      state.organizationDocumentTypeTitles.forEach((organizationDocumentTypeTitle) => {
        if (Number(organizationDocumentTypeTitle.RootId) >= result) {
          result = Number(organizationDocumentTypeTitle.RootId) + 1;
        }
      });
      return result;
    },
    areOrganizationDocumentTypeTitlesLoaded:
      (state) =>
      ({ ProductRootId, OrganizationRootId }) => {
        ProductRootId = Number(ProductRootId || 0);
        OrganizationRootId = Number(OrganizationRootId || 0);
        return (
          ProductRootId > 0 &&
          OrganizationRootId > 0 &&
          state.organizationDocumentTypeTitles?.find(
            (organizationDocumentTypeTitle) =>
              Number(organizationDocumentTypeTitle?.ProductRootId || 0) === ProductRootId && Number(organizationDocumentTypeTitle?.OrganizationRootId || 0) === OrganizationRootId
          ) != null
        );
      },
    isOrganizationDocumentTypeTitleNameTaken:
      (state) =>
      (currentOrganizationDocumentTypeTitle, isNew = false) => {
        const { organizationDocumentTypeTitles } = state;
        if (organizationDocumentTypeTitles?.length < 1) {
          return false;
        }
        if (!currentOrganizationDocumentTypeTitle) {
          return true;
        }
        const { Name: currentName, RootId } = currentOrganizationDocumentTypeTitle;
        if (!(currentName?.length > 0)) {
          return true;
        }
        if (Number(RootId || 0) < 1 && !isNew) {
          return true;
        }
        return !!organizationDocumentTypeTitles.find(
          (organizationDocumentTypeTitle) =>
            String(organizationDocumentTypeTitle?.Name || "") === String(currentName || "") && Number(RootId || 0) !== Number(organizationDocumentTypeTitle?.RootId || 0)
        );
      },
  },
  mutations: {
    setOrganizationDocumentTypeTitles(state, organizationDocumentTypeTitlesData) {
      state.organizationDocumentTypeTitles = organizationDocumentTypeTitlesData?.sort((odttl1, odttl2) => (odttl1.Name > odttl2.Name ? 1 : -1)) ?? [];
    },
    setOrganizationDocumentTypeTitlesCachedOrganizationRootId(state, organizationDocumentTypeTitlesCachedOrganizationRootId) {
      state.organizationDocumentTypeTitlesCachedOrganizationRootId = Number(organizationDocumentTypeTitlesCachedOrganizationRootId || 0);
    },
    setOrganizationDocumentTypeTitlesCachedProductRootId(state, organizationDocumentTypeTitlesCachedProductRootId) {
      state.organizationDocumentTypeTitlesCachedProductRootId = Number(organizationDocumentTypeTitlesCachedProductRootId || 0);
    },
    _addOrganizationDocumentTypeTitle(state, organizationDocumentTypeTitle) {
      const organizationDocumentTypeTitlesData = _.cloneDeep(state.organizationDocumentTypeTitles) ?? [];
      organizationDocumentTypeTitlesData.push(organizationDocumentTypeTitle);
      state.organizationDocumentTypeTitles = organizationDocumentTypeTitlesData.sort((odttl1, odttl2) => (odttl1.Name > odttl2.Name ? 1 : -1)) ?? [];
    },
    _updateOrganizationDocumentTypeTitle(state, updateOrganizationDocumentTypeTitle) {
      const rootId = Number(updateOrganizationDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTypeTitlesData = _.cloneDeep(state.organizationDocumentTypeTitles) ?? [];
        state.organizationDocumentTypeTitles =
          [
            ...organizationDocumentTypeTitlesData.filter((organizationDocumentTypeTitle) => Number(organizationDocumentTypeTitle?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateOrganizationDocumentTypeTitle),
          ].sort((odttl1, odttl2) => (odttl1.Name > odttl2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeOrganizationDocumentTypeTitle(state, deleteOrganizationDocumentTypeTitle) {
      const rootId = Number(deleteOrganizationDocumentTypeTitle?.RootId ?? 0);
      if (rootId > 0) {
        let organizationDocumentTypeTitlesData = _.cloneDeep(state.organizationDocumentTypeTitles) ?? [];
        state.organizationDocumentTypeTitles =
          [...organizationDocumentTypeTitlesData.filter((organizationDocumentTypeTitle) => Number(organizationDocumentTypeTitle?.RootId ?? 0) !== rootId)].sort((odttl1, odttl2) =>
            odttl1.Name > odttl2.Name ? 1 : -1
          ) ?? [];
      }
    },
  },
  actions: {
    async getOrganizationDocumentTypeTitles(context, {
      OrganizationRootId,
      ProductRootId,
      OrganizationDocumentTypeRootId,
      IncludeMetadata,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      IncludeOrganizationLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId || 0);
        OrganizationDocumentTypeRootId = Number(OrganizationDocumentTypeRootId || 0);
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        IncludeOrganizationLevelMetadata = IncludeOrganizationLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.organizationDocumentTypeTitlesCachedOrganizationRootId === OrganizationRootId) &&
          (context.state.organizationDocumentTypeTitlesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${organizationDocumentTypeTitlesUrl
          }?organizationrootid=${OrganizationRootId
          }&productrootid=${ProductRootId
          }&organizationdocumenttyperootid=${OrganizationDocumentTypeRootId
          }&includemetadata=${IncludeMetadata
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata
          }&includeorganizationlevelmetadata=${IncludeOrganizationLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setOrganizationDocumentTypeTitles", data);
        context.commit("setOrganizationDocumentTypeTitlesCachedOrganizationRootId", OrganizationRootId);
        context.commit("setOrganizationDocumentTypeTitlesCachedProductRootId", ProductRootId);
        return data;
      }, "organizationDocumentTypeTitles - getOrganizationDocumentTypeTitles");
    },
    async addOrganizationDocumentTypeTitles(context, organizationDocumentTypeTitles) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeTitlesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypeTitles });
        context.commit("setOrganizationDocumentTypeTitles", data);
      }, "organizations - addOrganizationDocumentTypeTitle");
    },
    async addOrganizationDocumentTypeTitle(context, organizationDocumentTypeTitle) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationDocumentTypeTitlesUrl, Payload: organizationDocumentTypeTitle });
        context.commit("_addOrganizationDocumentTypeTitle", data);
      }, "organizationDocumentTypeTitles - addOrganizationDocumentTypeTitle");
    },
    async updateOrganizationDocumentTypeTitle(context, organizationDocumentTypeTitle) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeTitlesUrl}/${Number(organizationDocumentTypeTitle?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationDocumentTypeTitle });
        context.commit("_updateOrganizationDocumentTypeTitle", data);
      }, "organizationDocumentTypeTitles - updateOrganizationDocumentTypeTitle");
    },
    async removeOrganizationDocumentTypeTitle(context, organizationDocumentTypeTitle) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationDocumentTypeTitlesUrl}/${Number(organizationDocumentTypeTitle?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationDocumentTypeTitle", organizationDocumentTypeTitle);
      }, "organizationDocumentTypeTitles - removeOrganizationDocumentTypeTitle");
    },
  },
};
