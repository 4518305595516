import { ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, productDocumentTypeFieldTypesUrl } from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDocumentTypeFieldTypes: [],
    productDocumentTypeFieldTypesCachedProductRootId: 0,
  },
  getters: {
    getProductDocumentTypeFieldTypeByName: (state) => (name) => {
      return state.productDocumentTypeFieldTypes.find((productDocumentTypeFieldType) => String(productDocumentTypeFieldType?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextProductDocumentTypeFieldTypeRootId: (state) => {
      let result = 1;
      state.productDocumentTypeFieldTypes.forEach((productDocumentTypeFieldType) => {
        if (Number(productDocumentTypeFieldType.RootId) >= result) {
          result = Number(productDocumentTypeFieldType.RootId) + 1;
        }
      });
      return result;
    },
    areProductDocumentTypeFieldTypesLoaded: (state) => (productRootId) => {
      productRootId = Number(productRootId || 0);
      return (
        productRootId > 0 &&
        state.productDocumentTypeFieldTypes?.find((productDocumentTypeFieldType) => Number(productDocumentTypeFieldType?.ProductRootId || 0) === productRootId) != null
      );
    },
    isProductDocumentTypeFieldTypeNameTaken:
      (state) =>
      (currentProductDocumentTypeFieldType, isNew = false) => {
        const { productDocumentTypeFieldTypes } = state;
        if (productDocumentTypeFieldTypes?.length < 1) {
          return false;
        }
        if (!currentProductDocumentTypeFieldType) {
          return true;
        }
        const { Name: currentName, RootId } = currentProductDocumentTypeFieldType;
        if (!(currentName?.length > 0)) {
          return false;
        }
        if (Number(RootId || 0) < 1 && !isNew) {
          return true;
        }
        return !!productDocumentTypeFieldTypes.find(
          (productDocumentTypeFieldType) =>
            String(productDocumentTypeFieldType?.Name || "") === String(currentName || "") && Number(RootId || 0) !== Number(productDocumentTypeFieldType?.RootId || 0)
        );
      },
  },
  mutations: {
    setProductDocumentTypeFieldTypes(state, productDocumentTypeFieldTypesData) {
      state.productDocumentTypeFieldTypes = productDocumentTypeFieldTypesData?.sort((pdtft1, pdtft2) => (pdtft1.Name > pdtft2.Name ? 1 : -1)) ?? [];
    },
    setProductDocumentTypeFieldTypesCachedProductRootId(state, productDocumentTypeFieldTypesCachedProductRootId) {
      state.productDocumentTypeFieldTypesCachedProductRootId = Number(productDocumentTypeFieldTypesCachedProductRootId || 0);
    },
    _addProductDocumentTypeFieldType(state, productDocumentTypeFieldType) {
      const productDocumentTypeFieldTypesData = _.cloneDeep(state.productDocumentTypeFieldTypes) ?? [];
      productDocumentTypeFieldTypesData.push(productDocumentTypeFieldType);
      state.productDocumentTypeFieldTypes = productDocumentTypeFieldTypesData.sort((pdtft1, pdtft2) => (pdtft1.Name > pdtft2.Name ? 1 : -1)) ?? [];
    },
    _updateProductDocumentTypeFieldType(state, updateProductDocumentTypeFieldType) {
      const rootId = Number(updateProductDocumentTypeFieldType?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentTypeFieldTypesData = _.cloneDeep(state.productDocumentTypeFieldTypes) ?? [];
        state.productDocumentTypeFieldTypes =
          [
            ...productDocumentTypeFieldTypesData.filter((productDocumentTypeFieldType) => Number(productDocumentTypeFieldType?.RootId ?? 0) !== rootId),
            _.cloneDeep(updateProductDocumentTypeFieldType),
          ].sort((pdtft1, pdtft2) => (pdtft1.Name > pdtft2.Name ? 1 : -1)) ?? [];
      }
    },
    _removeProductDocumentTypeFieldType(state, deleteProductDocumentTypeFieldType) {
      const rootId = Number(deleteProductDocumentTypeFieldType?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentTypeFieldTypesData = _.cloneDeep(state.productDocumentTypeFieldTypes) ?? [];
        state.productDocumentTypeFieldTypes =
          [...productDocumentTypeFieldTypesData.filter((productDocumentTypeFieldType) => Number(productDocumentTypeFieldType?.RootId ?? 0) !== rootId)].sort((pdtft1, pdtft2) =>
            pdtft1.Name > pdtft2.Name ? 1 : -1
          ) ?? [];
      }
    },
  },
  actions: {
    async getProductDocumentTypeFieldTypes(context, {
      ProductRootId,
      IncludeMetadata,
      IncludeProductDocumentTypes,
      IncludeProductDocumentTypeCategories,
      IncludeProductDocumentTypeTitles,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeProductDocumentTypes = IncludeProductDocumentTypes || false;
        IncludeProductDocumentTypeCategories = IncludeProductDocumentTypeCategories || false;
        IncludeProductDocumentTypeTitles = IncludeProductDocumentTypeTitles || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.productDocumentTypeFieldTypesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${productDocumentTypeFieldTypesUrl
          }?productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includeproductdocumenttypes=${IncludeProductDocumentTypes
          }&includeproductdocumenttypecategories=${IncludeProductDocumentTypeCategories
          }&includeproductdocumenttypetitles=${IncludeProductDocumentTypeTitles
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setProductDocumentTypeFieldTypes", data);
        context.commit("setProductDocumentTypeFieldTypesCachedProductRootId", ProductRootId);
        return data;
      }, "productDocumentTypeFieldTypes - getProductDocumentTypeFieldTypes");
    },
    async addProductDocumentTypeFieldTypes(context, productDocumentTypeFieldTypes) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeFieldTypesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypeFieldTypes });
        context.commit("setProductDocumentTypeFieldTypes", data);
      }, "products - addProductDocumentTypeFieldType");
    },
    async addProductDocumentTypeFieldType(context, productDocumentTypeFieldType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDocumentTypeFieldTypesUrl, Payload: productDocumentTypeFieldType });
        context.commit("_addProductDocumentTypeFieldType", data);
      }, "productDocumentTypeFieldTypes - addProductDocumentTypeFieldType");
    },
    async updateProductDocumentTypeFieldType(context, productDocumentTypeFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeFieldTypesUrl}/${Number(productDocumentTypeFieldType?.RootId ?? 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypeFieldType });
        context.commit("_updateProductDocumentTypeFieldType", data);
      }, "productDocumentTypeFieldTypes - updateProductDocumentTypeFieldType");
    },
    async removeProductDocumentTypeFieldType(context, productDocumentTypeFieldType) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypeFieldTypesUrl}/${Number(productDocumentTypeFieldType?.RootId ?? 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeProductDocumentTypeFieldType", productDocumentTypeFieldType);
      }, "productDocumentTypeFieldTypes - removeProductDocumentTypeFieldType");
    },
  },
};
