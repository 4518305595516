export default function (length) {
  const len = length || 10;
  let num = "";
  while (num.length < len) {
    num += getRandomStr();
  }
  return num.substr(0, len);
}

function getRandomStr() {
  return Math.random().toString(36).substr(2);
}
