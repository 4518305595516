import {ApiRequest, GetVerb, PostVerb, PutVerb, DeleteVerb, organizationPartiesUrl} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    organizationParties: [],
  },
  getters: {},
  mutations: {
    setOrganizationPartyData(state, organizationPartiesData) {
      state.organizationParties = organizationPartiesData?.sort((organizationParty1, organizationParty2) => (organizationParty1.Name > organizationParty2.Name ? 1 : -1)) || [];
    },
    clearOrganizationParties(state) {
      state.organizationParties = [];
    },
    _addOrganizationParty(state, organizationParty) {
      const organizationPartiesData = _.cloneDeep(state.organizationParties) || [];
      organizationPartiesData.push(organizationParty);
      state.organizationParties = organizationPartiesData.sort((organizationParty1, organizationParty2) => (organizationParty1.Name > organizationParty2.Name ? 1 : -1)) || [];
    },
    _updateOrganizationParty(state, updateOrganizationParty) {
      const rootId = Number(updateOrganizationParty?.RootId || 0);
      if (rootId > 0) {
        let organizationPartiesData = _.cloneDeep(state.organizationParties) || [];
        organizationPartiesData =
          [...organizationPartiesData.filter((organizationParty) => Number(organizationParty?.RootId || 0) !== rootId), _.cloneDeep(updateOrganizationParty)].sort((organizationParty1, organizationParty2) => (organizationParty1.Name > organizationParty2.Name ? 1 : -1)) || [];
        state.organizationParties = organizationPartiesData;
      }
    },
    _removeOrganizationParty(state, deleteOrganizationParty) {
      const rootId = Number(deleteOrganizationParty?.RootId || 0);
      if (rootId > 0) {
        let organizationPartiesData = _.cloneDeep(state.organizationParties) || [];
        organizationPartiesData = [...organizationPartiesData.filter((organizationParty) => Number(organizationParty?.RootId || 0) !== rootId)].sort((organizationParty1, organizationParty2) => (organizationParty1.Name > organizationParty2.Name ? 1 : -1)) || [];
        state.organizationParties = organizationPartiesData;
      }
    },
  },
  actions: {
    async getOrganizationPartiesData(context, { OrganizationRootId, IncludeMetadata }) {
      await timeOperation(async () => {
        OrganizationRootId = Number(OrganizationRootId || 0);
        IncludeMetadata = IncludeMetadata || true;
        const formattedUrl = `${organizationPartiesUrl}?organizationrootid=${OrganizationRootId}&includemetadata=${IncludeMetadata}`;
        const data = await context.dispatch(ApiRequest, { Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("setOrganizationPartyData", data);
      }, "organizationParties - getOrganizationPartiesData");
    },
    async addOrganizationParty(context, organizationParty) {
      return timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: organizationPartiesUrl, Payload: organizationParty });
        context.commit("_addOrganizationParty", data);
        return data;
      }, "organizationParties - addOrganizationParty");
    },
    async updateOrganizationParty(context, organizationParty) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationPartiesUrl}/${Number(organizationParty?.RootId || 0)}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: organizationParty });
        context.commit("_updateOrganizationParty", data);
      }, "organizationParties - updateOrganizationParty");
    },
    async removeOrganizationParty(context, organizationParty) {
      await timeOperation(async () => {
        const formattedUrl = `${organizationPartiesUrl}/${Number(organizationParty?.RootId || 0)}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeOrganizationParty", organizationParty);
      }, "organizationParties - removeOrganizationParty");
    },
  },
};
