<template>
  <LogoModal
    :inner-container-styles="getModalSize"
    inner-container-classes="form-modal-size"
    :modal-container-styles="getContainerStyle"
    :grow-with-content="true"
    :fill-screen="true"
    :buttons="modalButtons"
    @button-clicked="handleModalButtonClick"
    :show="show"
    @update:show="closeModal"
    :endSlotText="numberOfDocuments + ''"
    :hideOverflow="false"
    endClasses="w-full text-right pr-8"
  >
    <FormLayout
      @savedFormLayout="handleSavedForm"
      v-model="formData"
      :product-root-id="getGlobalSelectedProductRootId"
      :form-location-root-id="formLocationRootId"
      v-model:isFormEmpty="isFormEmpty"
      @update:formHeight="handelUpdateFormHeight"
      @update:formWidth="handelUpdateFormWidth"
      :hasRightMargin="true"
      :show-save-buttons-on-form="false"
      :configurationData="localConfigurationData"
      @update:configurationData="handleUpdateConfigData"
      @update:configurationDataKey="handleUpdateConfigDataKey"
      v-model:saveFunction="formSaveFunction"
      @cancelClicked="closeModal"
      v-model:formLayoutRootId="localFormLayoutRootId"
      :isInModal="true"
    />
    <div v-if="isFormEmpty" class="text-center">
      <div class="text-2xl font-bold">No Form Data Found Please Contact Your System Administrator</div>
    </div>
  </LogoModal>
</template>

<script>
import { computed, defineAsyncComponent, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import _ from "lodash";

import LogoModal from "@/components/uIElements/LogoModal.vue";
import {
  CreateNewOrderModalFormLocationName,
  DocumentIndexerModalFormLocationName,
  OrderEditorTemplateTasksModalFormLocationName,
  OrderEditorFreeFormTasksModalFormLocationName,
  OrderEditorCompanyContactsModalFormLocationName,
  OrderEditorPersonContactsModalFormLocationName,
  OrderEditorDocumentsModalFormLocationName,
} from "@/lib/settings";

import { ORDER, ORDER_ROOT_ID, MODAL_SAVE_ERRORS, DISABLED_UPLOAD_IDS, FILTERED_SAVED_UPLOADS } from "@/types/formLayoutConfigurationTypes";
export default {
  name: "FormViewerModal",
  components: {
    LogoModal,
    FormLayout: defineAsyncComponent(() => import("@/components/templateBuilder/renderedFormLayout/FormLayout.vue")),
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    modalType: {
      type: String,
      default: "",
    },
    orderRootId: {
      Type: Number,
      default: 0,
    },
    order: {
      type: Object,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:show", "saved", "update:formSaveData", "save", "cancel"],
  setup(props, { emit }) {
    const store = useStore();
    // State
    const productFormLocations = computed(() => store.state.pfl.productFormLocations);
    const uploadedDocuments = computed(() => store.state.upload.uploadedDocuments);
    const numberOfDocuments = computed(() => uploadedDocuments.value?.length || 0);
    const basicOrder = computed(() => store.state.ords.basicOrder);
    // Getters
    const getGlobalSelectedProductRootId = computed(() => store.getters.getGlobalSelectedProductRootId);
    // State
    const getBasicOrderData = async (params) => await store.dispatch("getBasicOrderData", params);
    const removeDisabledUploads = async (disabledUploadIds) => await store.dispatch("removeRecentUploadsList", disabledUploadIds);
    const removeRecentUploadsList = async () => await store.dispatch("removeRecentUploadsList", allDirectUploadIds.value);

    const formData = ref({});
    watch(
      formData,
      (is) => {
        emit("update:formSaveData", { FormLayoutRootId: localFormLayoutRootId.value, FormData: is });
      },
      {
        deep: true,
      }
    );
    const localFormLayoutRootId = ref(0);
    const formHeight = ref(0);
    const formWidth = ref(0);
    const isFormEmpty = ref(false);
    const localConfigurationData = ref({});
    const formSaveFunction = ref(() => {});

    const modalErrors = computed(() => localConfigurationData.value?.[MODAL_SAVE_ERRORS] || []);
    const modalButtons = computed(() => {
      // TODO: disabling the save button when there are error hints
      return [
        {
          Id: 1,
          text: "Cancel",
          isDisabled: false,
          cssClass: "btn btn-secondary m-1",
          type: "cancel",
        },
        {
          Id: 2,
          text: props.modalType === DocumentIndexerModalFormLocationName ? "Save" : "Create",
          isDisabled: modalErrors.value && modalErrors.value.length > 0,
          cssClass: "btn btn-primary m-1",
          type: "save",
        },
      ];
    });

    const getContainerStyle = computed(() => {
      if (!props.showModal && formHeight.value <= 0 && formWidth.value <= 0 && !isFormEmpty.value) {
        return {
          visibility: "hidden !important",
        };
      } else {
        return {};
      }
    });

    const formLocationRootId = computed(() => {
      switch (props.modalType) {
        case CreateNewOrderModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === CreateNewOrderModalFormLocationName)?.FormLocationRootId || 0;
        case DocumentIndexerModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === DocumentIndexerModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorTemplateTasksModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorTemplateTasksModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorFreeFormTasksModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorFreeFormTasksModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorCompanyContactsModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorCompanyContactsModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorPersonContactsModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorPersonContactsModalFormLocationName)?.FormLocationRootId || 0;
        case OrderEditorDocumentsModalFormLocationName:
          return productFormLocations.value.find((location) => (location.ProductLocationName || "") === OrderEditorDocumentsModalFormLocationName)?.FormLocationRootId || 0;
        default:
          console.warn("Failed to find form location root id for modal type: ", props.modalType);
          return 0;
      }
    });
    const allDirectUploadIds = computed(() => {
      return uploadedDocuments.value?.map?.((upload) => upload.DirectUploadId);
    });

    const getModalSize = computed(() => {
      return {
        height: `${formHeight.value}px !important`,
      };
    });

    async function closeModal() {
      emit("update:show", false);
      emit("cancel");
      formData.value = {};
      formWidth.value = 0;
      formHeight.value = 0;
      if (props.modalType === DocumentIndexerModalFormLocationName) {
        await removeRecentUploadsList();
      }
    }

    function handelUpdateFormHeight(value) {
      formHeight.value = value;
    }

    function handelUpdateFormWidth(value) {
      formWidth.value = value;
    }

    function handleUpdateConfigData(value) {
      localConfigurationData.value = _.cloneDeep(value);
    }
    function handleUpdateConfigDataKey({ key, value }) {
      localConfigurationData.value[key] = value;
    }

    async function handleModalButtonClick(button) {
      switch (button.type) {
        case "cancel":
          break;
        case "save":
          switch (props.modalType) {
            case CreateNewOrderModalFormLocationName:
            case DocumentIndexerModalFormLocationName: {
              const savedUploads = localConfigurationData.value?.[FILTERED_SAVED_UPLOADS];
              emit("save", savedUploads);
              return;
            }
            case OrderEditorTemplateTasksModalFormLocationName:
            case OrderEditorFreeFormTasksModalFormLocationName:
              emit("saved");
              break;
            default:
              break;
          }
          formSaveFunction.value?.();
          if (props.modalType === DocumentIndexerModalFormLocationName) {
            const disabledUploadIds = localConfigurationData.value?.[DISABLED_UPLOAD_IDS] || [];
            if (disabledUploadIds?.length > 0) {
              await removeDisabledUploads(disabledUploadIds);
            }
          }
          break;
        default:
          console.warn("Unknown button type: ", button.type);
          break;
      }
      await closeModal();
    }

    async function handleSavedForm() {
      emit("saved");
      await closeModal();
    }

    onMounted(async () => {
      await nextTick();
      const orderRootId = props.orderRootId || props.order?.RootId || 0;
      if (localConfigurationData.value[ORDER]?.RootId !== orderRootId) {
        localConfigurationData.value[ORDER_ROOT_ID] = orderRootId;
        let order = props.order ? props.order : { RootId: props.orderRootId };
        if (orderRootId > 0 && !props.order) {
          await getBasicOrderData({ RootId: orderRootId, OrderNumber: props.defaultOrderNumber });
          const newOrder = _.cloneDeep(basicOrder.value);
          if (newOrder?.RootId > 0) {
            order = newOrder;
          }
        }
        localConfigurationData.value[ORDER] = _.cloneDeep(order);
      }
    });

    return {
      emit,

      localFormLayoutRootId,
      formData,
      isFormEmpty,
      formLocationRootId,
      formHeight,
      formWidth,
      modalButtons,
      getContainerStyle,
      formSaveFunction,
      localConfigurationData,
      getModalSize,
      uploadedDocuments,
      numberOfDocuments,

      modalErrors,
      getGlobalSelectedProductRootId,

      closeModal,
      handleModalButtonClick,
      handleUpdateConfigData,
      handleUpdateConfigDataKey,
      handelUpdateFormHeight,
      handelUpdateFormWidth,
      handleSavedForm,
    };
  },
};
</script>

<style>
.form-modal-size {
  min-height: 15vh;
  max-height: 80vh;

  min-width: 70vw;
}
</style>
