<template>
  <div ref="root" :class="containerClasses">
    <div class="relative">
      <div class="flex">
        <div
          v-if="width > 1 && height > 1"
          v-width="width"
          v-height="height"
          class="flex w-full h-7"
          :class="`${getOuterInputClasses} ${truncate ? 'truncate ' : ''} ${required && modelValue?.length < 1 ? 'red-outline ' : ''} ${
            hasBorder ? `border border-1 ${focusClasses ? (isOpen ? focusClasses : '') : 'focus:ring-1'}` : ''
          }`"
          v-tooltip.top="{
            content: toolTip,
            modifiers: [{ name: 'arrow', options: { padding: 10 } }],
          }"
          @click.prevent="handleSetFocusClick"
        >
          <input
            v-height="height"
            ref="input"
            class="outline-none px-2 max-h-6 w-full"
            :tabindex="tabindex || 0"
            :class="inputClasses"
            @focus="onInputFocus"
            @blur="onInputBlur"
            :disabled="disabled"
            v-model="searchValue"
            :placeholder="placeholder"
            @keydown="handleKeys"
          />
          <div v-if="searchIcon" class="mx-2" @mouseleave="hoveringOverIcon = false" @mouseover="hoveringOverIcon = true">
            <svg
              v-if="!hoveringOverIcon || !canClearInput"
              @click="handleIconClick"
              v-tooltip="searchIconTooltip"
              class="w-4 h-4 cursor-pointer"
              :class="iconClasses"
              fill="none"
              :stroke="searchIconWhite ? `white` : `gray`"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 cursor-pointer relative top-1.5"
              :stroke="searchIconWhite ? `white` : `gray`"
              @click="handleClearInput"
            >
              <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div v-if="hasDropdownIcon" class="mx-2" @mouseleave="hoveringOverIcon = false" @mouseover="hoveringOverIcon = true">
            <svg v-if="!hoveringOverIcon || !canClearInput" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" class="h-4 mt-1.5">
              <path
                fill-rule="evenodd"
                d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 cursor-pointer relative top-1.5"
              :stroke="searchIconWhite ? `white` : `gray`"
              @click="handleClearInput"
            >
              <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <div
          v-else
          class="flex w-full h-7"
          :class="`${getOuterInputClasses} ${truncate ? 'truncate ' : ''} ${required && modelValue?.length < 1 ? 'red-outline ' : ''} ${
            hasBorder ? `border border-1 ${focusClasses ? (isOpen ? focusClasses : '') : 'focus:ring-1'}` : ''
          }`"
          @click.prevent="handleSetFocusClick"
        >
          <input
            ref="input"
            class="outline-none px-2 max-h-6 w-full"
            :class="inputClasses"
            @focus="onInputFocus"
            @blur="onInputBlur"
            :disabled="disabled"
            v-model="searchValue"
            :placeholder="placeholder"
            @keydown="handleKeys"
          />
          <div v-if="searchIcon" class="mx-2 relative top-1" @mouseleave="hoveringOverIcon = false" @mouseover="hoveringOverIcon = true">
            <svg
              v-if="!hoveringOverIcon || !canClearInput"
              v-tooltip="searchIconTooltip"
              @click="handleIconClick"
              class="w-4 h-4 cursor-pointer"
              :class="iconClasses"
              fill="none"
              :stroke="searchIconWhite ? `white` : `gray`"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 cursor-pointer"
              :stroke="searchIconWhite ? `white` : `gray`"
              @click="handleClearInput"
            >
              <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <div v-if="hasDropdownIcon" class="mx-2" @mouseleave="hoveringOverIcon = false" @mouseover="hoveringOverIcon = true">
            <svg v-if="!hoveringOverIcon || !canClearInput" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" class="h-4 mt-1.5">
              <path
                fill-rule="evenodd"
                d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="w-4 h-4 cursor-pointer relative top-1.5 mb-2"
              :stroke="searchIconWhite ? `white` : `gray`"
              @click="handleClearInput"
            >
              <path
                fill-rule="evenodd"
                d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <div class="absolute z-top w-full">
          <ul
            v-show="hasOptions && (!justLoaded || (clickToOpen && isOpen))"
            class="flex-grid px-3 overflow-y-scroll overflow-x-hidden max-h-32 pt-2 bg-white shadow-md"
            :class="listClasses"
          >
            <li
              :id="`row${index}`"
              class="row autocomplete-row"
              v-for="(item, index) in safeOptions"
              :key="index"
              :class="{
                'autocomplete-focus': index === focusIndex,
                'gray-background': index % 2 === 0 && index !== focusIndex,
              }"
              @click="optionSelected(isString(item) ? item : item?.text)"
            >
              <p v-if="isString(item)" :class="getItemClass">
                {{ item }}
              </p>
              <div v-else class="flex items-center space-x-4">
                <span :class="item?.cssClass" />
                <p class="relative top-2 break-all">{{ item?.text }}</p>
              </div>
              <div v-if="index === 0 && allowCustomInput && searchValue && !selectedValue" class="ml-auto">
                <svg
                  v-if="searchIcon"
                  class="w-4 h-4 mt-2 mx-1"
                  :class="iconClasses"
                  fill="none"
                  :stroke="searchIconWhite ? `white` : `gray`"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                </svg>
                <svg v-else class="w-6 h-6 mt-1 mx-1" fill="gray" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                </svg>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, nextTick, watch, onMounted, onBeforeUnmount } from "vue";
import _ from "lodash";

// TODO: truncate on blur
export default {
  name: "CustomAutoComplete",
  props: {
    modelValue: {
      default: "",
    },
    options: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    toolTip: {
      type: String,
      default: "",
    },
    formatFunction: {
      type: Function,
      default: (value) => value,
    },
    inputClasses: {
      type: String,
      default: "",
    },
    outerInputClasses: {
      type: String,
      default: "",
    },
    containerClasses: {
      type: String,
      default: "",
    },
    listClasses: {
      type: String,
      default: "",
    },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    searchIconWhite: {
      type: Boolean,
      default: false,
    },
    iconClasses: {
      type: String,
      default: "",
    },
    truncate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCustomInput: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    clearAfterSelection: {
      type: Boolean,
      default: false,
    },
    clickToOpen: {
      type: Boolean,
      default: false,
    },
    cancelFocus: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showAllOptionsOnFocus: {
      type: Boolean,
      default: false,
    },
    searchIconTooltip: {
      type: String,
      default: "",
    },
    hasDropdownIcon: {
      type: Boolean,
      default: false,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    focusClasses: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
    canClearInput: {
      type: Boolean,
      default: true,
    },
    autoselectFirstItem: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["optionSelected", "update:modelValue", "update:focus", "focus", "search", "update:options", "click", "blur"],
  setup(props, { emit }) {
    const root = ref(null);
    const input = ref(null);
    const hoveringOverIcon = ref(false);
    const focus = computed(() => props.focus);
    const getOuterInputClasses = computed(() => {
      return props.disabled ? `${props.outerInputClasses} disabled-background` : props.outerInputClasses;
    });
    watch(focus, (is) => {
      if (is) {
        if (!input.value) {
          console.warn("input not found for focus");
          return;
        }
        if (!input.value.focus) {
          console.warn("no focus function found");
          return;
        }
        // input.value.focus();
        setTimeout(() => {
          // something messes with autofocus so this is a hack to make it visible at the right time
          input.value.focus();
        }, 2000);
        emit("update:focus", false);
      }
    });
    //handle modelValue updates
    const selectedFirstOption = ref(false);
    const searchValue = ref("");
    const justLoaded = ref(true);
    const selectedValue = ref(null);
    watch(searchValue, (newValue) => {
      selectedValue.value = null;
      if (searchValue.value !== props.modelValue || (searchValue.value ?? "").length === 0) {
        if (!props.clickToOpen) {
          justLoaded.value = false;
        }
        emit("search", newValue);
        emit("update:modelValue", newValue);
      }
    });
    const modelValue = computed(() => props.modelValue);
    watch(modelValue, (newValue) => {
      if (searchValue.value !== newValue) {
        nextTick(() => {
          nextTick(() => {
            // do this after search value watcher updates
            selectedValue.value = newValue;
          });
        });
      }
      searchValue.value = newValue;
    });
    onMounted(() => {
      nextTick(() => {
        searchValue.value = props.modelValue ?? "";
        handleAutoSelectFirstItem();
      });
    });
    watch(
      () => props.options,
      (is, was) => {
        if (!_.isEqual(is, was)) {
          handleAutoSelectFirstItem();
        }
      },
      {
        deep: true,
      }
    );

    //refs
    const focusIndex = ref(null);

    //computed
    const validSearchValue = computed(() => searchValue.value?.length > 0);
    const safeOptions = computed(() => {
      const customOption = [];
      if (props.allowCustomInput && validSearchValue.value && !selectedValue.value) {
        customOption.push(searchValue.value);
      }
      if (props.options?.length > 0 && (!selectedValue.value || props.showAllOptionsOnFocus)) {
        customOption.push(
          ...(props.options?.map((option) => {
            return props.formatFunction(option);
          }) ?? [])
        );
      }
      const filledOptions = customOption.filter((item) => item);
      return filledOptions;
    });
    const hasOptions = computed(() => {
      return safeOptions.value?.length > 0;
    });
    const hasObject = computed(() => {
      return safeOptions.value?.some?.((item) => typeof item === "object" && item !== null);
    });
    const getItemClass = computed(() => (hasObject.value ? "relative top-1 break-all left-margin pt-2" : "relative top-2 px-2 break-all"));

    //functions
    const isOpen = ref(false);
    function onInputFocus() {
      emit("focus");
      if (props.showAllOptionsOnFocus && selectedValue.value === searchValue.value) {
        emit("search", "");
        isOpen.value = true;
      } else if (props.clickToOpen) {
        emit("search", searchValue.value);
        isOpen.value = true;
      }
    }
    function handleKeys(event) {
      switch (event.keyCode) {
        case 13: // enter
          if (hasOptions.value && (focusIndex.value ?? -1) > -1) {
            const option = safeOptions.value[focusIndex.value] || searchValue.value;
            optionSelected(isString(option) ? option : option?.text);
          } else if (props.searchIcon && validSearchValue.value && props.allowCustomInput) {
            optionSelected(searchValue.value);
          }
          break;
        case 38: // up arrow
          if (focusIndex.value === null) {
            focusIndex.value = 0;
          } else if (focusIndex.value > 0) {
            focusIndex.value--;
          }
          break;
        case 40: // down arrow
          if (focusIndex.value === null) {
            focusIndex.value = 0;
          } else if (focusIndex.value < safeOptions.value?.length - 1) {
            focusIndex.value++;
          }
          break;
      }
      if (focusIndex.value !== null) {
        setSelectedRow();
      }
    }
    function optionSelected(item) {
      const option = props.options.find((option) => props.formatFunction(option) === item);
      emit("optionSelected", option || item);
      emit("update:modelValue", item); // emits a string if it is custom (only on allowCustomInput)
      if (props.clearAfterSelection) {
        searchValue.value = "";
      } else {
        searchValue.value = item;
      }
      input.value.blur();
      isOpen.value = false;
      nextTick(() => {
        nextTick(() => {
          selectedValue.value = item;
        });
      });
    }
    function setSelectedRow() {
      if (focusIndex.value !== null && safeOptions.value?.length > 0) {
        document.getElementById("row" + focusIndex.value)?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }
    function handleIconClick() {
      if (hasOptions.value && (focusIndex.value ?? -1) > -1) {
        optionSelected(safeOptions.value[focusIndex.value] || searchValue.value);
      } else if (props.searchIcon && validSearchValue.value && props.allowCustomInput) {
        optionSelected(searchValue.value);
      }
    }
    function closeOnClickOff(e) {
      if (!root.value?.contains(e?.target)) {
        justLoaded.value = true;
      }
    }
    function isString(item) {
      return typeof item === "string";
    }
    function onInputBlur(e) {
      setTimeout(() => {
        if (props.clickToOpen) {
          isOpen.value = false;
          emit("blur", e);
        } else {
          emit("blur", e);
        }
      }, 200);
    }
    function handleSetFocusClick() {
      if (!props.cancelFocus) {
        if (!isOpen.value) {
          input.value.focus();
        }
      }
      emit("click");
    }
    async function handleClearInput() {
      searchValue.value = "";
      emit("update:modelValue", "");
      await nextTick();
      optionSelected(null);
    }
    function handleAutoSelectFirstItem() {
      if (props.autoselectFirstItem && props.options?.length > 0 && !props.modelValue && !selectedFirstOption.value) {
        selectedFirstOption.value = true;
        optionSelected(props.options[0]);
      }
    }

    watch(isOpen, (is) => {
      if (!is) {
        focusIndex.value = null;
      }
    });

    onMounted(() => {
      document.addEventListener("click", closeOnClickOff);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", closeOnClickOff);
    });

    return {
      root,
      input,

      focusIndex,
      searchValue,
      justLoaded,
      isOpen,
      selectedValue,
      hoveringOverIcon,

      safeOptions,
      getItemClass,
      getOuterInputClasses,
      hasOptions,

      onInputFocus,
      onInputBlur,
      handleKeys,
      optionSelected,
      isString,
      handleIconClick,
      handleSetFocusClick,
      handleClearInput,
    };
  },
};
</script>

<style scoped lang="scss">
.autocomplete-row {
  cursor: pointer;
}

.autocomplete-row:hover {
  background-color: #dadada;
}
.autocomplete-focus {
  background-color: #dadada;
}

.gray-background {
  background-color: #f5f5f5;
}
.z-top {
  z-index: 10001 !important;
}
.red-outline {
  border: 2px;
  border-color: rgb(255, 115, 115) !important;
}
.dropdown-icon {
  transform: rotate(0deg);
  transition: all 0.4s ease;
  &.dropdown {
    transform: rotate(180deg);
    transition: all 0.4s ease;
  }
}

.left-margin {
  margin-left: 2.3rem !important;
}
</style>
