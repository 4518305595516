import {
  ApiRequest,
  GetVerb,
  PostVerb,
  PutVerb,
  DeleteVerb,
  productDocumentTypesUrl,
} from "@/lib/api";
import _ from "lodash";
import timeOperation from "@/utils/timeOperation";

export default {
  state: {
    productDocumentTypes: [],
    productDocumentTypesCachedProductRootId: 0,
  },
  getters: {
    getProductDocumentTypeByName: (state) => (name) => {
      return state.productDocumentTypes.find((productDocumentType) => String(productDocumentType?.Name ?? "") === String(name ?? "")) ?? null;
    },
    getNextProductDocumentTypeRootId: (state) => {
      let result = 1;
      state.productDocumentTypes.forEach((productDocumentType) => {
        if (Number(productDocumentType.RootId) >= result) {
          result = Number(productDocumentType.RootId) + 1;
        }
      });
      return result;
    },
    areProductDocumentTypesLoaded: (state) => (productRootId) => {
      productRootId = Number(productRootId || 0);
      return (productRootId > 0) && (state.productDocumentTypes?.find((productDocumentType) => Number(productDocumentType?.ProductRootId || 0) === productRootId) != null);
    },
    isProductDocumentTypeNameTaken: (state) => (currentProductDocumentType) => {
      const { productDocumentTypes } = state;
      if (productDocumentTypes?.length < 1) {
        return false;
      }
      if (!currentProductDocumentType) {
        return true;
      }
      const { Name: currentName, RootId } = currentProductDocumentType;
      if (!(currentName?.length > 0)) {
        return true;
      }
      if (Number(RootId || 0) < 1) {
        return true;
      }
      return !!productDocumentTypes.find((productDocumentType) => String(productDocumentType?.Name || "") === String(currentName || "") && Number(RootId || 0) !== Number(productDocumentType?.RootId || 0));
    },
  },
  mutations: {
    setProductDocumentTypes(state, productDocumentTypesData) {
      state.productDocumentTypes = productDocumentTypesData?.sort((pdt1, pdt2) => (pdt1.Name > pdt2.Name ? 1 : -1)) ?? [];
    },
    setProductDocumentTypesCachedProductRootId(state, productDocumentTypesCachedProductRootId) {
      state.productDocumentTypesCachedProductRootId = Number(productDocumentTypesCachedProductRootId || 0);
    },
    _addProductDocumentType(state, productDocumentType) {
      const productDocumentTypesData = _.cloneDeep(state.productDocumentTypes) ?? [];
      productDocumentTypesData.push(productDocumentType);
      state.productDocumentTypes = productDocumentTypesData.sort((pdt1, pdt2) => (pdt1.Name > pdt2.Name ? 1 : -1)) ?? [];
    },
    _updateProductDocumentType(state, updateProductDocumentType) {
      const rootId = Number(updateProductDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentTypesData = _.cloneDeep(state.productDocumentTypes) ?? [];
        productDocumentTypesData =
          [...productDocumentTypesData.filter((productDocumentType) => Number(productDocumentType?.RootId ?? 0) !== rootId), _.cloneDeep(updateProductDocumentType)].sort(
            (pdt1, pdt2) => (pdt1.Name > pdt2.Name ? 1 : -1)
          ) ?? [];
        state.productDocumentTypes = productDocumentTypesData;
      }
    },
    _removeProductDocumentType(state, deleteProductDocumentType) {
      const rootId = Number(deleteProductDocumentType?.RootId ?? 0);
      if (rootId > 0) {
        let productDocumentTypesData = _.cloneDeep(state.productDocumentTypes) ?? [];
        productDocumentTypesData =
          [...productDocumentTypesData.filter((productDocumentType) => Number(productDocumentType?.RootId ?? 0) !== rootId)].sort((pdt1, pdt2) =>
            pdt1.Name > pdt2.Name ? 1 : -1
          ) ?? [];
        state.productDocumentTypes = productDocumentTypesData;
      }
    },
  },
  actions: {
    async getProductDocumentTypes(context, {
      ProductRootId,
      IncludeMetadata,
      IncludeProductDocumentTypeFields,
      IncludeProductDocumentTypeCategories,
      IncludeProductDocumentTypeTitles,
      IncludeSystemLevelMetadata,
      IncludeProductLevelMetadata,
      UseCache,
    }) {
      return timeOperation(async () => {
        ProductRootId = Number(ProductRootId || 0);
        IncludeMetadata = IncludeMetadata || false;
        IncludeProductDocumentTypeFields = IncludeProductDocumentTypeFields || false;
        IncludeProductDocumentTypeCategories = IncludeProductDocumentTypeCategories || false;
        IncludeProductDocumentTypeTitles = IncludeProductDocumentTypeTitles || false;
        IncludeSystemLevelMetadata = IncludeSystemLevelMetadata || false;
        IncludeProductLevelMetadata = IncludeProductLevelMetadata || false;
        UseCache = UseCache || false;
        if (UseCache &&
          (context.state.productDocumentTypesCachedProductRootId === ProductRootId)) {
          return;
        }
        const formattedUrl = `${productDocumentTypesUrl
          }?productrootid=${ProductRootId
          }&includemetadata=${IncludeMetadata
          }&includeproductdocumenttypefields=${IncludeProductDocumentTypeFields
          }&includeproductdocumenttypecategories=${IncludeProductDocumentTypeCategories
          }&includeproductdocumenttypetitles=${IncludeProductDocumentTypeTitles
          }&includesystemlevelmetadata=${IncludeSystemLevelMetadata
          }&includeproductlevelmetadata=${IncludeProductLevelMetadata}`;
        const data = await context.dispatch(ApiRequest, {Verb: GetVerb, FormattedUrl: formattedUrl, Payload: null});
        context.commit("setProductDocumentTypes", data);
        context.commit("setProductDocumentTypesCachedProductRootId", ProductRootId);
        return data;
      }, "productDocumentTypes - getProductDocumentTypes");
    },
    async addProductDocumentTypes(context, productDocumentTypes) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypesUrl}/bulkadd`;
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypes });
        context.commit("setProductDocumentTypes", data);
      }, "products - addProductDocumentType");
    },
    async addProductDocumentType(context, productDocumentType) {
      await timeOperation(async () => {
        const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: productDocumentTypesUrl, Payload: productDocumentType });
        context.commit("_addCurrentSelectedProductDocumentType", data);
        context.commit("_addProductDocumentType", data);
      }, "products - addProductDocumentType");
    },
    async updateProductDocumentType(context, productDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypesUrl}/${productDocumentType.RootId}`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentType });
        context.commit("_updateCurrentSelectedProductDocumentType", data);
        context.commit("_updateProductDocumentType", data);
      }, "products - updateProductDocumentType");
    },
    async removeProductDocumentType(context, productDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypesUrl}/${productDocumentType.RootId}`;
        await context.dispatch(ApiRequest, { Verb: DeleteVerb, FormattedUrl: formattedUrl, Payload: null });
        context.commit("_removeCurrentSelectedProductDocumentType", productDocumentType);
        context.commit("_removeProductDocumentType", productDocumentType);
      }, "products - removeProductDocumentType");
    },
    async updateProductDocumentTypePositions(context, productDocumentTypes) {
      await timeOperation(async () => {
        if (productDocumentTypes?.length > 0) {
          const formattedUrl = `${productDocumentTypesUrl}/updatepositions`;
          const data = await context.dispatch(ApiRequest, { Verb: PostVerb, FormattedUrl: formattedUrl, Payload: productDocumentTypes });
          context.commit("setProductDocumentTypes", data);
        }
      }, "productDocumentTypes - updateProductDocumentTypePositions");
    },
    async updateProductDocumentTypeEnabledStatus(context, productDocumentType) {
      await timeOperation(async () => {
        const formattedUrl = `${productDocumentTypesUrl}/${Number(productDocumentType?.RootId ?? 0)}/updateenabledstatus`;
        const data = await context.dispatch(ApiRequest, { Verb: PutVerb, FormattedUrl: formattedUrl, Payload: productDocumentType });
        context.commit("_updateCurrentSelectedProductDocumentType", data);
        context.commit("_updateProductDocumentType", data);
      }, "products - updateProductDocumentType");
    },
  },
};
